import React from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { getAgreementsState } from "../selectors";
import {
  PermissionCheck,
  withCurrentUser,
} from "features/../../../shared/components/auth";
import { Icon, Table, TableBody, TableCell, TableRow } from "semantic-ui-react";
import { ContentBuilder, Form, FormBuilder } from "@redriver/cinnamon";
import {
  getClient,
  setHeaderFields,
} from "../../../../../shared/features/Clients/actions";
import { Actions, Targets } from "constants/permissions";

const ClientHeader = () => {
  const { t } = useTranslation("agreements");
  const {
    companyFilters: { client },
  } = useSelector(getAgreementsState);

  return (
    <React.Fragment>
      {client.id && (
        <ContentBuilder
          loadAction={getClient}
          loadParams={client.id}
          renderContent={(data, state, events) =>
            data && (
              <div className="content-container agreements-list-header">
                <Table>
                  <TableBody>
                    <TableRow>
                      <TableCell style={{ width: "12.5em" }}>
                        {" "}
                        <h2>{t("Header_Customer")}</h2>
                      </TableCell>
                      <TableCell
                        style={{ width: "50%" }}
                        className="left-align"
                      >
                        <div className="text-container">
                          <FilterField value={data.name} />
                        </div>
                      </TableCell>
                      <TableCell style={{ width: "13.75em" }}>
                        <h2>{t("Header_Co_Reg")}</h2>
                      </TableCell>
                      <TableCell>
                        <div className="text-container">
                          <FilterField value={data.companyRegNo} />
                        </div>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <h3>{t("Header_Trading_Address")}</h3>
                      </TableCell>
                      <TableCell colSpan="3">
                        <div className="text-container">
                          <FilterField
                            value={data.fullAddress ? data.fullAddress : "N/A"}
                          />
                        </div>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
                <Table className="highlight">
                  <TableBody>
                    <TableRow>
                      <TableCell style={{ width: "12.5em" }}>
                        <h3>{t("Header_Account_Manager")}</h3>
                      </TableCell>
                      <TableCell
                        style={{ width: "25%" }}
                        className="left-align"
                      >
                        <div className="text-container upper">
                          {data.accountManager}
                        </div>
                      </TableCell>
                      <TableCell style={{ width: "6.25em" }}>
                        <h3>{t("Header_Office")}</h3>
                      </TableCell>
                      <TableCell>
                        <div className="text-container">
                          <FilterField value={data.dealerOffice ?? "N/A"} />
                        </div>
                      </TableCell>
                      <TableCell style={{ width: "19.375em" }}>
                        <h3>{t("Header_Company_ID")}</h3>
                      </TableCell>
                      <TableCell>
                        <div className="text-container">
                          <FilterField value={data.crmCompanyId} />
                        </div>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
                <FormBuilder
                  initialReadOnly
                  value={data}
                  submitAction={setHeaderFields}
                  submitParams={client.id}
                  onSubmitted={events.onRefresh}
                  renderForm={(formProps, state, events) => (
                    <Form
                      {...formProps}
                      readOnly={state.readOnly}
                      disabled={state.submitting}
                    >
                      <Table>
                        <TableBody>
                          <TableRow>
                            <TableCell style={{ width: "12.5em" }}>
                              <h3>{t("Header_Contact_Name")}</h3>
                            </TableCell>
                            <TableCell style={{ width: "20%" }}>
                              <Form.Input
                                field="contactName"
                                placeholder={t(
                                  "clients:fields:contactName:placeholder",
                                )}
                                renderReadOnly={() => (
                                  <div className="text-container">
                                    {data.contactName}
                                  </div>
                                )}
                              />
                            </TableCell>
                            <TableCell style={{ width: "12.5em" }}>
                              <h3>{t("Header_Email")}</h3>
                            </TableCell>
                            <TableCell style={{ width: "20%" }}>
                              <Form.Email
                                field="email"
                                placeholder={t("clients:fields:email:label")}
                                renderReadOnly={() => (
                                  <div className="text-container">
                                    {data.email}
                                  </div>
                                )}
                              />
                            </TableCell>
                            <TableCell style={{ width: "12.5em" }}>
                              <h3>{t("Header_Telephone")}</h3>
                            </TableCell>
                            <TableCell style={{ width: "20%" }}>
                              <Form.Input
                                field="phone"
                                placeholder={t(
                                  "clients:fields:phone:placeholder",
                                )}
                                renderReadOnly={() => (
                                  <div className="text-container">
                                    {data.phone}
                                  </div>
                                )}
                              />
                            </TableCell>
                            <PermissionCheck
                              target={Targets.Client}
                              action={Actions.Edit}
                            >
                              <TableCell style={{ minWidth: "5em" }}>
                                <div className="actions-container">
                                  <Icon
                                    role="button"
                                    name={state.readOnly ? "pencil" : "undo"}
                                    disabled={state.loading || state.submitting}
                                    onClick={events.onToggleReadOnly}
                                  />
                                  {!state.readOnly && (
                                    <Icon
                                      role="button"
                                      name="save"
                                      className="primary"
                                      disabled={
                                        !state.dirty || state.submitting
                                      }
                                      onClick={() =>
                                        !state.submitting &&
                                        events.onSubmit({ reset: ["readOnly"] })
                                      }
                                    />
                                  )}
                                </div>
                              </TableCell>
                            </PermissionCheck>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </Form>
                  )}
                />
              </div>
            )
          }
        />
      )}
    </React.Fragment>
  );
};

const FilterField = ({ fieldName, value }) => (
  <div className="agreement-field">
    <span>{fieldName}</span>
    <span className="value">{value ? value : "Not selected"}</span>
  </div>
);

export default withCurrentUser(ClientHeader);
