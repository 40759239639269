import React from "react";
import SignatoryInfo from "./SignatoryInfo";
import { useTranslation } from "react-i18next";

const DocumentRecipients = ({ header, data }) => {
  const { t } = useTranslation("agreements");
  if (data == null) return;
  const recipients = Array.isArray(data) ? data : [];
  return (
    <React.Fragment>
      <div className="document-recipients">
        <div className="document-recipients-header">{header} </div>
        {recipients.length == 0 ? (
          <span> {t("SheetActions.ViewDocumentRecipients.None")}</span>
        ) : (
          <div className="columns">
            {recipients.map((documentRecipient, index) => (
              <SignatoryInfo data={documentRecipient} key={index} />
            ))}
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

export default DocumentRecipients;
