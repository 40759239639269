import { apiGet, apiPut, mockSuccess } from "@redriver/cinnamon";

const NAMESPACE = "SCHEDULE_REQUESTS";

const ActionTypes = {
  GetCreditClearance: `${NAMESPACE}/GET_CREDIT_CLEARANCE`,
  RequestCreditClearance: `${NAMESPACE}/REQUEST_CREDIT_CLEARANCE`,
  GetVolumeRewrite: `${NAMESPACE}/GET_VOLUME_REWRITE`,
  RequestVolumeRewrite: `${NAMESPACE}/REQUEST_VOLUME_REWRITE`,
  GetReWriteAdditionalCapital: `${NAMESPACE}/GET_REWRITE_ADDITIONAL_CAPITAL`,
  RequestReWriteAdditionalCapital: `${NAMESPACE}/REQUEST_REWRITE_ADDITIONAL_CAPITAL`,
  OutstandingAgreementValue: `${NAMESPACE}/OUTSTANDING_AGREEMENT_VALUE`,
  RequestPartSettlement: `${NAMESPACE}/REQUEST_PART_SETTLEMENT`,
  GetPartSettlement: `${NAMESPACE}/GET_PART_SETTLEMENT`,
  RequestPartUpgrade: `${NAMESPACE}/REQUEST_PART_UPGRADE`,
  GetPartUpgrade: `${NAMESPACE}/GET_PART_UPGRADE`,
  RequestFullUpgrade: `${NAMESPACE}/REQUEST_FULL_UPGRADE`,
  GetFullUpgrade: `${NAMESPACE}/GET_FULL_UPGRADE`,
};

export const getCreditClearance = (scheduleId) =>
  apiGet(
    ActionTypes.GetCreditClearance,
    `schedules/${scheduleId}/credit-clearance`,
  );

export const requestCreditClearance = (formData, scheduleId) =>
  apiPut(
    ActionTypes.RequestCreditClearance,
    `schedules/${scheduleId}/credit-clearance`,
    {
      rrp: formData.schedule.rrp,
      grossSale: formData.schedule.grossSale,
      settlement: formData.schedule.settlement,
      comments: formData.schedule.comments,
      includeUpgrade: formData.schedule.includeUpgrade,
    },
  );

export const requestVolumeRewrite = (formData, scheduleId) =>
  apiPut(
    ActionTypes.RequestVolumeRewrite,
    `schedules/${scheduleId}/volume-rewrite`,
    formData,
  );

export const getVolumeRewrite = (scheduleId) =>
  apiGet(
    ActionTypes.GetVolumeRewrite,
    `schedules/${scheduleId}/volume-rewrite`,
  );

export const getReWriteAdditionalCapital = (scheduleId) =>
  apiGet(
    ActionTypes.GetReWriteAdditionalCapital,
    `schedules/${scheduleId}/rewrite-additional-capital`,
  );

export const requestReWriteAdditionalCapital = (formData, scheduleId) => {
  let toSend = {
    applicableFromPrevious: formData.applicableFromPrevious,
    applicableFromNext: formData.applicableFromNext,
    comments: formData.comments,
    units: formData.units,
  };
  return apiPut(
    ActionTypes.RequestReWriteAdditionalCapital,
    `schedules/${scheduleId}/rewrite-additional-capital`,
    toSend,
  );
};

export const requestOutstandingAgreementValue = (scheduleId) =>
  apiPut(
    ActionTypes.OutstandingAgreementValue,
    `schedules/${scheduleId}/outstanding-agreement-value`,
  );

export const getPartSettlement = (scheduleId) =>
  apiGet(
    ActionTypes.GetPartSettlement,
    `schedules/${scheduleId}/part-settlement`,
  );

export const requestPartSettlement = (formData, scheduleId) => {
  return apiPut(
    ActionTypes.RequestPartSettlement,
    `schedules/${scheduleId}/part-settlement`,
    formData,
  );
};

export const getPartUpgrade = (scheduleId) =>
  apiGet(ActionTypes.GetPartUpgrade, `schedules/${scheduleId}/part-upgrade`);

export const requestPartUpgrade = (formData, scheduleId) => {
  return apiPut(
    ActionTypes.RequestPartUpgrade,
    `schedules/${scheduleId}/part-upgrade`,
    formData,
  );
};

export const getFullUpgrade = (scheduleId) =>
  apiGet(ActionTypes.GetFullUpgrade, `schedules/${scheduleId}/full-upgrade`);

export const requestFullUpgrade = (formData, scheduleId) =>
  apiPut(
    ActionTypes.GetCreditClearance,
    `schedules/${scheduleId}/full-upgrade`,
    formData,
  );
