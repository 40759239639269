import { apiPut } from "@redriver/cinnamon";

const ActionTypes = {
  VoidEnvelope: "SHEETS/VOID_ENVELOPE",
};

export const voidEnvelope =
  (formData, { sheetId }) =>
  async (dispatch) =>
    await dispatch(
      apiPut(
        ActionTypes.VoidEnvelope,
        `sheets/${sheetId}/submit/void-envelope`,
        formData,
      ),
    );
