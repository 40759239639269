import { apiGet, apiPut } from "@redriver/cinnamon";

const NAMESPACE = "NOTIFICATIONS";

export const ActionTypes = {
  GetNotificationsList: `${NAMESPACE}/GET_NOTIFICATIONS_LIST`,
  MarkAsRead: `${NAMESPACE}/MARK_AS_READ`,
};

export const getNotificationsList = ({ pagination, filters }, params) => {
  const { pageSize, pageNumber } = pagination;
  const { sheetOwnerName, sheetOwner, ...otherFilters } = filters;
  return apiGet(ActionTypes.GetNotificationsList, `notifications`, {
    pageNumber,
    pageSize,
    ...otherFilters,
    ...params,
  });
};

export const markAsRead = ({ id }) => {
  return apiPut(ActionTypes.MarkAsRead, `notifications/${id}/mark-as-read`);
};
