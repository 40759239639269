import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import classNames from "classnames";
import { Grid } from "semantic-ui-react";
import {
  Form,
  Format,
  Lookup,
  PageLoader,
  getAuthClaim,
} from "@redriver/cinnamon";
import { FixedInput } from "components/forms";
import {
  withPermissions,
  PermissionCheck,
} from "features/../../../shared/components/auth";
import {
  getSheetIpsRatesState,
  getCalculationData,
  getSheetState,
  getSheetRatesState,
  getSheetDataState,
  LookupNames as SheetLookupNames,
  ActionTypes as SheetActionTypes,
} from "features/Sheets";
import { Targets, Actions } from "constants/permissions";
import {
  FunderType,
  funderTypes,
  FinanceType,
  SheetViewType,
} from "features/../../../shared/constants/enums";
import { mapFunderOverridesFieldName } from "features/Sheets/ManageSheet/IPSyndicateRatesStep";

const mapFieldName = (funderType) => {
  switch (funderType) {
    case FunderType.Funder:
      return "funderFmv";
    case FunderType.Dealer:
      return "dealerFmv";
    case FunderType.CoFunded:
      return "cofundedFmv";
    default:
      return null;
  }
};

const FmvSection = ({
  funderType,
  fmvCalculationData,
  calculating,
  sheetDetailsSaving,
  unlockRateType,
  unlockFMV,
  financeType,
  hasPermission,
  sheetViewType,
  impersonatedUser,
  funderSettings,
  preventUpdates,
  supplierLiquidityCharge,
}) => {
  const { t } = useTranslation("scheduleSetup");
  const funderTypeRecord = useMemo(
    () => funderTypes.find((x) => x.value == funderType),
    [funderType, funderTypes],
  );

  const d = useMemo(
    () => fmvCalculationData.find((x) => x.funderType == funderType),
    [funderType, fmvCalculationData],
  );

  const field = useMemo(() => mapFieldName(funderType), [funderType]);

  const canEditAdminFields = useMemo(
    () =>
      hasPermission(Targets.SheetAdmin, Actions.Edit) &&
      impersonatedUser.canEditAdminFields,
    [impersonatedUser],
  );

  const rateTypesAvailable = useMemo(
    () => funderSettings.rateTypesAvailable,
    [funderSettings],
  );

  const financeTypes = useMemo(
    () =>
      rateTypesAvailable.map((item) => ({
        text: t(`enums:financeTypes:${item}`),
        value: item,
      })),
    [rateTypesAvailable],
  );

  return (
    <div className="fmv-container">
      <Form.Object field={field} propagateUpdates="always">
        {canEditAdminFields &&
          (sheetViewType === SheetViewType.IpsAdmin ||
            sheetViewType === SheetViewType.SupplierAdmin) && (
            <div
              className={classNames(
                "fmv-section",
                `${funderType.toLowerCase()}`,
              )}
            >
              <div className="rates-step-field-group percentage-group">
                <FixedInput
                  options={[
                    { value: 0, text: t("No") },
                    { value: 1, text: t("Yes") },
                  ]}
                  field="salesPercentage"
                  label={t("Sales_%")}
                  placeholder={t("Sales_%_placeholder")}
                  minAllowValue={0}
                  maxAllowValue={100}
                  decimalPlaces={2}
                  formatNumber
                  fluid
                  width={4}
                  onFocus={(e) => e.target.select()}
                  className="percentage"
                />
                <FixedInput
                  field="incentivePercentage"
                  label={t("Incentive_%")}
                  placeholder={t("Incentive_%_placeholder")}
                  minAllowValue={0}
                  maxAllowValue={100}
                  decimalPlaces={2}
                  formatNumber
                  fluid
                  onFocus={(e) => e.target.select()}
                  className="percentage principal-pound"
                />
                <FixedInput
                  field="adminPercentage"
                  label={t("Admin_%")}
                  placeholder={t("Admin_%_placeholder")}
                  minAllowValue={0}
                  maxAllowValue={100}
                  decimalPlaces={2}
                  formatNumber
                  fluid
                  onFocus={(e) => e.target.select()}
                  className="percentage"
                />
                <FixedInput
                  field="otherPercentage"
                  label={t("Other_%")}
                  placeholder={t("Other_%_placeholder")}
                  minAllowValue={0}
                  maxAllowValue={100}
                  decimalPlaces={2}
                  formatNumber
                  fluid
                  onFocus={(e) => e.target.select()}
                  className="percentage"
                />
              </div>
              <div className="introduction-payment">
                <div className="columns">
                  <div className="header">{t("Introduction_Payment")} </div>
                  <div className="payment">
                    {supplierLiquidityCharge ? (
                      <Format.Number
                        value={supplierLiquidityCharge}
                        decimalPlaces={2}
                        currency
                        format={process.env.CURRENCY_CODE}
                      />
                    ) : (
                      <span>{`${process.env.MAJOR_CURRENCY}${t("NIL")}`}</span>
                    )}
                  </div>
                  <Form.Checkbox
                    label={t("Introduction_Payment_Display_Checkbox_Label")}
                    field="introductionPaymentDisplaySelected"
                  />
                </div>
              </div>
            </div>
          )}

        <div
          className={classNames(
            sheetViewType === SheetViewType.IpsAdmin ?? "rate-type-region",
            "fmv-section",
            `${funderType.toLowerCase()}`,
          )}
        >
          <PermissionCheck target={Targets.Sheet} action={Actions.ViewRateType}>
            <div className="">
              <p className="rate-type">
                {t("Rate_Type", {
                  type: t(`enums:funderTypes:${funderTypeRecord.value}`),
                })}
              </p>
            </div>
            <div className="rate-type-radio">
              <Form.RadioGroup
                inline
                name={(radioField) => `radio_ft_${field}_${radioField}`}
                options={financeTypes}
                field="financeType"
                disabled={
                  !unlockRateType || sheetDetailsSaving || preventUpdates
                }
                className="required-field sheet-radio-group"
              />
            </div>
          </PermissionCheck>
          <h4 className="reduce-top-margin">
            {t("Funder_Type_with_Finance", {
              funderType: t(`enums:funderTypes:${funderTypeRecord.value}`),
              financeType: t(`enums:financeTypes:${financeType}`),
            })}
          </h4>
          <div className="rrp-allowance">
            <h5 className="rrp-title">{t("RRP_Allowance")}</h5>
            <div className="rrp-value-container">
              {d ? (
                <Format.Number
                  value={d.rrpAllowancePercentage}
                  format="percentage"
                  decimalPlaces={2}
                  className="rrp-value highlighted"
                />
              ) : (
                "-"
              )}
            </div>
          </div>

          {calculating ? (
            financeType == "EFA" ? (
              <PageLoader size="small" />
            ) : (
              <PageLoader size="small" className="larger-page-loader" />
            )
          ) : (
            <React.Fragment>
              {sheetViewType === SheetViewType.IpsAdmin && (
                <React.Fragment>
                  <h5>{t("For_Adjusted_Rental")}</h5>
                  <Grid className="adjusted">
                    <Grid.Row className="header">
                      <Grid.Column width={4}></Grid.Column>
                      <Grid.Column width={6}>
                        {t("blended_IP_rate")}
                      </Grid.Column>
                      <Grid.Column width={6}>{t("nett_CFA")}</Grid.Column>
                    </Grid.Row>
                    {[
                      { name: "", ...(d && d.adjustedOverall) },
                      {
                        name: t("IPS%"),
                        highlighted: true,
                        ...(d && d.adjustedIpsPercentage),
                      },
                      {
                        name: t("IPS_Protection"),
                        ...(d && d.adjustedIpsProtection),
                      },
                      {
                        name: t("Supplier_Net"),
                        ...(d && d.adjustedDealerNet),
                      },
                      { name: t("GSR"), ...(d && d.adjustedGsr) },
                    ].map((e, i) => (
                      <Grid.Row key={`row${i}`}>
                        <Grid.Column width={4}>{e.name}</Grid.Column>
                        <Grid.Column width={6} className="center-align">
                          {e.blendedIpRate != null ? (
                            <Format.Number
                              value={e.blendedIpRate}
                              decimalPlaces={4}
                              className="highlighted"
                            />
                          ) : (
                            "-"
                          )}
                        </Grid.Column>
                        <Grid.Column width={6} className="center-align">
                          {e.nettCfa != null ? (
                            <Format.Number
                              value={e.nettCfa}
                              decimalPlaces={2}
                              currency
                              format={process.env.CURRENCY_CODE}
                              className={classNames(
                                e.highlighted && "highlighted",
                              )}
                            />
                          ) : (
                            "-"
                          )}
                        </Grid.Column>
                      </Grid.Row>
                    ))}
                  </Grid>
                </React.Fragment>
              )}

              {financeType == FinanceType.FMV &&
                sheetViewType === SheetViewType.IpsAdmin && (
                  <React.Fragment>
                    <div className="rrp-container">
                      {canEditAdminFields && (
                        <div className="future-value-row">
                          <h5 className="right-align">
                            {t("For_Future_Value")}
                          </h5>
                        </div>
                      )}
                      <div className="ffv-numbers-row">
                        <div
                          className="col-1"
                          style={{
                            visibility:
                              canEditAdminFields &&
                              sheetViewType === SheetViewType.IpsAdmin
                                ? "visible"
                                : "hidden",
                          }}
                        >
                          <div className="break-point" />
                          <div>
                            {d ? (
                              <Format.Number
                                value={d.ofRrpPercentage}
                                decimalPlaces={2}
                                className="highlighted"
                              />
                            ) : (
                              "-"
                            )}
                            %
                          </div>
                          <div>
                            {d ? (
                              <Format.Number
                                value={d.ofTotalCapitalPercentage}
                                decimalPlaces={2}
                                className="highlighted"
                              />
                            ) : (
                              "-"
                            )}
                            %
                          </div>
                        </div>
                        <div
                          className="col-2"
                          style={{
                            visibility:
                              canEditAdminFields &&
                              sheetViewType === SheetViewType.IpsAdmin
                                ? "visible"
                                : "hidden",
                          }}
                        >
                          <Lookup
                            lookup={SheetLookupNames[SheetActionTypes.FmvTypes]}
                            render={({ response }) => (
                              <div className="rrp-type-container">
                                <Form.RadioGroup
                                  field="fmvType"
                                  options={response || []}
                                  disabled={
                                    !unlockFMV ||
                                    sheetDetailsSaving ||
                                    preventUpdates
                                  }
                                  className="rrp-type"
                                  name={(radioField) =>
                                    `radio_fmv_${field}_${radioField}`
                                  }
                                />
                              </div>
                            )}
                          />
                        </div>
                        {canEditAdminFields &&
                          sheetViewType === SheetViewType.IpsAdmin && (
                            <div className="col-3">
                              <FutureValueRow
                                fv={d ? d.fullFmvFutureValues : null}
                              />
                              <FutureValueRow
                                fv={d ? d.ofRrpFutureValues : null}
                              />
                              <FutureValueRow
                                fv={d ? d.ofTotalCapitalFutureValues : null}
                              />
                            </div>
                          )}
                      </div>

                      {canEditAdminFields && (
                        <div className="figures-used">
                          <div>{t("FMV_Figures_used_in_Calculation")}</div>
                          {d ? (
                            <React.Fragment>
                              <div>
                                <Format.Number
                                  value={d.fmvUsedPercentage}
                                  decimalPlaces={2}
                                />
                                %
                              </div>
                              <div />
                              <div>
                                {process.env.MAJOR_CURRENCY}
                                <Format.Number
                                  value={d.fmvUsedAmount}
                                  decimalPlaces={2}
                                />
                              </div>
                            </React.Fragment>
                          ) : (
                            "-"
                          )}
                        </div>
                      )}
                    </div>
                  </React.Fragment>
                )}
            </React.Fragment>
          )}
        </div>
      </Form.Object>
    </div>
  );
};

const FutureValueRow = ({ fv }) => (
  <Grid.Row columns={2} className="future-value-row rrp-type-container">
    <Grid.Column className="rrp-type">
      {fv != null ? (
        <Format.Number value={fv.percentage} decimalPlaces={2} />
      ) : (
        "-"
      )}
      %
    </Grid.Column>
    <Grid.Column className="rrp-type">
      {fv != null ? (
        <Format.Number
          value={fv.amount}
          decimalPlaces={2}
          format={process.env.CURRENCY_CODE}
        />
      ) : (
        "-"
      )}
    </Grid.Column>
  </Grid.Row>
);

const mapStateToProps = (state, props) => {
  const { sheetData } = getSheetState(state);
  const calculationData = getCalculationData(state);
  const fmvCalculationData = calculationData
    ? calculationData.breakdown.fmvDetails
    : [];
  const { impersonatedUser, preventUpdates, rates } = getSheetDataState(state);

  const { calculating, sheetDetailsSaving, sheetViewType } =
    getSheetState(state);
  const { unlockRateType, unlockFMV } =
    getSheetIpsRatesState(state)[mapFunderOverridesFieldName(props.funderType)];
  const ratesState = getSheetRatesState(state);
  const { financeType } = ratesState[mapFieldName(props.funderType)];
  const funderTypeSettings = sheetData.defaults.funderTypeSettings;

  let funderSettings = true;
  if (funderTypeSettings) {
    funderSettings = funderTypeSettings.find(
      (obj) => obj.type == props.funderType,
    );
  }

  return {
    fmvCalculationData,
    calculating,
    sheetDetailsSaving,
    unlockRateType,
    unlockFMV,
    financeType,
    sheetViewType,
    impersonatedUser,
    funderSettings,
    preventUpdates,
    rates,
    userArea: getAuthClaim(state, "userArea"),
  };
};

const actions = {};

export default connect(mapStateToProps, actions)(withPermissions(FmvSection));
