import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import { PageLoader } from "@redriver/cinnamon";
import { getAgreementsState } from "./selectors";
import { withPermissions } from "features/../../../shared/components/auth";
import { Targets, Actions } from "constants/permissions";
import { putClientWithDetails } from "features/../../../shared/features/Clients/actions";
import { setAgreementCompanyFilter } from "./actions";
import PageNotFound from "pages/system/PageNotFound";
import withCurrentUser from "features/../../../shared/components/auth/withCurrentUser";
import { compose } from "redux";
import AgreementsList from "./AgreementsList/AgreementsList";
import { ClientsList } from "./ClientsList";
import { DealerOfficesList } from "./DealerOfficesList";
import { DealersList } from "./DealersList";
import { SystemRoutes } from "constants/routes";
import { getPersistentQueryParamState } from "features/System";

const Agreements = ({ hasPermission, history, currentUser, sheetId }) => {
  const { isInternalUser } = currentUser;

  const dispatch = useDispatch();
  const [loadingFilterData, setLoadingFilterData] = useState(false);

  const queryParams = useSelector(getPersistentQueryParamState);
  const { companyFilters } = useSelector(getAgreementsState);
  const { dealer, dealerOffice, client } = companyFilters;

  const isAdminUser =
    isInternalUser || hasPermission(Targets.SheetAdmin, Actions.Edit);

  const onDealerSelect = (dealer) => {
    dispatch(
      setAgreementCompanyFilter({
        ...companyFilters,
        dealer: {
          id: dealer.id,
          name: dealer.name,
        },
      }),
    );
  };

  const onDealerOfficeSelect = (office) => {
    dispatch(
      setAgreementCompanyFilter({
        ...companyFilters,
        dealerOffice: {
          id: office.id,
          name: office.name,
        },
        dealer: {
          id: office.dealerId,
          name: office.dealerName,
        },
      }),
    );
  };

  const onClientSelect = (client) => {
    dispatch(
      setAgreementCompanyFilter({
        ...companyFilters,
        client: {
          id: client.id,
          name: client.name,
          code: client.code,
        },
        dealerOffice: {
          id: client.dealerOfficeId,
          name: client.dealerOfficeName,
        },
        dealer: {
          id: client.dealerId,
          name: client.dealerName,
        },
      }),
    );
  };

  useEffect(() => {
    (async function () {
      /*
       *   logic to PUT a client using the supplied query params should be skipped
       *   if the user is not using a CRM link or we already have Client/DO filters set
       */
      if (
        !window.location.href.match(/customer=/) ||
        client?.id ||
        dealerOffice?.id
      ) {
        return;
      }

      const clientRequest = {
        originatingUrl: window.location.href,
        address: {},
      };
      paramToFieldMappings.forEach((pair) => {
        clientRequest[pair.field] = queryParams[pair.param];
      });
      addressParamsToFieldMappings.forEach((pair) => {
        clientRequest.address[pair.field] = queryParams[pair.param];
      });

      setLoadingFilterData(true);

      const { response, success, error } = await dispatch(
        putClientWithDetails(clientRequest),
      );

      if (success) {
        onClientSelect(response);
      } else if (error && (error.status === 401 || error.status === 403)) {
        history.push(SystemRoutes.AccountManagerLogout);
        return;
      }

      setLoadingFilterData(false);
    })();
    // Empty array is necessary to ensure this runs only once
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /*
   * Currently Dealer Admin users will be taken directly to the Clients list (if no customer code in URL params)
   * HOWEVER this will change after we have reworked users to be associated with a Dealer rather than a single DealerOffice, and they will be taken to the Dealer Office list
   */

  return (
    <React.Fragment>
      {loadingFilterData ? (
        <PageLoader />
      ) : !isAdminUser || !!client.id ? (
        <AgreementsList sheetId={sheetId} />
      ) : !isAdminUser || !!dealerOffice.id ? (
        <ClientsList
          onSelect={onClientSelect}
          dealerOfficeId={dealerOffice.id}
        />
      ) : !isInternalUser || !!dealer.id ? (
        <DealerOfficesList
          onSelect={onDealerOfficeSelect}
          dealerId={dealer.id}
        />
      ) : isInternalUser && !dealer.id ? (
        <DealersList onSelect={onDealerSelect} />
      ) : (
        <PageNotFound />
      )}
    </React.Fragment>
  );
};

const paramToFieldMappings = [
  { param: "name", field: "name" },
  { param: "office", field: "dealerOfficeCode" },
  { param: "account-manager", field: "accountManager" },
  { param: "customer", field: "code" },
  { param: "business-type", field: "businessType" },
  { param: "co-reg", field: "companyRegNo" },
  { param: "contact-name", field: "contactName" },
  { param: "phone", field: "phone" },
  { param: "email", field: "email" },
  { param: "company-id", field: "crmCompanyId" },
];
const addressParamsToFieldMappings = [
  { param: "line1", field: "line1" },
  { param: "line2", field: "line2" },
  { param: "town", field: "town" },
  { param: "county", field: "county" },
  { param: "postcode", field: "postcode" },
  { param: "country", field: "country" },
];

export default compose(
  withCurrentUser,
  withPermissions,
  withRouter,
)(Agreements);
