import { debounce, cloneDeep } from "lodash";
import { produce } from "immer";
import {
  apiGet,
  apiPut,
  apiPost,
  apiDelete,
  notifySuccess,
  getAuthClaim,
  getAuthClaims,
  lookupReset,
  lookupRequest,
  getLookupData,
  notifyError,
} from "@redriver/cinnamon";
import { claimExists } from "features/../../../shared/components/auth";
import { Targets, Actions } from "constants/permissions";
import {
  getSheetState,
  getSheetDataState,
  getSheetLargeFormatScheduleState,
  getCalculationData,
} from "../selectors";
import { LookupTypes as SettingsLookupTypes } from "features/Settings";
import {
  FunderType,
  UserType,
  ContractTypeDescription,
  SheetViewType,
} from "features/../../../shared/constants/enums";
import {
  LookupNames as SheetsLookupNames,
  ActionTypes as SheetsActionTypes,
} from "features/Sheets";
import FileSaver from "file-saver";
import {
  newLargeFormatScheduleGroup,
  isRealNumber,
  concatenateServiceArrays,
  isZeroOrEmpty,
  isEmpty,
} from "./helpers";
import { largeFormatCreateModalFieldName } from "./ScheduleStep/constants";

import { capitalInitialState } from "./ReducerHelpers";

import * as mortar from "mortar/endpoints/sheets";
import * as lookupMortar from "mortar/endpoints/lookups";

const NAMESPACE = "MANAGE_SHEET";

export const ActionTypes = {
  UpdateSheetData: `${NAMESPACE}/UPDATE_SHEET_DATA`,
  EnsureSheetValuesAreValid: `${NAMESPACE}/ENSURE_SHEET_DATA_VALID`,
  UpdateSheetSummary: `${NAMESPACE}/UPDATE_SHEET_SUMMARY`,
  SaveSheetData: `${NAMESPACE}/SAVE_SHEET_DATA`,
  LoadSheetDetails: `${NAMESPACE}/LOAD_SHEET_DETAILS`,
  ClearSheetDetails: `${NAMESPACE}/CLEAR_SHEET_DETAILS`,
  CalculateServicesTotal: `${NAMESPACE}/UPDATE_SERVICES_TOTAL`,
  CalculateSheet: `${NAMESPACE}/CALCULATE_SHEET`,
  PauseCalculation: `${NAMESPACE}/PAUSE_CALCULATION`,
  ResumeCalculation: `${NAMESPACE}/RESUME_CALCULATION`,
  ResetRequiresRecalculation: `${NAMESPACE}/RESET_REQUIRES_CALC`,
  SetRequiresRecalculation: `${NAMESPACE}/SET_REQUIRES_CALC`,
  SidePanelVisibility: `${NAMESPACE}/SET_PANEL_VISIBILITY`,
  SetSheetViewType: `${NAMESPACE}/SET_SHEET_VIEW_TYPE`,
  UpdateProposedVolumes: `${NAMESPACE}/UPDATE_PROPOSED_VOLUMES`,
  LoadNotes: `${NAMESPACE}/LOAD_NOTES`,
  LoadNote: `${NAMESPACE}/LOAD_NOTE`,
  CreateNote: `${NAMESPACE}/CREATE_NOTE`,
  DeleteNote: `${NAMESPACE}/DELETE_NOTE`,
  EditNote: `${NAMESPACE}/EDIT_NOTE`,
  LockSheet: `${NAMESPACE}/LOCK_SHEET`,
  UnlockSheet: `${NAMESPACE}/UNLOCK_SHEET`,
  CloneSheet: `${NAMESPACE}/CLONE`,
  SaveAsSheet: `${NAMESPACE}/SAVE_AS`,
  SubmitSheet: `${NAMESPACE}/SUBMIT`,
  SubmitSheetForAdminApproval: `${NAMESPACE}/SUBMIT_FOR_ADMIN_APPROVAL`,
  RenameSheet: `${NAMESPACE}/RENAME`,
  DownloadSheet: `${NAMESPACE}/DOWNLOAD`,
  DownloadSheetPreview: `${NAMESPACE}/DOWNLOAD_PREVIEW`,
  DownloadSheetContract: `${NAMESPACE}/DOWNLOAD_PREVIEW`,
  DownloadPaperInclusive: `${NAMESPACE}/DOWNLOAD_PI`,
  DownloadLargeFormatPrinting: `${NAMESPACE}/DOWNLOAD_LFP`,
  DownloadCapitalCalcAdminInfo: `${NAMESPACE}/DOWNLOAD_CC_ADMIN_INFO`,
  SetSheetOwner: `${NAMESPACE}/SET_SHEET_OWNER`,
  DeleteSheet: `${NAMESPACE}/DELETE_SHEET`,
  CrystalliseSheet: `${NAMESPACE}/CRYSTALLISE_SHEET`,
  UncrystalliseSheet: `${NAMESPACE}/UNCRYSTALLISE_SHEET`,
  SetCrystallised: `${NAMESPACE}/SET_CRYSTALLISED`,
  LoadSheetServices: `${NAMESPACE}/LOAD_SHEET_SERVICES`,
  SetOldSheetData: `${NAMESPACE}/SET_SHEET_DATA`,
  FillLargeFormatCreateModal: `${NAMESPACE}/FILL_IN_LARGE_FORMAT_MODAL`,
  FillLFModalOptionFields: `${NAMESPACE}/FILL_IN_LF_MODAL_OPTION_FIELDS`,
  VerifyUnlockView: `${NAMESPACE}/VERIFY_UNLOCK_VIEW`,
  RevertPaperInclusive: `${NAMESPACE}/REVERT_PAPER_INCLUSIVE`,
  CanDeleteScheduleItem: `${NAMESPACE}/CAN_DELETE_SCHEDULE_ITEM`,
  SetImpersonateUser: `${NAMESPACE}/SET_IMPERSONATED_USER`,
  DefaultImpersonatedUser: `${NAMESPACE}/DEFAULT_IMPERSONATED_USER`,
  GetAudits: `${NAMESPACE}/GET_AUDITS`,
  UpdateComparisonData: `${NAMESPACE}/UPDATE_COMPARISON_DATA`,
  EndSnapshot: `${NAMESPACE}/END_SNAPSHOT`,
  GetDuplicateSheetName: `${NAMESPACE}/DUPLICATE_SHEET_NAME`,
  UpdateLargeFormatProposedVolumes: `${NAMESPACE}/UPDATE_LARGE_FORMAT_PROPOSED_VOLUMES`,
  UpdatePaperFields: `${NAMESPACE}/UPDATE_PAPER_FIELDS`,
  CalculateComparisonData: `${NAMESPACE}/CALCULATE_COMPARISON_DATA`,
  ToggleSheetReadOnly: `${NAMESPACE}/TOGGLE_SHEET_READ_ONLY`,
  ArchiveSheet: `${NAMESPACE}/ARCHIVE_SHEET`,
  UnarchiveSheet: `${NAMESPACE}/UNARCHIVE_SHEET`,
  DownloadComparisonSnapshot: `${NAMESPACE}/DOWNLOAD_COMPARISON_SNAPSHOT`,
  DuplicateSheetToNewMaster: `${NAMESPACE}/DUPLICATE_SHEET_TO_NEW_MASTER`,
};

// Old Sheet Data is stored in a global variable so that the data can be compared before the user types and after the user finishes typing
const initializeOldSheetData = () => {
  window.OldSheetData = { timestamp: null, data: null };
};

const getOldSheetData = () =>
  window.OldSheetData ? window.OldSheetData.data : null;

const setOldSheetDataIfAppropriate = (oldSheetData) => {
  if (window.OldSheetData == null) initializeOldSheetData();

  if (
    !window.OldSheetData.timestamp ||
    window.OldSheetData.timestamp < Date.now() - 15000 ||
    !window.OldSheetData.data
  ) {
    window.OldSheetData.timestamp = Date.now();
    window.OldSheetData.data = oldSheetData;
  }
};

const clearOldSheetData = () => {
  if (window.OldSheetData == null) initializeOldSheetData();
  window.OldSheetData.timestamp = null;
  window.OldSheetData.data = null;
};

export const updateData =
  (field, sheetData, applyChanges) => (dispatch, getState) => {
    const oldSheetData = produce(getSheetState(getState()), () => {});
    const userType = getAuthClaim(getState(), "userType");
    const isDealerAdmin =
      userType == userType.Dealer &&
      claimExists(getAuthClaims(getState()), Targets.SheetAdmin, Actions.Edit);

    const unitData =
      (
        getLookupData(getState(), SettingsLookupTypes.Units, {
          contractType: oldSheetData.sheetData.contractType,
          isLargeFormat: false,
          sheetId: oldSheetData.sheetData.id,
        }) || {}
      ).response || [];

    const serviceData =
      (
        getLookupData(
          getState(),
          SheetsLookupNames[SheetsActionTypes.Services],
          {
            sheetId: oldSheetData.sheetData.id,
            search: "",
            includeMandatory: true,
          },
        ) || {}
      ).response || [];
    dispatch({
      type: ActionTypes.UpdateSheetData,
      sheetData,
      applyChanges,
      isDealerAdmin,
      serviceData,
      unitData,
      field,
      userType: getAuthClaim(getState(), "userType"),
      userArea: getAuthClaim(getState(), "userArea"),
    });

    // Store old sheet data in a global variable. Overwrite existing if null, or old and most likely out of date
    setOldSheetDataIfAppropriate(oldSheetData);

    debouncedNewUpdateForms(
      dispatch,
      getState,
      field,
      serviceData,
      applyChanges,
      sheetData,
    );
  };

const newUpdateForms = async (
  dispatch,
  getState,
  field,
  serviceData,
  applyChanges,
  sheetData,
) => {
  const oldSheetData = getOldSheetData()?.sheetData;
  const oldServices = concatenateServiceArrays(oldSheetData?.services);

  const newSheetData = getSheetDataState(getState());
  const services = concatenateServiceArrays(newSheetData.services);

  const hasServiceBeenDeleted = (oldServices?.length ?? 0) > services.length;

  dispatch({
    type: ActionTypes.CalculateServicesTotal,
    services,
    serviceData,
    totalDevicesOnSchedule: newSheetData.capital.totalDevicesOnSchedule.value,
    userType: getAuthClaim(getState(), "userType"),
    userArea: getAuthClaim(getState(), "userArea"),
  });
  const includeNewLargeFormatItems = field === largeFormatCreateModalFieldName;

  if (
    !getSheetState(getState()).calculationPaused &&
    (getSheetState(getState()).requiresRecalculation ||
      shouldRecalculate(field, sheetData, applyChanges, getOldSheetData()) ||
      hasServiceBeenDeleted)
  ) {
    await dispatch(calculateSheet(includeNewLargeFormatItems));
    await dispatch(calculateComparisonData());
    dispatch(resetRequiresRecalculation());
  }
  // Clear cached old sheet data
  clearOldSheetData();
};

export const calculateFormOnChange = (sheetData) => (dispatch, getState) => {
  if (
    !getSheetState(getState()).calculationPaused &&
    shouldRecalculateOnFormChange(sheetData?.field)
  ) {
    calculateFormInternal(dispatch, getState);
  }
};

//function to determine wether we should recalculate on for change
const shouldRecalculateOnFormChange = (fieldName) => {
  const fieldsToTriggerRecalculate = [
    "funderSelected",
    "dealerFunderSelected",
    "cofundedSelected",
    "excessAllocatedToSchedule",
    "includeExcessAndVolumeCredit",
    "actualExcess",
  ];

  return fieldsToTriggerRecalculate.some((x) => x === fieldName);
};

export const calculateForm =
  (debounced = true) =>
  (dispatch, getState) => {
    debounced
      ? debouncedCalculateForm(dispatch, getState)
      : calculateFormInternal(dispatch, getState);
  };

const calculateFormInternal = async (dispatch, getState) => {
  const sheetData = getSheetDataState(getState());
  const unitData =
    (
      getLookupData(getState(), SettingsLookupTypes.Units, {
        contractType: sheetData.contractType,
        isLargeFormat: false,
        sheetId: sheetData.id,
      }) || {}
    ).response || [];

  const serviceData =
    (
      getLookupData(getState(), SheetsLookupNames[SheetsActionTypes.Services], {
        sheetId: sheetData.id,
        search: "",
        includeMandatory: true,
      }) || {}
    ).response || [];

  const largeFormatUnitData =
    (
      getLookupData(
        getState(),
        SettingsLookupTypes.LargeFormatMeteredServices,
        {
          contractType: sheetData.contractType,
          sheetId: sheetData.id,
        },
      ) || {}
    ).response || [];

  await dispatch({
    type: ActionTypes.EnsureSheetValuesAreValid,
    unitData,
    serviceData,
    largeFormatUnitData,
    userType: getAuthClaim(getState(), "userType"),
    userArea: getAuthClaim(getState(), "userArea"),
  });

  if (!getSheetState(getState()).calculationPaused) {
    await dispatch(calculateSheet(getState(), true));
    await dispatch(calculateComparisonData(getState()));
  }
  clearOldSheetData();
};

const debouncedCalculateForm = debounce(
  (dispatch, getState) => calculateFormInternal(dispatch, getState),
  750,
);

const debouncedNewUpdateForms = debounce(
  (dispatch, getState, field, serviceData, applyChanges, sheetData) =>
    newUpdateForms(
      dispatch,
      getState,
      field,
      serviceData,
      applyChanges,
      sheetData,
    ),
  1000,
);

const shouldRecalculate = (field, sheetData) => {
  // Recalculation occurs when pressing Enter, Tab or Alt key, or clicking away from an input field.
  // For other inputs such as dropdowns and toggles, we need to check this manually so when updated, a recalculation is triggered.
  let result = false;
  try {
    const changeJson = JSON.stringify({ field, sheetData });
    const fieldsToTriggerRecalculate = [
      // Schedule Setup
      "financeType",
      "fmvType",
      "paymentMethod",
      "sheetType",
      // Capital
      "funderSelected",
      "dealerFunderSelected",
      "cofundedSelected",
      "isUpgrade",
      "coTerminusRentals",
      "ipCoTerminusRentals",
      // Quarterly Services
      "service",
      // Paper
      "isPaperInclusive",
      "paperId",
      "paperIncomeAdjustment",
      "isCostEffectiveA3",
      "paperInclusiveChargeType",
      // System Schedule
      "includeManagedVol",
      // Large Format Printing create/edit modal
      "printerId",
      "lfInkInclusive",
      "includeScan",
      "includeCleaning",
      "printerInkUsageId",
      "lfRollMedia",
      "compPrinterInkUsageId",
      "priceType",
      //schedule stability,
      "agreementStabilityOption",
      "comparisonAdjustmentQtrFinance",
      "comparisonAdjustmentQtrService",
      "sheetOverrideVMax",
    ];
    fieldsToTriggerRecalculate.forEach((fieldName) => {
      if (new RegExp(`"${fieldName}"`).test(changeJson)) {
        result = true;
      }
    });
  } catch (error) {
    return false;
  }

  return result;
};

export const resetRequiresRecalculation = () => ({
  type: ActionTypes.ResetRequiresRecalculation,
});

export const setRequiresRecalculation = () => ({
  type: ActionTypes.SetRequiresRecalculation,
});

const getSheetDetailsToPost = (state, includeNewLargeFormatItems) => {
  const { sheetData } = getSheetState(state);
  let newSheetData = cloneDeep(sheetData);

  //if capital area not selected, filter out area
  if (!newSheetData.capital.funderSelected) {
    newSheetData.capital.funder = capitalInitialState(FunderType.Funder);
  } else {
    newSheetData.capital.funder.settlements =
      newSheetData.capital.funder.settlements.concat(
        newSheetData.capital.funder.adminSettlements || [],
      );
    newSheetData.capital.funder.settlements =
      newSheetData.capital.funder.settlements.concat(
        newSheetData.capital.funder.ipSettlements || [],
      );
    newSheetData.capital.funder.coTerminusRentals =
      newSheetData.capital.funder.coTerminusRentals.concat(
        newSheetData.capital.funder.ipCoTerminusRentals || [],
      );
  }

  if (!newSheetData.capital.cofundedSelected) {
    newSheetData.capital.cofunded = capitalInitialState(FunderType.CoFunded);
  } else {
    newSheetData.capital.cofunded.settlements =
      newSheetData.capital.cofunded.settlements.concat(
        newSheetData.capital.cofunded.adminSettlements || [],
      );
    newSheetData.capital.cofunded.settlements =
      newSheetData.capital.cofunded.settlements.concat(
        newSheetData.capital.cofunded.ipSettlements || [],
      );
    newSheetData.capital.cofunded.coTerminusRentals =
      newSheetData.capital.cofunded.coTerminusRentals.concat(
        newSheetData.capital.cofunded.ipCoTerminusRentals || [],
      );
  }
  if (!newSheetData.capital.dealerFunderSelected) {
    newSheetData.capital.dealer = capitalInitialState(FunderType.Dealer);
  } else {
    newSheetData.capital.dealer.settlements =
      newSheetData.capital.dealer.settlements.concat(
        newSheetData.capital.dealer.adminSettlements || [],
      );
    newSheetData.capital.dealer.settlements =
      newSheetData.capital.dealer.settlements.concat(
        newSheetData.capital.dealer.ipSettlements || [],
      );
    newSheetData.capital.dealer.coTerminusRentals =
      newSheetData.capital.dealer.coTerminusRentals.concat(
        newSheetData.capital.dealer.ipCoTerminusRentals || [],
      );
  }

  newSheetData.services.services = concatenateServiceArrays(
    newSheetData.services,
  );

  delete newSheetData.defaults.papers;

  if (includeNewLargeFormatItems) {
    const lf = newSheetData.largeFormatCreateModal;
    //Add extra Large Format item to the post model to calculate the paper & ink prices if it were to be added
    if (lf && lf.printerId) {
      newSheetData.largeFormatCalcGroup = newLargeFormatScheduleGroup(
        "id",
        lf,
        sheetData.largeFormatDefaults,
        sheetData.largeFormatGroups,
        true,
        newSheetData,
      );
    }
    //update large format group edit modes.
    let lfg = newSheetData.largeFormatGroups;
    const editIndex = newSheetData.largeFormatCreateModal.groupIndex;
    lfg.forEach((group, index) => {
      group.editMode = index == editIndex;
    });
    newSheetData.largeFormatGroups = lfg;
  }

  return {
    ...newSheetData,
    defaults: null,
  };
};

export const updateComparisonData = () => (dispatch) => {
  dispatch({ type: ActionTypes.UpdateComparisonData });
};

export const endSnapshot = () => (dispatch) => {
  dispatch({ type: ActionTypes.EndSnapshot });
};

export const isDataPresent = () => (dispatch, getState) => {
  const scheduleData = getSheetState(getState()).sheetData.systemScheduleItems;
  let dataPresent = true;
  scheduleData.map((item) => {
    if (item.unit.value == null) {
      dataPresent = false;
    }
  });
  return dataPresent;
};

export const updateHeader = (sheetId) => async (dispatch) => {
  await dispatch(
    lookupReset(SheetsLookupNames[SheetsActionTypes.HeaderDetails]),
  );
  await dispatch(
    lookupRequest(SheetsLookupNames[SheetsActionTypes.HeaderDetails], {
      sheetId,
    }),
  );
};

export const loadUnitLookupData = (sheetId) => async (dispatch) => {
  await dispatch(lookupReset(SettingsLookupTypes.Units));
  await dispatch(
    lookupRequest(SettingsLookupTypes.Units, {
      contractType: ContractTypeDescription.PaperContract,
      isLargeFormat: false,
      sheetId: sheetId,
    }),
  );
  await dispatch(lookupReset(SettingsLookupTypes.LargeFormatMeteredServices));
  await dispatch(
    lookupRequest(SettingsLookupTypes.LargeFormatMeteredServices, {
      contractType: ContractTypeDescription.PaperContract,
      sheetId: sheetId,
    }),
  );
};

export const saveDetails =
  (
    id,
    skipHeaderUpdate = false,
    redirectFunction = null,
    cancelReload = false,
  ) =>
  async (dispatch, getState) => {
    const sheetData = getSheetDetailsToPost(getState(), false);
    const response = await dispatch(
      apiPut(ActionTypes.SaveSheetData, `sheets/${sheetData.id}`, sheetData),
    );

    if (!response.success) return response;
    if (!skipHeaderUpdate) {
      await dispatch(
        lookupReset(SheetsLookupNames[SheetsActionTypes.HeaderDetails]),
      );
      await dispatch(
        lookupRequest(SheetsLookupNames[SheetsActionTypes.HeaderDetails], {
          sheetId: sheetData.id,
        }),
      );
    }

    if (redirectFunction) {
      redirectFunction();
    } else if (!cancelReload) {
      await dispatch(loadSheetDetails(sheetData.id));
    }

    await dispatch(notifySuccess("Sheet updated successfully"));

    return response;
  };

export const loadSheetDetails = (id) => async (dispatch, getState) => {
  const serviceData =
    (
      getLookupData(getState(), SheetsLookupNames[SheetsActionTypes.Services], {
        sheetId: id,
        search: "",
        includeMandatory: true,
      }) || {}
    ).response || [];

  await dispatch(
    apiGet(ActionTypes.LoadSheetDetails, `sheets/${id}`, null, {
      actionCustomData: {
        serviceData,
        userType: getAuthClaim(getState(), "userType"),
        userArea: getAuthClaim(getState(), "userArea"),
      },
    }),
  );

  await calculateFormInternal(dispatch, getState);
};

export const clearSheetDetails = () => (dispatch) =>
  dispatch({
    type: ActionTypes.ClearSheetDetails,
  });

export const lfHasManagedVolInc = (lfGroups) =>
  Array.isArray(lfGroups) &&
  lfGroups
    .map((x) =>
      (x.paperMeters || []).concat([x.otherMeter, x.printerMeter, x.scanMeter]),
    )
    .flat()
    .filter((x) => x)
    .some((x) => x.includeManagedVol.value);

export let cannotCalcMessages = {};

export const getMaximumRrpAllowed = (calculationData, funderType) => {
  if (!calculationData || !calculationData.breakdown) return null;

  const calcItemCosts = calculationData.breakdown.capitalCalcCosts.itemCosts;
  const hasFunderType = calcItemCosts.some((x) => x.funderType == funderType);
  return calculationData && hasFunderType
    ? calcItemCosts.find((x) => x.funderType == funderType)
        .maxProductCapitalAllowed
    : null;
};

export const canCalculate = (
  sheetData,
  calculationData,
  includeNewLargeFormatItems = false,
) => {
  const errorConcatHelper = (errs) =>
    errs.length > 0
      ? `Cannot calculate/save because ${errs.join(", and ")}`
      : "";

  let errorText,
    result = true,
    calcErrorsFull = [],
    calcErrorsCapital = [],
    calcErrorsServices = [],
    calcErrorsSchedules = [];

  if (includeNewLargeFormatItems) {
    const {
      largeFormatCreateModal: { printerId, printerInkUsageId },
    } = sheetData;

    if (!printerId || !printerInkUsageId) {
      result = false;
      errorText = "Create Large Format item form is not filled in";
      calcErrorsFull.push(errorText);
      calcErrorsSchedules.push(errorText);
    }
  }

  //do not calculate if no calc items selected.
  const {
    funderSelected,
    cofundedSelected,
    dealerFunderSelected,
    dealer,
    cofunded,
    funderTotalCosts, // Product + Other costs
    dealerTotalCosts,
    cofundedTotalCosts,
  } = sheetData.capital;
  if (
    !cofundedSelected.value &&
    !dealerFunderSelected.value &&
    !funderSelected.value
  ) {
    errorText = "there are no Capital Calc items";
    calcErrorsFull.push(errorText);
    calcErrorsCapital.push(errorText);
    result = false; //don't exit early
  } else if (
    (cofundedSelected.value &&
      !sheetData.capital.cofunded.financeSelection.value) ||
    (funderSelected.value &&
      !sheetData.capital.funder.financeSelection.value) ||
    (dealerFunderSelected.value &&
      !sheetData.capital.dealer.financeSelection.value)
  ) {
    errorText = "there are Capital Calc items with no Finance Selection";
    calcErrorsFull.push(errorText);
    calcErrorsCapital.push(errorText);
    result = false; //don't exit early
  }

  if (
    dealerFunderSelected.value &&
    Number.isNaN(parseFloat(dealer.ipSyndicatePercentageYield.value))
  ) {
    errorText = 'supplier funder is selected, but no value for "% of yield"';
    calcErrorsFull.push(errorText);
    calcErrorsCapital.push(errorText);
    result = false; //don't exit early
  }

  if (
    cofundedSelected.value &&
    Number.isNaN(parseFloat(cofunded.coFundedDealerPercentage.value))
  ) {
    errorText = 'co-funded is selected, but no value for "Supplier Co-Fund %"';
    calcErrorsFull.push(errorText);
    calcErrorsCapital.push(errorText);
    result = false; //don't exit early
  }

  const funderMax = getMaximumRrpAllowed(calculationData, FunderType.Funder);
  if (
    funderSelected.value &&
    funderTotalCosts != null &&
    funderMax != null &&
    funderTotalCosts > funderMax
  ) {
    errorText = "funder total exceeds the maximum RRP allowance";
    calcErrorsFull.push(errorText);
    calcErrorsCapital.push(errorText);
    // Do not prevent calculation, as fixing requires a recalculation to check.
  }

  const dealerMax = getMaximumRrpAllowed(calculationData, FunderType.Dealer);
  if (
    dealerFunderSelected.value &&
    dealerTotalCosts != null &&
    dealerMax != null &&
    dealerTotalCosts > dealerMax
  ) {
    errorText = "supplier total exceeds the maximum RRP allowance";
    calcErrorsFull.push(errorText);
    calcErrorsCapital.push(errorText);
    // Do not prevent calculation, as fixing requires a recalculation to check.
  }

  const cofundedMax = getMaximumRrpAllowed(
    calculationData,
    FunderType.CoFunded,
  );
  if (
    cofundedSelected.value &&
    cofundedTotalCosts != null &&
    cofundedMax != null &&
    cofundedTotalCosts > cofundedMax
  ) {
    errorText = "co-funded total exceeds the maximum RRP allowance";
    calcErrorsFull.push(errorText);
    calcErrorsCapital.push(errorText);
    // Do not prevent calculation, as fixing requires a recalculation to check.
  }

  //do not calculate if services exist without service or value
  if (
    concatenateServiceArrays(sheetData.services).some(
      (x) => !x.service || !x.service.value || isNaN(parseInt(x.value.value)),
    )
  ) {
    errorText = "there are Services without a selected Service or value";
    calcErrorsFull.push(errorText);
    calcErrorsServices.push(errorText);
    result = false; //don't exit early
  }

  // Paper
  const pi = sheetData.paperInclusive;
  if (pi && pi?.isPaperInclusive?.value) {
    if (
      (pi.a3ContractedPaperSpend &&
        !isRealNumber(pi.a3ContractedPaperSpend.pricePerReam.value)) ||
      (pi.a4ContractedPaperSpend &&
        !isRealNumber(pi.a4ContractedPaperSpend.pricePerReam.value))
    ) {
      errorText =
        "there are Contracted Paper rows without a Price per Ream value";
      calcErrorsFull.push(errorText);
      result = false;
    }
  }

  // Do not calculate if no schedule items that are included in managed volume, or any are part-filled in
  // && New Large Format popup Modal isn't open
  if (
    (!sheetData.systemScheduleItems ||
      (!sheetData.systemScheduleItems.some((x) => x.includeManagedVol.value) &&
        !lfHasManagedVolInc(sheetData.largeFormatGroups)) ||
      sheetData.systemScheduleItems.some((x) => !x.unit.value) ||
      sheetData.systemScheduleItems.some((x) =>
        isNaN(parseFloat(x.costPerClick.value)),
      ) ||
      sheetData.systemScheduleItems.some((x) =>
        isNaN(parseInt(x.minQuarterly.value)),
      )) &&
    !(
      sheetData.largeFormatCreateModal.printerChanged &&
      sheetData.largeFormatCreateModal.printerId
    )
  ) {
    errorText =
      "there are no Schedule Items included in managed volume (or some have not been completed)";
    calcErrorsFull.push(errorText);
    calcErrorsSchedules.push(errorText);
    result = false; //don't exit early
  }

  // Do not calculate if the Large Format Create Modal has incomplete paper items
  if (
    sheetData.largeFormatCreateModal?.lfRollMedia?.some(
      (pm) =>
        pm &&
        ((isEmpty(pm.lfPaperInclusive) && isEmpty(pm.paperInclusiveId)) ||
          isEmpty(pm.lfRollMedia)),
    )
  ) {
    errorText = "the Large Format modal form paper section is incomplete";
    calcErrorsFull.push(errorText);
    calcErrorsSchedules.push(errorText);
    result = false;
  }

  // Do not calculate if Large Format unit Min. Qtr is Empty
  if (
    sheetData.largeFormatGroups
      ? sheetData.largeFormatGroups.some(
          (x) =>
            (x.printerMeter &&
              !(
                x.printerMeter.minQuarterlyVolume.value ||
                x.printerMeter.minQuarterlyVolume.value === 0
              )) ||
            (x.scanMeter &&
              !(
                x.scanMeter.minQuarterlyVolume.value ||
                x.scanMeter.minQuarterlyVolume.value === 0
              )) ||
            (x.otherMeter &&
              !(
                x.otherMeter.minQuarterlyVolume.value ||
                x.otherMeter.minQuarterlyVolume.value === 0
              )) ||
            x.paperMeters.some(
              (pm) =>
                pm &&
                !(
                  pm.minQuarterlyVolume.value ||
                  pm.minQuarterlyVolume.value === 0
                ),
            ),
        )
      : false
  ) {
    errorText =
      "there are large format schedule items that are only part filled in";
    calcErrorsFull.push(errorText);
    calcErrorsSchedules.push(errorText);
    result = false; //don't exit early
  }

  if (
    sheetData.systemScheduleItems &&
    sheetData.systemScheduleItems.some(
      (x) =>
        isZeroOrEmpty(x.costPerClick.value) ||
        isZeroOrEmpty(x.minQuarterly.value),
    )
  ) {
    errorText = "there are Schedule units with a CPI or Min Qtr of 0 ";
    calcErrorsFull.push(errorText);
    calcErrorsSchedules.push(errorText);
    result = false; //don't exit early
  }

  if (
    sheetData.largeFormatGroups &&
    sheetData.largeFormatGroups.some(
      (x) =>
        (x.otherMeter != null &&
          (isZeroOrEmpty(x.otherMeter.costPerClick.value) ||
            isZeroOrEmpty(x.otherMeter.minQuarterlyVolume.value))) ||
        (x.paperMeter != null &&
          (isZeroOrEmpty(x.paperMeter.costPerClick.value) ||
            isZeroOrEmpty(x.paperMeter.minQuarterlyVolume.value))) ||
        (x.printerMeter != null &&
          (isZeroOrEmpty(x.printerMeter.costPerClick.value) ||
            isZeroOrEmpty(x.printerMeter.minQuarterlyVolume.value))) ||
        (x.scannerMeter != null &&
          (isZeroOrEmpty(x.scannerMeter.costPerClick.value) ||
            isZeroOrEmpty(x.scannerMeter.minQuarterlyVolume.value))),
    )
  ) {
    errorText =
      "there are Schedule large format items with a CPI or Min Qtr of 0 ";
    calcErrorsFull.push(errorText);
    calcErrorsSchedules.push(errorText);
    result = false; //don't exit early
  }

  const excessAllocated = sheetData.excessAllocation?.excessAllocatedToSchedule;
  const actualExcess = sheetData.excessAllocation?.actualExcess;
  if (
    isRealNumber(excessAllocated) &&
    isRealNumber(actualExcess) &&
    parseFloat(excessAllocated) > parseFloat(actualExcess)
  ) {
    errorText =
      "Excess Allocated to Schedule cannot be greater than Actual Excess";
    calcErrorsFull.push(errorText);
    calcErrorsSchedules.push(errorText);
    result = false; //don't exit early
  }

  cannotCalcMessages = {
    errors: calcErrorsFull,
    full: errorConcatHelper(calcErrorsFull),
    capital: errorConcatHelper(calcErrorsCapital),
    services: errorConcatHelper(calcErrorsServices),
    schedules: errorConcatHelper(calcErrorsSchedules),
  };

  return result;
};

export const pauseCalculation = () => async (dispatch) =>
  await dispatch({
    type: ActionTypes.PauseCalculation,
  });

export const resumeCalculation = () => async (dispatch) =>
  await dispatch({
    type: ActionTypes.ResumeCalculation,
  });

export const calculateSheet =
  (includeNewLargeFormatItems) => async (dispatch, getState) => {
    const state = getState();
    const sheetData = getSheetDetailsToPost(state, includeNewLargeFormatItems);
    if (!canCalculate(sheetData, getCalculationData(state))) {
      return;
    }

    return await dispatch(
      mortar.calculateSheet({
        routeParams: { sheetId: sheetData.id },
        request: sheetData,
        options: {
          actionCustomData: {
            includeNewLargeFormatItems,
          },
        },
      }),
    );
  };

export const calculateComparisonData = () => (dispatch, getState) => {
  const state = getState();
  const sheetData = getSheetDetailsToPost(state, true);
  if (!canCalculate(sheetData, getCalculationData(state))) {
    return;
  }

  return {
    type: ActionTypes.CalculateComparisonData,
  };
};

export const setSidePanelVisibility = (actions, comparison) => ({
  type: ActionTypes.SidePanelVisibility,
  actions,
  comparison,
});

export const setDefaultSheetViewType = (userType, canEditSheetAdminFields) => {
  const sheetViewType =
    userType === UserType.HQ
      ? SheetViewType.IpsAdmin
      : canEditSheetAdminFields
      ? SheetViewType.SupplierAdmin
      : SheetViewType.AccountManager;
  return {
    type: ActionTypes.SetSheetViewType,
    sheetViewType,
  };
};

export const setSheetViewType = (sheetViewType) => ({
  type: ActionTypes.SetSheetViewType,
  sheetViewType,
});

export const updateProposedVolumes = (formData) => {
  return {
    type: ActionTypes.UpdateProposedVolumes,
    formData,
  };
};

export const updateLargeFormatProposedVolumes = (formData) => (dispatch) => {
  dispatch({
    type: ActionTypes.UpdateLargeFormatProposedVolumes,
    formData,
  });
};

export const updatePaperFields = (formData) => (dispatch) => {
  dispatch({
    type: ActionTypes.UpdatePaperFields,
    formData,
  });
};

export const setImpersonateUser = (data) => (dispatch) => {
  dispatch({
    type: ActionTypes.SetImpersonateUser,
    data: data.options.find((x) => x.value == data.value),
  });
  return data.options.find((x) => x.value == data.value);
};

export const defaultImpersonatedUser = () => (dispatch) => {
  dispatch({ type: ActionTypes.DefaultImpersonatedUser });
};

export const createNote = (formData, sheetId) =>
  mortar.createNote({
    request: formData,
    routeParams: {
      sheetId,
    },
  });

export const editNote = (formData, { sheetId, noteId }) =>
  mortar.editNote({
    request: formData,
    routeParams: { sheetId, noteId },
  });

export const deleteNote = (formData, { sheetId, noteId }) =>
  mortar.deleteNote({
    routeParams: { sheetId, noteId },
  });

export const getNotes = ({ filters, pagination }, sheetId) =>
  mortar.getNotes({
    routeParams: { sheetId },
    queryParams: {
      ...filters,
      ...pagination,
    },
  });

export const lockSheet = (sheetId) =>
  apiPut(ActionTypes.LockSheet, `sheets/${sheetId}/lock`);

export const unlockSheet = (sheetId) =>
  apiPut(ActionTypes.UnlockSheet, `sheets/${sheetId}/unlock`);

export const cloneSheet =
  ({ name }, sheetId) =>
  async (dispatch) => {
    let response = await dispatch(
      mortar.cloneSheet({
        request: { name },
        routeParams: { sheetId },
      }),
    );
    response &&
      response.success &&
      dispatch(notifySuccess("Sheet Duplicated Successfully"));
    return response;
  };

export const duplicateSheetToNewMaster =
  ({ name }, { sheetId, clientId }) =>
  async (dispatch) => {
    let response = await dispatch(
      mortar.duplicateSheetToNewMaster({
        routeParams: { sheetId },
        request: {
          name,
          clientId,
        },
      }),
    );
    response &&
      response.success &&
      dispatch(notifySuccess("Sheet Duplicated To New Master Successfully"));
    return response;
  };

export const saveAsSheet =
  ({ name }, { sheetId, successMessage }) =>
  async (dispatch, getState) => {
    const sheetData = getSheetDetailsToPost(getState(), false);
    let response = await dispatch(
      mortar.sheetSaveAs({
        routeParams: { sheetId },
        request: { ...sheetData, name },
      }),
    );
    response && response.success && dispatch(notifySuccess(successMessage));
    return response;
  };

export const setSheetOwners = ({ ownedById }, { sheetId, dealerId }) => {
  const dealerOfficeId = dealerId;
  return mortar.updateSheetOwner({
    request: { ownedById, dealerOfficeId },
    routeParams: { sheetId },
  });
};
export const deleteSheet = (formData, { sheetId }) => {
  return mortar.deleteSheet({
    routeParams: { sheetId },
    options: formData,
  });
};

export const crystalliseSheet = (_, sheetId) =>
  mortar.crystalliseSheet({
    routeParams: { sheetId },
  });

export const uncrystalliseSheet = (_, sheetId) =>
  mortar.uncrystalliseSheet({
    routeParams: { sheetId },
  });

export const setCrystallised = (isCrystallised) => ({
  type: ActionTypes.SetCrystallised,
  isCrystallised,
});

export const archiveSheet = (_, sheetId) =>
  mortar.archiveSheet({
    routeParams: { sheetId },
  });

export const unarchiveSheet = (_, sheetId) =>
  mortar.unarchiveSheet({
    routeParams: { sheetId },
  });

export const renameSheet = (formData, { sheetId }) =>
  mortar.renameSheet({
    routeParams: { sheetId },
    request: formData,
  });

export const downloadSheet =
  ({ loadRewrite, sheetId, loadAdmin }) =>
  async (dispatch) => {
    let result = await dispatch(
      mortar.downloadSheet({
        routeParams: { sheetId },
        options: {
          loadAdmin,
          loadRewrite,
        },
      }),
    );
    if (result.response?.size > 0) {
      // Do not use response.success because 204 is "No Content" but is a successful call.
      FileSaver.saveAs(result.response, "Sheet.pdf");
    }
    return result;
  };
export const downloadSheetAdminVersion =
  ({ sheetId }) =>
  async (dispatch) => {
    let result = await dispatch(
      apiGet(ActionTypes.DownloadSheet, `sheets/${sheetId}/download`),
    );
    if (result.response?.size > 0) {
      // Do not use response.success because 204 is "No Content" but is a successful call.
      FileSaver.saveAs(result.response, "Admin Print Principal.pdf");
    }
    return result;
  };

export const getSheetContract =
  ({ sheetId }, params, options) =>
  async (dispatch) =>
    await dispatch(
      mortar.downloadSheetContract({
        queryParams: params,
        routeParams: { sheetId },
        options,
      }),
    );

export const downloadSheetContractWithoutSubmitting =
  (sheetId, watermark) => async (dispatch) => {
    const getPdfResult = await dispatch(
      getSheetContract(
        { sheetId },
        { watermark },
        { preventErrorNotification: true },
      ),
    );

    getPdfResult.response?.size > 0 && // Do not use response.success because 204 is "No Content" but is a successful call.
      FileSaver.saveAs(getPdfResult.response, "Sheet-Contract.pdf");
  };

export const getProductScheduleAddendum =
  ({ sheetId }, params, options) =>
  async (dispatch) =>
    await dispatch(
      mortar.downloadProductScheduleAddendum({
        queryParams: params,
        routeParams: { sheetId },
        options,
      }),
    );

export const downloadProductScheduleAddendum =
  (sheetId) => async (dispatch) => {
    const getPdfResult = await dispatch(
      getProductScheduleAddendum({ sheetId }, null, {
        preventErrorNotification: false,
      }),
    );

    getPdfResult.response?.size > 0 && // Do not use response.success because 204 is "No Content" but is a successful call.
      FileSaver.saveAs(getPdfResult.response, "Product-Schedule-Addendum.pdf");
  };

export const getPrincipalSoftwareSupportAddendum =
  ({ sheetId }, params, options) =>
  async (dispatch) =>
    await dispatch(
      mortar.downloadPrincipalSoftwareSupportAddendum({
        queryParams: params,
        routeParams: { sheetId },
        options,
      }),
    );
export const downloadPrincipalSoftwareSupportAddendum =
  (sheetId) => async (dispatch) => {
    const getPdfResult = await dispatch(
      getPrincipalSoftwareSupportAddendum({ sheetId }, null, {
        preventErrorNotification: false,
      }),
    );
    getPdfResult.response?.size > 0 && // Do not use response.success because 204 is "No Content" but is a successful call.
      FileSaver.saveAs(
        getPdfResult.response,
        "Principal-Software-Support-Addendum.pdf",
      );
  };

export const getPrincipalLargeFormatAddendum =
  ({ sheetId }, params, options) =>
  async (dispatch) =>
    await dispatch(
      mortar.downloadPrincipalLfpAddendum({
        queryParams: params,
        routeParams: { sheetId },
        options,
      }),
    );

export const downloadPrincipalLargeFormatAddendum =
  (sheetId) => async (dispatch) => {
    const getPdfResult = await dispatch(
      getPrincipalLargeFormatAddendum({ sheetId }, null, {
        preventErrorNotification: false,
      }),
    );

    getPdfResult.response?.size > 0 && // Do not use response.success because 204 is "No Content" but is a successful call.
      FileSaver.saveAs(
        getPdfResult.response,
        "Principal-Large-Format-Addendum.pdf",
      );
  };
export const getDirectDebitAddendum =
  ({ sheetId }, params, options) =>
  async (dispatch) =>
    await dispatch(
      mortar.downloadDirectDebitAddendum({
        queryParams: params,
        routeParams: { sheetId },
        options,
      }),
    );

export const downloadDirectDebitAddendum = (sheetId) => async (dispatch) => {
  const getPdfResult = await dispatch(
    getDirectDebitAddendum({ sheetId }, null, {
      preventErrorNotification: false,
    }),
  );

  getPdfResult.response?.size > 0 && // Do not use response.success because 204 is "No Content" but is a successful call.
    FileSaver.saveAs(getPdfResult.response, "Direct-Debit-Addendum.pdf");
};
export const getPaperInclusiveAddendum =
  ({ sheetId }, params, options) =>
  async (dispatch) =>
    await dispatch(
      mortar.downloadPaperInclusiveAddendum({
        queryParams: params,
        routeParams: { sheetId },
        options,
      }),
    );

export const downloadPaperInclusiveAddendum = (sheetId) => async (dispatch) => {
  const getPdfResult = await dispatch(
    getPaperInclusiveAddendum({ sheetId }, null, {
      preventErrorNotification: true,
    }),
  );

  getPdfResult.response?.size > 0 &&
    FileSaver.saveAs(getPdfResult.response, "Paper-Inclusive-Addendum.pdf");
};
export const downloadSheetContract =
  ({ sheetId, watermark }) =>
  async (dispatch) => {
    if (!process.env.TRADE_AS_IPS) {
      const submitResult = await dispatch(
        mortar.submitSheet({ routeParams: { sheetId } }),
      );

      if (submitResult?.success) {
        dispatch(notifySuccess("Sheet successfully submitted"));
        dispatch(setCrystallised(true));
      } else {
        submitResult?.error?.response?.forEach((x) =>
          dispatch(notifyError(x?.message)),
        );
        return submitResult;
      }
    }

    const getPdfResult = await dispatch(
      getSheetContract(
        { sheetId },
        { watermark },
        { preventErrorNotification: true },
      ),
    );

    getPdfResult.response?.size > 0 && // Do not use response.success because 204 is "No Content" but is a successful call.
      FileSaver.saveAs(getPdfResult.response, "Sheet-Contract.pdf");

    return getPdfResult;
  };

export const downloadPrintPreview =
  ({ sheetId }) =>
  async (dispatch) => {
    const getPdfResult = await dispatch(
      getSheetContract(
        { sheetId },
        { watermark: true, isPreview: true },
        { preventErrorNotification: true },
      ),
    );

    getPdfResult.response?.size > 0 && // Do not use response.success because 204 is "No Content" but is a successful call.
      FileSaver.saveAs(getPdfResult.response, "Sheet-Contract-Preview.pdf");
    return getPdfResult;
  };

export const downloadPaperInclusiveSummary =
  ({ sheetId }) =>
  async (dispatch) => {
    const result = await dispatch(
      apiGet(
        ActionTypes.DownloadPaperInclusive,
        `sheets/${sheetId}/download/paper-inclusive`,
      ),
    );

    result.response?.size > 0 &&
      FileSaver.saveAs(result.response, "Paper-Inclusive.pdf"); // Do not use response.success because 204 is "No Content" but is a successful call.
    return result;
  };

export const downloadLargeFormatPrinting =
  ({ sheetId, loadAdmin = false }) =>
  async (dispatch) => {
    const result = await dispatch(
      apiGet(
        ActionTypes.DownloadLargeFormatPrinting,
        `sheets/${sheetId}/download/lfp`,
        { loadAdmin },
      ),
    );
    if (result.response?.size > 0) {
      // Do not use response.success because 204 is "No Content" but is a successful call.)
      FileSaver.saveAs(
        result.response,
        loadAdmin ? "Large-Format-Admin.pdf" : "Large-Format.pdf",
      );
    }
    dispatch({
      type: ActionTypes.ResumeCalculation,
    });
    return result;
  };

export const downloadCapitalCalcAdminInfo =
  ({ sheetId }) =>
  async (dispatch) => {
    const result = await dispatch(
      apiGet(
        ActionTypes.DownloadCapitalCalcAdminInfo,
        `sheets/${sheetId}/download/capital-calc-admin-info`,
      ),
    );
    if (result.response?.size > 0) {
      // Do not use response.success because 204 is "No Content" but is a successful call.
      FileSaver.saveAs(result.response, "Capital-Calc-Admin-Info.pdf");
    }
    return result;
  };

export const downloadComparisonSnapshot =
  ({ sheetId }) =>
  async (dispatch) => {
    const result = await dispatch(
      apiGet(
        ActionTypes.DownloadComparisonSnapshot,
        `sheets/${sheetId}/download/comparison-snapshot`,
        {},
        { preventErrorNotification: true },
      ),
    );
    if (result.response?.size > 0) {
      // Do not use response.success because 204 is "No Content" but is a successful call.
      FileSaver.saveAs(result.response, "Comparison-Snapshot.pdf");
    }
    return result;
  };

export const submitSheetForAdminApproval = ({ sheetId }) =>
  apiPut(
    ActionTypes.SubmitSheetForAdminApproval,
    `sheets/${sheetId}/submit/dealer-admin-approval`,
  );

export const saveAndDownloadSheet =
  ({
    sheetDownloadFunction,
    sheetDownloadParams,
    sheetId,
    loadRewrite,
    loadAdmin,
    sheetCanBeUpdated,
    onSaved,
  }) =>
  async (dispatch) => {
    const download = async () =>
      await dispatch(
        sheetDownloadFunction({
          ...sheetDownloadParams,
          sheetId: sheetId,
          loadRewrite: loadRewrite,
          loadAdmin: loadAdmin,
        }),
      );

    if (sheetCanBeUpdated) {
      const response = await dispatch(saveDetails(sheetId, true));
      if (response.success) {
        if (onSaved) onSaved();
        return await download();
      }
      return response;
    }
    return await download();
  };

export const loadSheetServices =
  (sheetId, includeMandatory) => async (dispatch) => {
    const servicesParams = { sheetId, search: "", includeMandatory };
    await dispatch(lookupReset(SheetsLookupNames[SheetsActionTypes.Services]));
    await dispatch(
      lookupRequest(
        SheetsLookupNames[SheetsActionTypes.Services],
        servicesParams,
      ),
    );
  };

export const getLFPrinterConfigLookup = (sheetId) =>
  lookupMortar.largeFormatPrinterConfigLookup({
    queryParams: { sheetId, search: "" },
  });

export const getLFInkInclusiveLookup = (printerId, sheetId) =>
  lookupMortar.largeFormatInkInclusiveLookup({
    queryParams: { printerId, sheetId, search: "" },
  });

export const getLFPrinterInclusiveLookup = (paperWidth, sheetId) =>
  lookupMortar.largeFormatPaperInclusiveLookup({
    queryParams: { paperWidth, sheetId, search: "", dealerOfficeId: null },
  });

export const getLFRollMediaNamesLookup = (paperWidthId, sheetId) =>
  lookupMortar.rollMediaNameLookup({
    queryParams: { paperWidthId, sheetId, search: "" },
  });

export const revertPaperInclusive =
  (paperInclusive) => async (dispatch, getState) => {
    await dispatch({
      type: ActionTypes.RevertPaperInclusive,
      paperInclusive,
    });

    const state = getState();
    getSheetDetailsToPost(state, false);

    await dispatch(calculateSheet(false));
  };

export const fillLargeFormatCreateModal =
  (largeFormatEditIndex, sheetId) => async (dispatch, getState) => {
    const lfModal = getSheetLargeFormatScheduleState(getState())[
      largeFormatEditIndex
    ];

    const printerId = lfModal.printerId;
    const inkInclusiveId = lfModal.inkInclusiveId;
    const compInkInclusiveId = lfModal.compPrinterInkUsageId;

    const largeFormatPrinterConfig = await dispatch(
      getLFPrinterConfigLookup(sheetId),
    );
    const printer =
      largeFormatPrinterConfig.response.filter((x) => x.id == printerId)[0] ||
      [];
    const paperWidth = process.env.USE_IMPERIAL_UNITS
      ? printer.paperWidthInches
      : printer.paperWidthmm;

    const largeFormatInkInclusive = await dispatch(
      getLFInkInclusiveLookup(printerId, sheetId),
    );
    const ink =
      largeFormatInkInclusive.response.filter(
        (x) => x.id == inkInclusiveId,
      )[0] || [];
    const compInk =
      largeFormatInkInclusive.response.filter(
        (x) => x.id == compInkInclusiveId,
      )[0] || [];

    const largeFormatPrinterInclusive = await dispatch(
      getLFPrinterInclusiveLookup(paperWidth, sheetId),
    );

    const largeFormatRollMediaNames = await dispatch(
      getLFRollMediaNamesLookup(null, sheetId),
    );

    const mediaItems = lfModal.paperMeters.map((pm) => {
      const rollMedia = largeFormatRollMediaNames.response.find(
        (x) => x.id == pm.paperInclusiveId,
      );
      const width = largeFormatPrinterInclusive.response.find(
        (x) => x.id == pm.paperWidthId,
      );

      return {
        width,
        rollMedia,
        paperWidth,
      };
    });

    await dispatch({
      type: ActionTypes.FillLargeFormatCreateModal,
      lfModal,
      largeFormatEditIndex,
      printer,
      ink,
      compInk,
      mediaItems,
    });
    if (!getSheetState(getState()).calculationPaused) {
      await dispatch(calculateSheet(true));
      await dispatch(calculateComparisonData());
    }
  };

export const verifyUnlockView = (request) => async (dispatch) =>
  await dispatch(
    apiPost(ActionTypes.VerifyUnlockView, "sheets/verify-unlock-view", request),
  );

export const canDeleteScheduleItem = ({
  sheetId,
  isLargeFormat,
  scheduleItemId,
}) =>
  mortar.canDeleteScheduleItem({
    queryParams: {
      isLargeFormat,
      scheduleItemId,
    },
    routeParams: {
      sheetId,
    },
  });

export const getAudits =
  ({ pagination }, { sheetId }) =>
  async (dispatch) =>
    await dispatch(
      mortar.getSheetAudits({
        queryParams: {
          ...pagination,
          sheetId,
        },
        routeParams: {
          sheetId,
        },
      }),
    );

export const getSheetDuplicateName = ({ sheetId, duplicateToNewMaster }) => {
  return apiGet(
    ActionTypes.GetDuplicateSheetName,
    `sheets/${sheetId}/duplicate-name`,
    { duplicateToNewMaster },
  );
};

export const toggleSheetReadOnly = (_, sheetId) =>
  mortar.toggleSheetReadOnly({
    routeParams: {
      sheetId,
    },
  });
