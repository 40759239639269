import React from "react";
import { connect, useSelector } from "react-redux";
import { Grid } from "semantic-ui-react";
import { SidePanel, Form } from "@redriver/cinnamon";
import { FilterButton } from "features/../../../shared/components/buttons";
import { useTranslation } from "react-i18next";
import { UserTypeAhead } from "features/Settings";
import { UserArea, UserType } from "../../../../../shared/constants/enums";
import { withCurrentUser } from "features/../../../shared/components/auth";
import { getAgreementsState } from "../selectors";

import { SheetStateDropdownLookup } from "features/Sheets";

export const FieldNames = {
  Search: "search",
  SearchType: "searchType",
  SheetStatesIps: "sheetStatesIps",
  SheetStatesPrincipal: "sheetStatesPrincipal",
  SheetOwnerId: "sheetOwnerId",
  SheetOwner: "sheetOwner",
  SheetOwnerName: "sheetOwnerName",
  IsCrystallised: "isCrystallised",
  IsUncrystallised: "isUncrystallised",
  IsArchived: "isArchived",
  IsExpired: "isExpired",
  NotArchived: "notArchived",
};

export const FieldNamesToTranslationKeys = {
  [FieldNames.SheetStatesIps]: "States",
  [FieldNames.SheetStatesPrincipal]: "DocusignStates",
  [FieldNames.SheetOwnerId]: "SheetOwner",
  [FieldNames.SheetOwnerName]: "SheetOwner",
  [FieldNames.IsCrystallised]: "IsCrystallised",
  [FieldNames.IsUncrystallised]: "IsUncrystallised",
  [FieldNames.IsArchived]: "IsArchived",
  [FieldNames.NotArchived]: "NotArchived",
  [FieldNames.IsExpired]: "IsExpired",
};

const AgreementSheetFilters = ({ formProps, currentUser }) => {
  const { t } = useTranslation("agreements", { keyPrefix: "AgreementFilters" });
  const { filters } = useSelector(getAgreementsState);

  const dealerId = filters?.dealer?.id;
  let userIsDealer = currentUser.userType == UserType.Dealer;
  const isAccountManager =
    currentUser.userArea === UserArea.AccountManager && userIsDealer;

  return (
    <SidePanel
      closeIcon
      trigger={<FilterButton />}
      className="sheets-filter-side-panel"
    >
      <div className="ui form highlighted-inputs">
        <Form {...formProps}>
          <div className="ui header filters-title">
            <div className="title-text">{t("Title")}</div>
          </div>
          <Grid>
            <Grid.Column>
              <SheetStateDropdownLookup
                field={
                  process.env.TRADE_AS_IPS
                    ? FieldNames.SheetStatesIps
                    : FieldNames.SheetStatesPrincipal
                }
                label={t(
                  FieldNamesToTranslationKeys[
                    process.env.TRADE_AS_IPS
                      ? FieldNames.SheetStatesIps
                      : FieldNames.SheetStatesPrincipal
                  ],
                )}
                clearable
                fluid
                multiple
              />
              {!isAccountManager && (
                <UserTypeAhead
                  field={FieldNames.SheetOwnerId}
                  label={t(
                    FieldNamesToTranslationKeys[FieldNames.SheetOwnerId],
                  )}
                  lookupParams={{
                    dealerId,
                  }}
                  placeholder={t("SheetOwner_Placeholder")}
                  clearable
                  fluid
                  textField="sheetOwnerName"
                  optionField="sheetOwner"
                  unknownValueOptions={[formProps.value.sheetOwner]}
                />
              )}

              <br />
              <Form.Checkbox
                field={FieldNames.IsCrystallised}
                label={t(
                  FieldNamesToTranslationKeys[FieldNames.IsCrystallised],
                )}
              />
              <Form.Checkbox
                field={FieldNames.IsUncrystallised}
                label={t(
                  FieldNamesToTranslationKeys[FieldNames.IsUncrystallised],
                )}
              />
              <Form.Checkbox
                field={FieldNames.IsArchived}
                label={t(FieldNamesToTranslationKeys[FieldNames.IsArchived])}
              />
              <Form.Checkbox
                field={FieldNames.NotArchived}
                label={t(FieldNamesToTranslationKeys[FieldNames.NotArchived])}
              />
              <Form.Checkbox
                field={FieldNames.IsExpired}
                label={t(FieldNamesToTranslationKeys[FieldNames.IsExpired])}
              />
            </Grid.Column>
          </Grid>
        </Form>
      </div>
    </SidePanel>
  );
};

const mapStateToProps = (state) => {
  const { filters } = getAgreementsState(state);
  return {
    dealerId: filters.dealer?.id,
  };
};

export default connect(
  mapStateToProps,
  null,
)(withCurrentUser(AgreementSheetFilters));
