import React from "react";
import { useTranslation } from "react-i18next";
import { uncrystalliseSheet } from "../actions";
import { ModalBuilder, Table } from "@redriver/cinnamon";
import { withRouter } from "react-router-dom";
import {
  withCurrentUser,
  PermissionCheck,
} from "features/../../../shared/components/auth";

import { Targets, Actions } from "constants/permissions";
import { TranslatedModal } from "features/../../../shared/components/modals";

const UncrystalliseSheet = ({ sheet, sheetActionRequired, onSubmitted }) => {
  const { t } = useTranslation("agreements");
  const {
    sheetId,
    sheetName,
    availableActionsIps,
    availableActionsPrincipal,
    submittedForPhysicalDocumentFlow,
    isCrystallised,
  } = sheet;

  if (
    !isCrystallised ||
    (process.env.TRADE_AS_IPS && !availableActionsIps[sheetActionRequired]) ||
    (!process.env.TRADE_AS_IPS &&
      !availableActionsPrincipal[sheetActionRequired])
  )
    return null;

  return (
    <PermissionCheck
      target={Targets.Sheet}
      action={Actions.CrystalliseAndUncrystalliseSheets}
    >
      <ModalBuilder
        withForm
        submitAction={uncrystalliseSheet}
        submitParams={sheetId}
        onSubmitted={onSubmitted}
        renderTrigger={(trigger) => (
          <Table.MenuItem onClick={trigger}>
            {t("SheetActions.UncrystalliseSheet.TriggerText")}
          </Table.MenuItem>
        )}
        renderModal={(modalProps) => (
          <TranslatedModal.Confirmation
            {...modalProps}
            header={t("SheetActions.UncrystalliseSheet.HeaderText")}
            size="small"
          >
            {t("SheetActions.UncrystalliseSheet.ConfirmMessage", {
              sheetName,
            })}
          </TranslatedModal.Confirmation>
        )}
      />
    </PermissionCheck>
  );
};

export default withCurrentUser(withRouter(UncrystalliseSheet));
