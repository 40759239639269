import React from "react";
import { Icon } from "semantic-ui-react";
import { useTranslation, Trans } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { Form, FormBuilder, ActionButton } from "@redriver/cinnamon";
import { PermissionCheck } from "features/../../../shared/components/auth";
import { Actions, Targets } from "constants/permissions";
import { BoilerPlateTandCsDropdownLookup } from "features/../../../shared/features/Settings";
import { getAgreementsState } from "features/Agreements/selectors";
import {
  getMasterAgreement,
  getMasterAgreements,
  updateMasterAgreement,
  removeExpandedAgreementOrSchedule,
  addExpandedAgreementOrSchedule,
} from "../actions";
import DeleteMasterAgreement from "./DeleteMasterAgreement";

const AgreementRowForm = ({
  agreement,
  getExpandIconName,
  isAdminUser,
  isInternalUser,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation("agreements");

  const {
    expandedAgreementsAndSchedules,
    companyFilters,
    filters,
    pagination,
  } = useSelector(getAgreementsState);

  const onToggleAgreementExpanded = (id) => {
    if (expandedAgreementsAndSchedules?.includes(id)) {
      dispatch(removeExpandedAgreementOrSchedule(id));
    } else {
      dispatch(addExpandedAgreementOrSchedule(id));
    }
  };

  const onAgreementUpdated = () => {
    dispatch(getMasterAgreement(agreement.agreementId, filters));
  };

  const onAgreementDeleted = () => {
    dispatch(getMasterAgreements({ filters, pagination }, companyFilters));
  };

  return (
    <FormBuilder
      initialData={agreement}
      submitAction={updateMasterAgreement}
      submitParams={{ id: agreement.agreementId }}
      onSubmitted={onAgreementUpdated}
      renderError={false}
      renderForm={(formProps, state, formEvents) => (
        <Form {...formProps} className="manage-agreement-form">
          <div className="full-width">
            <div className="float-left name flex">
              <div
                onClick={() => onToggleAgreementExpanded(agreement.agreementId)}
                className="spaced expand-collapse"
              >
                <Icon
                  className="master-agreement-icon"
                  name={getExpandIconName(agreement.agreementId)}
                />
                {t("AgreementRow.ProjectName_Label")}
              </div>
              <Form.Input
                className="spaced no-padding-left project-name"
                field="projectName"
                placeholder={t("AgreementRow.ProjectName_Placeholder")}
                loading={state.submitting}
                required
                disabled={!isAdminUser}
              />
            </div>

            {!isInternalUser || process.env.TRADE_AS_IPS ? (
              <div className="float-left spaced not-bold">
                <Trans
                  t={t}
                  values={{
                    ref: agreement.agreementReference ?? t("NotLive"),
                  }}
                  components={{
                    span: <span />,
                  }}
                >
                  AgreementRow.TitleWithRef
                </Trans>
              </div>
            ) : (
              <div className="float-left">
                <div className="float-left spaced not-bold">
                  {t("AgreementRow.Title")}
                </div>
                <div className="float-left spaced no-padding-left">
                  <Form.Input field="agreementReference" inline fluid />
                </div>
              </div>
            )}

            <div className="float-left">
              <div className="float-left spaced not-bold">
                {t("AgreementRow.Master_Agreement_Date")}
              </div>
              <div className="float-left spaced no-padding-left">
                <Form.Date
                  local
                  field="startDate"
                  placeholder=""
                  inline
                  disabled={!isAdminUser}
                />
              </div>
            </div>
            {process.env.TRADE_AS_IPS && (
              <div className="float-left">
                <div className="float-left spaced not-bold">
                  {t("AgreementRow.TCs_Label")}
                </div>
                <div className="agreementTandC float-left spaced no-padding-left">
                  <BoilerPlateTandCsDropdownLookup
                    field="boilerPlateTandCsId"
                    dealerId={companyFilters.dealer.id}
                    clearable
                    fluid
                    textField="boilerPlateTandCs"
                    readOnly={
                      agreement.boilerPlateTandCsDisabled || !isAdminUser
                    }
                    renderReadOnly={() => agreement.boilerPlateTandCs}
                  />
                </div>
              </div>
            )}

            <div className="float-left">
              <div className="flex spaced">
                <PermissionCheck
                  target={Targets.Agreements}
                  action={Actions.Edit}
                >
                  <ActionButton
                    type="save"
                    floated="right"
                    onClick={() => formEvents.onSubmit()}
                    loading={state.submitting}
                    className={
                      "action-button" + (state.dirty ? " " : " hidden")
                    }
                  />
                  <ActionButton
                    type="cancel"
                    floated="right"
                    onClick={() => formEvents.onReset()}
                    loading={state.submitting}
                    className={
                      "action-button" + (state.dirty ? " " : " hidden")
                    }
                  />
                </PermissionCheck>
              </div>
            </div>
            <DeleteMasterAgreement
              agreementId={agreement.agreementId}
              AgreementReference={agreement.agreementReference}
              onSubmitted={onAgreementDeleted}
            />
          </div>
        </Form>
      )}
    />
  );
};

export default AgreementRowForm;
