import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Format, Table } from "@redriver/cinnamon";
import { SheetActions } from "features/Agreements/AgreementsList/SheetComponents";
import { Link } from "react-router-dom";
import { SheetRoutes } from "constants/routes";
import { PermissionCheck } from "features/../../../shared/components/auth";
import { Actions, Targets } from "constants/permissions";
import {
  SheetStateIps,
  SheetStatePrincipal,
} from "features/../../../shared/constants/enums";
import { getAgreementsState } from "../../selectors";

const AgreementListScheduleItem = ({ agreement, schedule }) => {
  const { t } = useTranslation("agreements");
  const { t: tState } = useTranslation("enums", {
    keyPrefix: process.env.TRADE_AS_IPS
      ? "SheetStateIps"
      : "SheetStatePrincipal",
  });

  const { t: tPhysicalDocumentState } = useTranslation("enums", {
    keyPrefix: "PhysicalDocumentSheetStatePrincipal",
  });

  const { scheduleSheets } = useSelector(getAgreementsState);

  const getRowClassName = (sheet) => {
    return sheet.isDeclined
      ? "red-highlight"
      : sheet.isRetired
      ? "grey-highlight"
      : sheet.isLive
      ? "blue-highlight"
      : sheet.isCrystallised
      ? "purple-highlight"
      : sheet.isReadOnly
      ? "green-highlight"
      : "";
  };

  return (
    <React.Fragment>
      <Table
        className="schedules-list sheets-list"
        data={scheduleSheets[schedule.scheduleId]?.response?.map((s) => ({
          ...s,
          className: getRowClassName(s),
        }))}
        dataKey="id"
        emptyMessage={t("ScheduleRow.Sheets.No_sheets_found")}
        selectable
      >
        <Table.Column
          field="sheetName"
          className="sheet-name"
          title={t("ScheduleRow.Sheets.Headers.Sheet_Name")}
          width="22%"
          render={(x) => (
            <Link to={SheetRoutes.Sheet(x.sheetId)}>
              {x.sheetName.startsWith(t("DraftPrefix")) ? (
                <span className="red">{x.sheetName}</span>
              ) : (
                <span>{x.sheetName}</span>
              )}
            </Link>
          )}
        />
        <Table.Column
          field="sheetType"
          title={t("ScheduleRow.Sheets.Headers.Sheet_Type")}
          width="4%"
          className="rewrite center-aligned"
          render={(x) =>
            x.sheetType ? <span>{x.sheetType}</span> : <span>-</span>
          }
        />
        <Table.Column
          field="startDate"
          title={t("ScheduleRow.Sheets.Headers.Applicable_From")}
          width="6%"
          className="center-aligned"
          render={(x) => (
            <Format.Date value={x.startDate} format="DD MMM YYYY" />
          )}
        />
        <PermissionCheck action={Actions.ViewExpired} target={Targets.Sheet}>
          <Table.Column
            field="expiryDate"
            title={t("ScheduleRow.Sheets.Headers.Expiry_Date")}
            width="6%"
            className="center-aligned"
            render={(x) => (
              <Format.Date value={x.expiryDate} format="DD MMM YYYY" />
            )}
          />
        </PermissionCheck>
        {!process.env.TRADE_AS_IPS && (
          <Table.Column
            field=""
            title={t("ScheduleRow.Sheets.Headers.Current_Schedule")}
            width="8%"
            className="center-aligned"
            render={(x) => x.currentScheduleReference}
          />
        )}
        {!process.env.TRADE_AS_IPS && (
          <Table.Column
            field=""
            title={t("ScheduleRow.Sheets.Headers.Revision_Number")}
            width="8%"
            className="center-aligned"
            render={(x) => x.revisionNumber}
          />
        )}
        <Table.Column
          title={t("ScheduleRow.Sheets.Headers.Replacing_Schedule")}
          width="8%"
          className="center-aligned"
          field="replacingScheduleReference"
        />
        <Table.Column
          field="lastModifiedDate"
          title={t("ScheduleRow.Sheets.Headers.Last_Modified")}
          width="6%"
          className="center-aligned"
          render={(x) => (
            <div className="tooltip">
              <Format.Date value={x.lastModifiedDate} format="DD MMM YYYY" />
              <span className="tooltiptext">
                <Format.DateTime
                  value={x.lastModifiedDate}
                  format="hh:mmA DD MMM YYYY"
                />
                <br />
                {x.modifiedBy}
              </span>
            </div>
          )}
        />
        <Table.Column
          render={(x) => x.createdBy.name}
          title={t("ScheduleRow.Sheets.Headers.Created_by")}
        />
        <Table.Column
          field="crystallisedUtc"
          title={t("ScheduleRow.Sheets.Headers.Crystallised_Date")}
          className="center-aligned"
          render={(x) => (
            <Format.Date value={x.crystallisedUtc} format="DD MMM YYYY" />
          )}
          width="6%"
        />
        <Table.Column
          field="archivedUtc"
          title={t("ScheduleRow.Sheets.Headers.Archived_Date")}
          className="center-aligned"
          render={(x) => (
            <Format.Date value={x.archivedUtc} format="DD MMM YYYY" />
          )}
          width="6%"
        />
        <Table.Column
          field=""
          title={t("ScheduleRow.Sheets.Headers.Admin_Comment")}
          width="15%"
          render={(x) => x.adminComment}
        />
        <Table.Column
          title={t("ScheduleRow.Sheets.Headers.Status")}
          width="9%"
          className="center-aligned"
          render={(x) =>
            x.isReadOnly
              ? t("ScheduleRow.Sheets.Headers.ReadOnly")
              : (!process.env.TRADE_AS_IPS
                  ? x.submittedForPhysicalDocumentFlow
                    ? tPhysicalDocumentState(
                        x.physicalDocumentSheetStatePrincipal,
                      )
                    : tState(x.statePrincipal)
                  : tState(x.stateIps)) || t("ScheduleRow.Sheets.Headers.Unset")
          }
        />
        <Table.Column
          title={t("ScheduleRow.Sheets.Headers.Owner")}
          render={(x) =>
            x.ownedBy?.name || t("ScheduleRow.Sheets.Headers.Unset")
          }
        ></Table.Column>
        <Table.Column
          hoverable
          width="1%"
          align="right"
          render={(x) => (
            <SheetActions agreement={agreement} schedule={schedule} sheet={x} />
          )}
        />
      </Table>
    </React.Fragment>
  );
};

export default AgreementListScheduleItem;
