import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  ActionBar,
  Form,
  ModalBuilder,
  Table,
  useFormField,
  Wizard,
  PageLoader,
} from "@redriver/cinnamon";

import { DocusignAdminRecipientsLookup } from "features/Sheets/lookups";
import { sendForCustomerApproval } from "./actions";
import { Button, Icon } from "semantic-ui-react";
import { TranslatedModal } from "features/../../../shared/components/modals";
import { IconButton } from "features/../../../shared/components/buttons";
import Add from "features/../../../shared/assets/black-add.svg";
import UserArray from "./UserArray";
import ClientSignatory from "./ClientSignatory";
import { DirectDebitSignatory } from "features/../../../shared/constants/enums";

const ConfirmationStep = ({
  formData,
  scheduleReference,
  agreementReference,
  t,
  sheetName,
  dealerOfficeId,
}) => {
  const formatPhoneNumber = (data) => {
    const countryCode = data?.phoneCountryCode
      ? `(${data?.phoneCountryCode})`
      : "";
    const phoneNumber = data?.phoneNumber ? `${data?.phoneNumber}` : "";

    return countryCode + phoneNumber;
  };

  const renderCustomerSignatoryRow = (field, fieldName) => {
    return (
      <tr>
        <td>
          {field[`isNew_${fieldName}`]
            ? field.fullName
            : field.authorisedSignatory?.name?.split(" - "[0])}
        </td>
        <td>
          {field[`isNew_${fieldName}`]
            ? field.position
            : field.authorisedSignatory?.position}
        </td>
        <td>
          {field[`isNew_${fieldName}`]
            ? field.email
            : field.authorisedSignatory?.email}
        </td>
        <td>
          {field[`isNew_${fieldName}`]
            ? formatPhoneNumber(field)
            : formatPhoneNumber(field.authorisedSignatory)}
        </td>
      </tr>
    );
  };

  return (
    <DocusignAdminRecipientsLookup
      dealerOfficeId={dealerOfficeId}
      render={({ loading, response }) => {
        if (loading) {
          return <PageLoader />;
        }

        return (
          <React.Fragment>
            <p>{t("SheetActions.SendForApproval.ConfirmMessage")}</p>
            <p>{t("SheetActions.SendForApproval.Confirm")}</p>
            <p>
              {t("SheetActions.SendForApproval.SheetLabel")}
              <strong>{sheetName}</strong>
            </p>
            <p>
              {t("SheetActions.SendForApproval.ScheduleLabel")}
              <strong>{scheduleReference ?? t("NotLive")}</strong>
            </p>
            <p>
              {t("SheetActions.SendForApproval.AgreementLabel")}
              <strong>{agreementReference ?? t("NotLive")}</strong>
            </p>
            <h3>{t("SheetActions.SendForApproval.RecipientsTable.Title")}</h3>
            <table className="recipients">
              <thead>
                <tr>
                  <th>
                    {t("SheetActions.SendForApproval.RecipientsTable.FullName")}
                  </th>
                  <th>
                    {t("SheetActions.SendForApproval.RecipientsTable.Position")}
                  </th>
                  <th>
                    {t("SheetActions.SendForApproval.RecipientsTable.Email")}
                  </th>
                  <th>
                    {t(
                      "SheetActions.SendForApproval.RecipientsTable.PhoneNumber",
                    )}
                  </th>
                </tr>
              </thead>
              <tbody>
                {formData.usersToViewBeforeDocumentSigned?.map((u, i) => (
                  <tr key={`tr_${i}`}>
                    <td>{u.name}</td>
                    <td>
                      {t(
                        "SheetActions.SendForApproval.RecipientsTable.ViewUserPosition",
                      )}
                    </td>
                    <td>{u.email}</td>
                    <td>{formatPhoneNumber(u)}</td>
                  </tr>
                ))}

                {renderCustomerSignatoryRow(
                  formData.customerSignatory1,
                  "customerSignatory1",
                )}
                {formData.customerSignatory2?.authorisedSignatoryId &&
                  renderCustomerSignatoryRow(
                    formData.customerSignatory2,
                    "customerSignatory2",
                  )}

                {formData.directDebitSignatories?.map((u, i) => (
                  <tr key={`tr_${i}`}>
                    <td>{u.name}</td>
                    <td>
                      {t(
                        "SheetActions.SendForApproval.RecipientsTable.DirectDebitSignatoryPosition",
                      )}
                    </td>
                    <td>{u.email}</td>
                    <td>{formatPhoneNumber(u)}</td>
                  </tr>
                ))}
                {response?.map((u, i) => (
                  <tr key={`tr_${i}`}>
                    <td>{u.name}</td>
                    <td>{u.position}</td>
                    <td>{u.email}</td>
                    <td>{formatPhoneNumber(u)}</td>
                  </tr>
                ))}
                {formData.usersToReceiveCopyOfSignedDocument?.map((u, i) => (
                  <tr key={`tr_${i}`}>
                    <td>{u.name}</td>
                    <td>
                      {t(
                        "SheetActions.SendForApproval.RecipientsTable.CopyUserPosition",
                      )}
                    </td>
                    <td>{u.email}</td>
                    <td>{formatPhoneNumber(u)}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </React.Fragment>
        );
      }}
    />
  );
};

const SendForApproval = ({
  sheet,
  sheetData,
  onSubmitted,
  scheduleReference,
  agreementReference,
  sheetActionRequired,
  generateDocument,
  boilerPlateTandCsId,
  triggerDisabled,
}) => {
  const { t } = useTranslation("agreements");
  const { t: tSheet } = useTranslation("sheetTranslation");

  const [directDebitSignatory] = useFormField("directDebitSignatory");
  const [totalDirectDebitSignatories, setTotalDirectDebitSignatories] =
    useState(0);
  const [includeSecondClientSignatory, setIncludeSecondClientSignatory] =
    useState(false);

  const sheetId = sheet ? sheet.sheetId : sheetData.id;

  const availableActions = process.env.TRADE_AS_IPS
    ? sheet
      ? sheet.availableActionsIps
      : sheetData.availableActionsIps
    : sheet
    ? sheet.availableActionsPrincipal
    : sheetData.availableActionsPrincipal;

  if (!availableActions[sheetActionRequired]) return null;

  const DirectDebitMandateOptions = [
    {
      value: DirectDebitSignatory.SameAsAuthorisedSignatory,
      text: t("SheetActions.SendForApproval.SameAsAuthorisedSignatory"),
    },
    {
      value: DirectDebitSignatory.NewSignatory,
      text: t("SheetActions.SendForApproval.NewSignatory"),
    },
    {
      value: DirectDebitSignatory.NotRequired,
      text: t("SheetActions.SendForApproval.NotRequired"),
    },
  ];

  const optionsStep = () => (
    <React.Fragment>
      <ClientSignatory
        field={"customerSignatory1"}
        signatoryDropdownLabel={t(
          "SheetActions.SendForApproval.CustomerSignatory1_Label",
        )}
        sheetId={sheetId}
      />

      <Button
        onClick={() => {
          setIncludeSecondClientSignatory(!includeSecondClientSignatory);
        }}
        content={t(
          `SheetActions.SendForApproval.${
            includeSecondClientSignatory
              ? "RemoveSecondSignatory"
              : "AddSecondSignatory"
          }`,
        )}
        className="wizard-title-button not-bold"
        style={{ marginBottom: "0.5em" }}
      />

      <Form.If condition={() => includeSecondClientSignatory}>
        <ClientSignatory
          field={"customerSignatory2"}
          signatoryDropdownLabel={t(
            "SheetActions.SendForApproval.CustomerSignatory2_Label",
          )}
          sheetId={sheetId}
        />
      </Form.If>

      {!process.env.TRADE_AS_IPS && (
        <React.Fragment>
          <Form.RadioGroup
            field="directDebitSignatory"
            options={DirectDebitMandateOptions}
            label="Direct Debit Signatory"
          />
          <Form.If
            condition={({ fields }) =>
              fields.directDebitSignatory == DirectDebitSignatory.NewSignatory
            }
          >
            <Form.ArrayAdder
              field="directDebitSignatories"
              arrayKey="id"
              className="wizard-title-button ip-button not-bold"
              as={IconButton}
              icon={Add}
              content={t("SheetActions.SendForApproval.DirectDebitSignatory")}
              populate={() => {
                setTotalDirectDebitSignatories(totalDirectDebitSignatories + 1);
                return {
                  id: Date.now(),
                  name: "",
                  email: "",
                };
              }}
              disabled={totalDirectDebitSignatories >= 2}
            />

            <UserArray
              field="directDebitSignatories"
              onUserRemoved={() =>
                setTotalDirectDebitSignatories(totalDirectDebitSignatories - 1)
              }
            />
          </Form.If>
          <br />
        </React.Fragment>
      )}

      <Form.ArrayAdder
        field="usersToViewBeforeDocumentSigned"
        arrayKey="id"
        className="wizard-title-button ip-button not-bold"
        as={IconButton}
        icon={Add}
        content={t("SheetActions.SendForApproval.ViewBeforeSigned")}
        populate={() => ({
          id: Date.now(),
          name: "",
          email: "",
        })}
      />

      <UserArray field="usersToViewBeforeDocumentSigned" />

      <Form.ArrayAdder
        field="usersToReceiveCopyOfSignedDocument"
        arrayKey="id"
        className="wizard-title-button ip-button not-bold"
        as={IconButton}
        icon={Add}
        content={t("SheetActions.SendForApproval.ReceiveCopyOfSignedDocument")}
        populate={() => ({
          id: Date.now(),
          name: "",
          email: "",
        })}
      />

      <UserArray field="usersToReceiveCopyOfSignedDocument" />
    </React.Fragment>
  );

  const disableNext =
    directDebitSignatory == DirectDebitSignatory.NewSignatory &&
    totalDirectDebitSignatories == 0;
  return (
    <ModalBuilder
      withForm
      initialData={{
        customerSignatory1: { isNew_customerSignatory1: false },
        customerSignatory2: { isNew_customerSignatory2: false },
      }}
      submitAction={sendForCustomerApproval}
      submitParams={{
        sheetId,
        includeSecondClientSignatory,
      }}
      onCancelled={() => {
        setTotalDirectDebitSignatories(0);
      }}
      onSubmitted={onSubmitted}
      renderTrigger={(trigger) =>
        generateDocument ? (
          <Button
            className="generate-btn"
            primary
            onClick={triggerDisabled ? null : trigger}
            disabled={
              triggerDisabled ||
              (!boilerPlateTandCsId && process.env.TRADE_AS_IPS)
            }
          >
            <Icon name="check" />
            {tSheet(
              `SendForApproval.${
                process.env.TRADE_AS_IPS
                  ? "TriggerText_Ips"
                  : "TriggerText_Principal"
              }`,
            )}
          </Button>
        ) : (
          <Table.MenuItem
            onClick={trigger}
            disabled={
              triggerDisabled ||
              (!boilerPlateTandCsId && process.env.TRADE_AS_IPS)
            }
          >
            {t("SheetActions.SendForApproval.ActionLabel")}
          </Table.MenuItem>
        )
      }
      renderModal={(modalProps, formProps, state, events) => (
        <TranslatedModal.Edit
          {...modalProps}
          header={t("SheetActions.SendForApproval.ModalHeader")}
          className="custom-actions customer-approval"
          size="large"
          onClick={(event) => event && event.stopPropagation()}
        >
          <Form
            {...formProps}
            onClick={(event) => event && event.stopPropagation()}
          >
            <Wizard
              steps={[
                { key: "optionsStep" },
                { key: "confirmationStep", canSubmit: true },
              ]}
            >
              <Wizard.Step step="optionsStep" component={optionsStep} />
              <Wizard.Step
                step="confirmationStep"
                render={() => (
                  <ConfirmationStep
                    formData={formProps.value}
                    agreementReference={agreementReference}
                    scheduleReference={scheduleReference}
                    sheetName={sheet ? sheet.sheetName : sheetData.name}
                    dealerOfficeId={
                      sheet ? sheet.dealerOfficeId : sheetData.dealerOfficeId
                    }
                    t={t}
                  />
                )}
              />
              <Wizard.Nav
                component={({
                  activeStep,
                  hasNextStep,
                  hasPreviousStep,
                  onGoToNextStep,
                  onGoToPreviousStep,
                }) => (
                  <div className="custom-action-footer">
                    <ActionBar>
                      <ActionBar.Item>
                        <Button
                          className="cancel"
                          content={t("Cancel")}
                          disabled={modalProps.submitting}
                          onClick={modalProps.onCancel}
                        />
                      </ActionBar.Item>
                      <ActionBar.Space />
                      {hasPreviousStep && (
                        <ActionBar.Item fluid align="right">
                          <Button
                            className="previous"
                            content={t("Back")}
                            disabled={modalProps.submitting}
                            onClick={onGoToPreviousStep}
                          />
                        </ActionBar.Item>
                      )}
                      {hasNextStep && (
                        <ActionBar.Item
                          fluid={!hasPreviousStep} //prevent random whitespace
                          align="right"
                        >
                          <Button
                            primary
                            className="next"
                            content={t("Next")}
                            loading={state.submitting}
                            disabled={state.submitting || disableNext}
                            onClick={() => {
                              let { formValid } = state;
                              if (formValid) {
                                if (
                                  formProps.value.directDebitSignatory !==
                                  DirectDebitSignatory.NewSignatory
                                ) {
                                  formProps.value.directDebitSignatories = null;
                                  setTotalDirectDebitSignatories(0);
                                }
                                if (!includeSecondClientSignatory) {
                                  formProps.value.customerSignatory2.authorisedSignatory =
                                    null;
                                  formProps.value.customerSignatory2.authorisedSignatoryId =
                                    null;
                                }
                                onGoToNextStep();
                                return;
                              }
                              events.onSubmit();
                            }}
                          />
                        </ActionBar.Item>
                      )}
                      {activeStep.canSubmit && (
                        <ActionBar.Item>
                          <Button
                            className="positive submit"
                            content={t("Confirm")}
                            loading={modalProps.submitting}
                            disabled={modalProps.submitting}
                            onClick={() => {
                              modalProps.submitting = true;
                              events.onSubmit();
                            }}
                          />
                        </ActionBar.Item>
                      )}
                    </ActionBar>
                  </div>
                )}
              />
            </Wizard>
          </Form>
        </TranslatedModal.Edit>
      )}
    />
  );
};

export default SendForApproval;
