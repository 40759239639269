import React from "react";
import { useTranslation } from "react-i18next";

const SignatoryInfo = ({ data }) => {
  const { t } = useTranslation("agreements");
  if (data == null) return;
  return (
    <div>
      <div className="flex">
        <div style={{ width: "40%" }}>
          {t("SheetActions.ViewDocumentRecipients.Name")}:
        </div>
        <div className="bold">{data.name}</div>
      </div>
      <div className="flex">
        <div style={{ width: "40%" }}>
          {t("SheetActions.ViewDocumentRecipients.Email")}:
        </div>
        <div className="bold">{data.email}</div>
      </div>
      <div className="flex">
        <div style={{ width: "40%" }}>
          {t("SheetActions.ViewDocumentRecipients.Position")}:
        </div>
        <div className="bold">{data.position}</div>
      </div>
      <div className="flex">
        <div style={{ width: "40%" }}>
          {t("SheetActions.ViewDocumentRecipients.PhoneNumber")}:
        </div>
        <div className="bold">{data.phoneNumber}</div>
      </div>
      <div className="flex">
        <div style={{ width: "40%" }}>
          {t("SheetActions.ViewDocumentRecipients.CountryCode")}:
        </div>
        <div className="bold">{data.phoneCountryCode}</div>
      </div>
    </div>
  );
};

export default SignatoryInfo;
