import React from "react";
import { useTranslation } from "react-i18next";
import { ProductMeteredServiceDropdown } from "./lookup";
import { EditFieldModal } from "components/forms";

const ApplicableItemArray = ({
  itemUnitList,
  currentlySelected,
  sheetId,
  applicableItemField,
  unitOptions,
  onModalDropdownSubmit,
}) => {
  const { t } = useTranslation("productSchedule");

  return (
    <div>
      <div className="product-schedule-app-item">
        <EditFieldModal
          onSubmit={onModalDropdownSubmit}
          header={t("Item")}
          fieldName={"scheduleItemId"}
          formChildrenProps={{
            defaultOpen: true,
          }}
        >
          <ProductMeteredServiceDropdown
            options={unitOptions}
            field="scheduleItemId"
            sheetId={sheetId}
            currentlySelected={currentlySelected}
            scheduleItemId={applicableItemField.scheduleItemId}
            itemUnitList={itemUnitList}
          />
        </EditFieldModal>
      </div>
    </div>
  );
};

export default ApplicableItemArray;
