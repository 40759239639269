import React from "react";
import { Form, ModalBuilder, Table } from "@redriver/cinnamon";
import { useTranslation } from "react-i18next";
import { TranslatedModal } from "../../../../../../shared/components/modals";
import { setCurrentSchedule } from "./actions";

const SetCurrentSchedule = ({ sheet, onSubmitted }) => {
  const { t } = useTranslation("agreements");
  const { sheetId, currentScheduleReference, revisionNumber } = sheet;

  return (
    <ModalBuilder
      withForm
      initialData={{ currentScheduleReference, revisionNumber }}
      submitParams={{ sheetId }}
      submitAction={setCurrentSchedule}
      onSubmitted={onSubmitted}
      renderTrigger={(onTrigger) => (
        <Table.MenuItem onClick={onTrigger}>
          {t("SheetActions.SetCurrentSchedule.TriggerText")}
        </Table.MenuItem>
      )}
      renderModal={(modalProps, formProps) => (
        <TranslatedModal.Edit
          {...modalProps}
          header={t("SheetActions.SetCurrentSchedule.ModalHeader")}
          submitText={t("SheetActions.SetCurrentSchedule.ModalSubmit")}
        >
          <Form {...formProps}>
            <Form.Input field="currentScheduleReference" maxLength={50} />
            <Form.Input
              field="revisionNumber"
              maxLength={50}
              label={t("SheetActions.SetCurrentSchedule.RevisionNumber")}
            />
          </Form>
        </TranslatedModal.Edit>
      )}
    />
  );
};

export default SetCurrentSchedule;
