import React from "react";
import { useTranslation } from "react-i18next";
import {
  FormBuilder,
  Form,
  ActionBar,
  ActionBarItem,
} from "@redriver/cinnamon";
import { UserTypeOptions } from "features/../../../shared/constants/enums";
import Search from "features/../../../shared/assets/purple-search.svg";
import { UserTypeAhead } from "features/Settings";
import { PermissionCheck } from "features/../../../shared/components/auth";
import { Targets, Actions } from "constants/permissions";

const NotificationFilters = ({ value, onChange }) => {
  const { t } = useTranslation("notifications", { keyPrefix: "filters" });
  return (
    <PermissionCheck target={Targets.Notifications} action={Actions.ViewOthers}>
      <FormBuilder
        value={value}
        onChange={onChange}
        renderForm={(formProps) => (
          <ActionBar>
            <ActionBar.Item fluid className="flex">
              <Form {...formProps}>
                <div className="recipient-dd-container">
                  <UserTypeAhead
                    className="recipient"
                    field="recipientId"
                    label={t("recipient_label")}
                    lookupParams={{}}
                    inline
                    clearable
                    fluid
                    textField="sheetOwnerName"
                    optionField="sheetOwner"
                    placeholder={t("recipient_placeholder")}
                  />
                </div>
              </Form>
            </ActionBar.Item>
          </ActionBar>
        )}
      />
    </PermissionCheck>
  );
};

export default NotificationFilters;
