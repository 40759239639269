import React from "react";
import { useTranslation } from "react-i18next";
import { Format } from "@redriver/cinnamon";
import DataGrid from "./DataGrid";
import classNames from "classnames";
import { FunderType } from "features/../../../shared/constants/enums";

const Currency = (value) => (
  <Format.Number value={value} format={process.env.CURRENCY_CODE} />
);

const FunderPayoutGrid = ({ data, funderType }) => {
  const { t } = useTranslation("capitalCalc", {
    keyPrefix: "infoModal.funderTypes.payout",
  });
  return (
    <DataGrid
      className={classNames("payout", funderType?.toLowerCase())}
      minimumRows={7}
      headerText={t("title")}
      lastRow={[t("dealTotal"), Currency(data.total)]}
      dataRows={[
        [t("capitalInvestment"), Currency(data.totalEquipmentValue)],
        [t("upgrade"), Currency(data.upgrade)],
        [t("supplierUpgrade"), Currency(data.dealerUpgrade)],
        [t("settlement"), Currency(data.settlement)],
        [t("supplierSettlement"), Currency(data.dealerSettlement)],
        [t("supplierLiquidityCharge"), Currency(data.supplierLiquidityCharge)],
        {
          className: "green-highlight",
          values: [t("supplierPayout"), Currency(data.dealerPayout)],
        },
        funderType != FunderType.Dealer
          ? [
              t("ipsCapitalDifference"),
              Currency(data.ipSyndicateCapitalDifference),
            ]
          : ["", ""],
        [t("ipsUpgrade"), Currency(data.ipsUpgrade)],
        [t("ipsSettlement"), Currency(data.ipsSettlement)],
        [t("ipsLiquidityCharge"), ""],
        [t("totalCapital@Ips%"), Currency(data.totalCapitalAtIpsPercent)],
        [
          t("totalCapital@Ips%@Sup"),
          Currency(data.totalCapitalAtIpsPercentAtSupplier),
        ],
        [
          t("ipsSettlement@Supplier%"),
          Currency(data.ipsSettlementAtSupplierPercent),
        ],
        [t("ipsRateRounding"), Currency(data.ipsRateRounding)],
        {
          className: "bold",
          values: [
            t("totalIpsLiquidityCharge"),
            Currency(data.totalIpsLiquidityCharge),
          ],
        },
        {
          className: "blue-highlight",
          values: [t("ipsTotal"), Currency(data.ipsTotal)],
        },
        {
          className: "blank",
          values: [null, null],
        },
      ]}
    />
  );
};

export default FunderPayoutGrid;
