import React, { cloneElement } from "react";
import { ModalBuilder, Modal, Form } from "@redriver/cinnamon";

const EditFieldModal = ({
  children,
  onSubmit,
  header,
  initialData,
  fieldName,
  formChildrenProps,
}) => {
  return (
    <ModalBuilder
      withForm
      initialData={initialData}
      onSubmit={onSubmit}
      renderTrigger={(showModal) =>
        cloneElement(children, { onClick: showModal })
      }
      renderModal={(modalProps, formProps) => {
        return (
          <Modal.Edit
            {...modalProps}
            header={header}
            submitDisabled={formProps.value[fieldName] == null}
          >
            <Form {...formProps}>
              {cloneElement(children, formChildrenProps)}
            </Form>
          </Modal.Edit>
        );
      }}
    />
  );
};

export default EditFieldModal;
