export default {
  Initialising: "Initialising",
  PreLive: "PreLive",
  PreLive_Draft: "PreLive_Draft",
  PreLive_AwaitingPrincipalPreApproval: "PreLive_AwaitingPrincipalPreApproval",

  PreLive_QueuedForDocusign: "PreLive_QueuedForDocusign",

  PreLive_WithCustomer_Docusign: "PreLive_WithCustomer_Docusign",
  PreLive_WithCustomer_Docusign_Waiting:
    "PreLive_WithCustomer_Docusign_Waiting",
  PreLive_WithCustomer_Docusign_Checking:
    "PreLive_WithCustomer_Docusign_Checking",

  PreLive_WithPrincipalAdmin_Docusign: "PreLive_WithPrincipalAdmin_Docusign",
  PreLive_WithPrincipalAdmin_Docusign_Waiting:
    "PreLive_WithPrincipalAdmin_Docusign_Waiting",
  PreLive_WithPrincipalAdmin_Docusign_Checking:
    "PreLive_WithPrincipalAdmin_Docusign_Checking",

  PreLive_WithPrincipalSignatory_Docusign:
    "PreLive_WithPrincipalSignatory_Docusign",
  PreLive_WithPrincipalSignatory_Docusign_Waiting:
    "PreLive_WithPrincipalSignatory_Docusign_Waiting",
  PreLive_WithPrincipalSignatory_Docusign_Checking:
    "PreLive_WithPrincipalSignatory_Docusign_Checking",

  PreLive_CollatingFunderPack: "PreLive_CollatingFunderPack",
  PreLive_CollatingFunderPack_Waiting: "PreLive_CollatingFunderPack_Waiting",
  PreLive_CollatingFunderPack_Checking: "PreLive_CollatingFunderPack_Checking",

  PreLive_WithFunder_Docusign: "PreLive_WithFunder_Docusign",
  PreLive_WithFunder_Docusign_Waiting: "PreLive_WithFunder_Docusign_Waiting",
  PreLive_WithFunder_Docusign_Checking: "PreLive_WithFunder_Docusign_Checking",

  PreLive_FunderApproved: "PreLive_FunderApproved",
  Live: "Live",
  Retired: "Retired",
  Dead: "Dead",

  CustomerApprovalDeclined: "CustomerApprovalDeclined",
  PrincipalApprovalDeclined: "PrincipalApprovalDeclined",
  FunderApprovalDeclined: "FunderApprovalDeclined",

  DealRejected: "DealRejected",
};
