import React, { useMemo } from "react";
import { Button } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { ModalBuilder, Modal, Form } from "@redriver/cinnamon";
import {
  usePermission,
  PermissionCheck,
} from "features/../../../shared/components/auth";
import { Actions, Targets } from "constants/permissions";
import {
  SheetActionIps,
  SheetActionPrincipal,
  SheetType,
} from "features/../../../shared/constants/enums";
import { SendForApproval } from "features/Agreements/AgreementsList/SheetActions/SendForApproval";
import { SubmitSheet } from "features/Agreements/AgreementsList/SheetActions";
import {
  downloadSheetContractWithoutSubmitting,
  downloadPrintPreview,
  downloadSheetContract,
  downloadProductScheduleAddendum,
  downloadDirectDebitAddendum,
  downloadPrincipalSoftwareSupportAddendum,
  downloadPrincipalLargeFormatAddendum,
  downloadPaperInclusiveAddendum,
  loadSheetDetails,
} from "features/Sheets/ManageSheet/actions";
import { getSheetDataState } from "features/Sheets/selectors";

const GenerateDocument = ({ sheet, renderTrigger, onSubmitted }) => {
  const { t } = useTranslation("sheetTranslation", {
    keyPrefix: "generateDocumentModal",
  });
  const dispatch = useDispatch();
  const {
    rates: { sheetType },
  } = useSelector(getSheetDataState);
  const canSubmitPhysical = usePermission(Targets.Sheet, Actions.Submit);
  const canSubmitDocusign = usePermission(
    Targets.Sheet,
    Actions.SubmitForCustomerApproval,
  );

  const dropdownOptions = useMemo(
    () =>
      [
        canSubmitDocusign
          ? { value: false, text: t("deliveryMethod.docusign") }
          : null,
        canSubmitPhysical
          ? { value: true, text: t("deliveryMethod.physical") }
          : null,
      ].filter((x) => x != null),
    [t, canSubmitDocusign, canSubmitPhysical],
  );

  const sheetId = useMemo(() => sheet.id ?? sheet.sheetId, [sheet]);

  if (!canSubmitDocusign && !canSubmitPhysical) return null; // User needs to be able to submit at least one method
  if (sheetType == null) return; // We need to have this field in order to download the correct document.

  return (
    <ModalBuilder
      withForm
      renderTrigger={renderTrigger}
      renderModal={(modalProps, formProps, state, events) => (
        <Modal.Add
          {...modalProps}
          header={t("headerText")}
          submitLabel={t("submitText")}
          className="generate-document-modal"
        >
          <Form {...formProps}>
            <Form.Dropdown
              field="usePhysicalDocument"
              label={t("deliveryMethod.title")}
              options={dropdownOptions}
              placeholder={t("deliveryMethod.placeholder")}
            />

            <div className="action-buttons">
              <Button negative className="cancel" onClick={modalProps.onCancel}>
                {t("buttons.cancel")}
              </Button>

              <Form.If
                condition={({ fields }) =>
                  !fields.usePhysicalDocument && canSubmitDocusign
                }
              >
                <SendForApproval
                  sheetData={sheet}
                  onSubmitted={async () => {
                    await dispatch(loadSheetDetails(sheetId));
                    onSubmitted && onSubmitted();
                    events.onCancel();
                  }}
                  sheetActionRequired={
                    process.env.TRADE_AS_IPS
                      ? SheetActionIps.CanSendForApproval
                      : SheetActionPrincipal.CanSendForApproval
                  }
                  generateDocument={true}
                  scheduleReference={sheet.scheduleReference}
                  agreementReference={sheet.agreementReference}
                  boilerPlateTandCsId={sheet.boilerPlateTandCsId}
                  triggerDisabled={
                    formProps?.value?.usePhysicalDocument == null
                  }
                />
              </Form.If>

              <Form.If
                condition={({ fields }) =>
                  !!fields.usePhysicalDocument && canSubmitPhysical
                }
              >
                <SubmitSheet
                  generateDocument
                  sheet={sheet}
                  onSubmitted={async () => {
                    if (
                      sheetType == SheetType.NewMaster ||
                      sheetType == SheetType.RewriteNewMasterDocument
                    ) {
                      dispatch(downloadPrintPreview({ sheetId }));
                    } else {
                      dispatch(downloadSheetContractWithoutSubmitting(sheetId));
                    }

                    if (!process.env.TRADE_AS_IPS) {
                      dispatch(downloadProductScheduleAddendum(sheetId));
                      dispatch(downloadDirectDebitAddendum(sheetId));
                      dispatch(
                        downloadPrincipalSoftwareSupportAddendum(sheetId),
                      );
                      dispatch(downloadPrincipalLargeFormatAddendum(sheetId));
                      dispatch(downloadPaperInclusiveAddendum(sheetId));
                    }

                    await dispatch(loadSheetDetails(sheetId));
                    onSubmitted && onSubmitted();
                    events.onCancel();
                  }}
                />
              </Form.If>
            </div>
          </Form>
        </Modal.Add>
      )}
    />
  );
};

export default GenerateDocument;
