import React from "react";
import { Table, ActionMenu } from "@redriver/cinnamon";
import { withRouter } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getAgreementsState } from "../../selectors";
import {
  PermissionCheck,
  withCurrentUser,
  withPermissions,
} from "features/../../../shared/components/auth";
import { Targets, Actions } from "constants/permissions";
import { AppRoutes } from "constants/routes";
import {
  PhysicalDocumentSheetStatePrincipal,
  SheetActionIps,
  SheetActionPrincipal,
  SheetStateIps,
  SheetStatePrincipal,
  SheetStatus,
} from "features/../../../shared/constants/enums";

import {
  getMasterAgreement,
  getMasterAgreements,
  addExpandedAgreementOrSchedule,
  getAgreementScheduleSheets,
} from "../actions";
import {
  SubmitSheet,
  SetPhysicalDocumentSheetState,
  RenameSheet,
  SheetStateProgressView,
  SendForApproval,
  PreApproveSheetPrincipal,
  PreApproveSheetIps,
  IpsReview,
  MakeSheetLive,
  ResetSheetState,
  AddAdminComment,
  AmendExpiryDate,
  DeclinePreApprovalPrincipal,
  DeclinePreApprovalIps,
  DocumentRecipientsView,
} from "../SheetActions";
import {
  SetSheetOwner,
  CloneSheet,
  DeleteSheet,
  CrystalliseSheet,
  UncrystalliseSheet,
  ArchiveSheet,
  UnarchiveSheet,
  ToggleSheetReadOnly,
  DuplicateToNewMaster,
} from "features/Sheets/ManageSheet/SheetActions";
import { SetCurrentSchedule } from "../SetCurrentSchedule";
import { SetReplacingSchedule } from "../SetReplacingSchedule";
import { VoidEnvelope } from "../SheetActions/VoidEnvelope";
import {
  SheetStateIps as NumberedSheetStateIps,
  SheetStatePrincipal as NumberedSheetStatePrincipal,
} from "features/../constants/sheetState";
import SendToFunder from "../SheetActions/SendToFunder/SendToFunder";
import { RejectDeal } from "../SheetActions/RejectDeal";
import { useTranslation } from "react-i18next";
import { TableSubMenu } from "components/menus";

const SheetActions = ({
  agreement,
  schedule,
  sheet,
  currentUser,
  hasPermission,
  history,
}) => {
  const { t } = useTranslation("agreements", { keyPrefix: "SheetActions" });
  const dispatch = useDispatch();
  const { filters, companyFilters, pagination } =
    useSelector(getAgreementsState);

  const dealerId = companyFilters?.dealer?.id;
  let { sheetId, sheetName, ownedBy, isReadOnly } = sheet,
    { agreementReference, boilerPlateTandCsId } = agreement,
    { scheduleReference } = schedule;
  const { isSupplierAdminUser, isInternalUser } = currentUser;
  const isAdminUser = isInternalUser || isSupplierAdminUser;

  const canPreApproveAsSupplier =
    hasPermission(Targets.Sheet, Actions.PreApproveSupplier) &&
    (process.env.TRADE_AS_IPS
      ? sheet.stateIps == SheetStateIps.PreLive_AwaitingSupplierPreApproval
      : sheet.statePrincipal ==
          SheetStatePrincipal.PreLive_AwaitingPrincipalPreApproval &&
        !sheet.submittedForPhysicalDocumentFlow);

  const canPreApproveAsIPS =
    process.env.TRADE_AS_IPS &&
    isInternalUser &&
    hasPermission(Targets.Sheet, Actions.PreApproveIPS) &&
    sheet.stateIps == SheetStateIps.PreLive_AwaitingIpsPreApproval;
  const sheetInPreApprovalState = process.env.TRADE_AS_IPS
    ? sheet.stateIps == SheetStateIps.PreLive_AwaitingIpsPreApproval ||
      sheet.stateIps == SheetStateIps.PreLive_AwaitingSupplierPreApproval
    : sheet.statePrincipal ==
      SheetStatePrincipal.PreLive_AwaitingPrincipalPreApproval;

  const reloadAgreement = () =>
    dispatch(getMasterAgreement(agreement.agreementId, filters));

  const reloadAgreements = () =>
    dispatch(getMasterAgreements({ filters, pagination }, companyFilters));

  const onDuplicateToNewMasterSubmitted = (response) => {
    reloadAgreements();
    dispatch(getAgreementScheduleSheets(response.scheduleId, filters));

    // Expand newly created agreement and schedule, so using the response IDs.
    dispatch(addExpandedAgreementOrSchedule(response.masterAgreementId));
    dispatch(addExpandedAgreementOrSchedule(response.scheduleId));
  };

  const reloadSheetsForSchedule = () =>
    dispatch(getAgreementScheduleSheets(schedule.scheduleId, filters));

  return (
    <Table.Menu upward className="sheet-actions">
      <TableSubMenu
        text={t("Groups.New_Sheet_Actions")}
        floating="right"
        icon="caret left"
      >
        <TableSubMenu.Menu>
          {!sheet.isDeclined && (
            <CloneSheet
              sheetId={sheetId}
              name={sheetName}
              isLive={sheet.isLive}
            />
          )}
          {!sheet.isDeclined && (
            <PermissionCheck
              action={Actions.DuplicateToNewMaster}
              target={Targets.Sheet}
            >
              <DuplicateToNewMaster
                sheetId={sheetId}
                name={sheetName}
                onSubmitted={onDuplicateToNewMasterSubmitted}
              />
            </PermissionCheck>
          )}
        </TableSubMenu.Menu>
      </TableSubMenu>

      <TableSubMenu
        text={t("Groups.Sheet_Actions")}
        floating="right"
        icon="caret left"
      >
        <TableSubMenu.Menu>
          {(!isReadOnly || isAdminUser) && !sheet.isDeclined && (
            <RenameSheet sheet={sheet} onSubmitted={reloadSheetsForSchedule} />
          )}

          {(!isReadOnly || isAdminUser) && !sheet.isDeclined && (
            <PermissionCheck action={Actions.Resurrect} target={Targets.Sheet}>
              <DeleteSheet
                sheet={sheet}
                onSubmitted={() => {
                  reloadSheetsForSchedule();
                  reloadAgreement();
                }}
                submitParams={{ sheetId }}
              />
            </PermissionCheck>
          )}

          {isAdminUser && !sheet.isDeclined && (
            <AmendExpiryDate
              sheet={sheet}
              onSubmitted={reloadSheetsForSchedule}
            />
          )}

          {isAdminUser && !sheet.isDeclined && (
            <PermissionCheck
              target={Targets.Sheet}
              action={Actions.UpdateSheetScheduleReference}
            >
              <SetCurrentSchedule
                sheet={sheet}
                onSubmitted={reloadSheetsForSchedule}
              />
            </PermissionCheck>
          )}

          {isInternalUser && !sheet.isDeclined && (
            <SetReplacingSchedule
              sheet={sheet}
              onSubmitted={reloadSheetsForSchedule}
            />
          )}
        </TableSubMenu.Menu>
      </TableSubMenu>

      <TableSubMenu
        text={t("Groups.Document_Actions")}
        floating="right"
        icon="caret left"
      >
        <TableSubMenu.Menu>
          {!sheetInPreApprovalState &&
            !sheet.isDeclined &&
            !sheet.submittedForPhysicalDocumentFlow && (
              <PermissionCheck
                target={Targets.Sheet}
                action={Actions.SubmitForCustomerApproval}
              >
                <SendForApproval
                  sheet={sheet}
                  onSubmitted={() => {
                    reloadSheetsForSchedule();
                    reloadAgreement();
                  }}
                  scheduleReference={scheduleReference}
                  agreementReference={agreementReference}
                  boilerPlateTandCsId={boilerPlateTandCsId}
                  sheetActionRequired={
                    process.env.TRADE_AS_IPS
                      ? SheetActionIps.CanSendForApproval
                      : SheetActionPrincipal.CanSendForApproval
                  }
                />
              </PermissionCheck>
            )}

          {!sheet.submittedForPhysicalDocumentFlow &&
            !sheet.submittedForDocusignFlow &&
            (!isReadOnly || isAdminUser) && (
              <PermissionCheck target={Targets.Sheet} action={Actions.Submit}>
                <SubmitSheet
                  sheet={sheet}
                  onSubmitted={reloadSheetsForSchedule}
                />
              </PermissionCheck>
            )}
          {sheet.submittedForDocusignFlow && (
            <PermissionCheck
              target={Targets.Sheet}
              action={Actions.ViewDocumentRecipients}
            >
              <DocumentRecipientsView sheetId={sheetId} />
            </PermissionCheck>
          )}
          {process.env.TRADE_AS_IPS &&
            !sheet.isDeclined &&
            (canPreApproveAsSupplier || canPreApproveAsIPS) && (
              <PreApproveSheetIps
                sheet={sheet}
                onSubmitted={reloadSheetsForSchedule}
              />
            )}
          {!process.env.TRADE_AS_IPS &&
            canPreApproveAsSupplier &&
            !sheet.isDeclined && (
              <PreApproveSheetPrincipal
                sheet={sheet}
                onSubmitted={() => {
                  reloadSheetsForSchedule();
                  reloadAgreement();
                }}
                scheduleReference={scheduleReference}
                agreementReference={agreementReference}
                boilerPlateTandCsId={boilerPlateTandCsId}
                sheetActionRequired={
                  SheetActionPrincipal.CanBePreApprovedByPrincipal
                }
              />
            )}
          {process.env.TRADE_AS_IPS &&
            !sheet.isDeclined &&
            (canPreApproveAsSupplier || canPreApproveAsIPS) && (
              <DeclinePreApprovalIps
                sheet={sheet}
                onSubmitted={reloadSheetsForSchedule}
              />
            )}

          {!process.env.TRADE_AS_IPS &&
            !sheet.isDeclined &&
            canPreApproveAsSupplier && (
              <DeclinePreApprovalPrincipal
                sheet={sheet}
                onSubmitted={reloadSheetsForSchedule}
              />
            )}

          {sheet.isInDocuSign && (
            <PermissionCheck action={Actions.Void} target={Targets.Sheet}>
              <VoidEnvelope
                sheet={sheet}
                onSubmitted={() => {
                  reloadSheetsForSchedule();
                  reloadAgreement();
                }}
              />
            </PermissionCheck>
          )}

          {isAdminUser && (
            <PermissionCheck action={Actions.Reset} target={Targets.Sheet}>
              <ResetSheetState
                sheet={sheet}
                onSubmitted={reloadSheetsForSchedule}
                sheetActionRequired={
                  process.env.TRADE_AS_IPS
                    ? SheetActionIps.CanResetSheetState
                    : SheetActionPrincipal.CanResetSheetState
                }
              />
            </PermissionCheck>
          )}

          {!sheet.submittedForPhysicalDocumentFlow && !sheet.isDeclined && (
            <SheetStateProgressView sheet={sheet} />
          )}

          {!process.env.TRADE_AS_IPS && !sheet.isDeclined && (
            <React.Fragment>
              {isAdminUser &&
                sheet.submittedForPhysicalDocumentFlow &&
                !sheet.submittedForDocusignFlow &&
                sheet.physicalDocumentSheetStatePrincipal ===
                  PhysicalDocumentSheetStatePrincipal.SubmittedToPrincipalAdmin && (
                  <PermissionCheck
                    target={Targets.Sheet}
                    action={Actions.SetPhysicalDocumentSheetState}
                  >
                    <SendToFunder
                      sheet={sheet}
                      onSubmitted={reloadSheetsForSchedule}
                    />
                  </PermissionCheck>
                )}

              {isAdminUser && (
                <PermissionCheck
                  target={Targets.Sheet}
                  action={Actions.RejectDeal}
                >
                  <RejectDeal
                    sheet={sheet}
                    onSubmitted={reloadSheetsForSchedule}
                  />
                </PermissionCheck>
              )}
            </React.Fragment>
          )}
        </TableSubMenu.Menu>
      </TableSubMenu>

      <TableSubMenu
        text={t("Groups.Admin_Actions")}
        floating="right"
        icon="caret left"
      >
        <TableSubMenu.Menu>
          <Table.MenuItem
            onClick={() =>
              history.push(`${AppRoutes.Notification}?sheetId=${sheetId}`)
            }
          >
            {t("ViewNotifications")}
          </Table.MenuItem>

          {(!isReadOnly || isAdminUser) && !sheet.isDeclined && (
            <PermissionCheck target={Targets.Sheet} action={Actions.SetOwner}>
              <SetSheetOwner
                sheetId={sheetId}
                dealerId={dealerId}
                ownedBy={ownedBy}
                onSubmitted={reloadSheetsForSchedule}
              />
            </PermissionCheck>
          )}

          {isAdminUser && !sheet.isDeclined && (
            <AddAdminComment
              sheet={sheet}
              onSubmitted={reloadSheetsForSchedule}
            />
          )}

          {!sheet.submittedForDocusignFlow && (!isReadOnly || isAdminUser) && (
            <PermissionCheck
              target={Targets.Sheet}
              action={Actions.SetPhysicalDocumentSheetState}
            >
              <SetPhysicalDocumentSheetState
                sheet={sheet}
                onSubmitted={reloadSheetsForSchedule}
              />
            </PermissionCheck>
          )}

          {(!isReadOnly || isAdminUser) && !sheet.isDeclined && (
            <PermissionCheck
              target={Targets.Sheet}
              action={Actions.CrystalliseAndUncrystalliseSheets}
            >
              <React.Fragment>
                <UncrystalliseSheet
                  sheet={sheet}
                  onSubmitted={reloadSheetsForSchedule}
                  sheetActionRequired={
                    process.env.TRADE_AS_IPS
                      ? SheetActionIps.CanUncrystallise
                      : SheetActionPrincipal.CanUncrystallise
                  }
                />
                <CrystalliseSheet
                  sheet={sheet}
                  onSubmitted={reloadSheetsForSchedule}
                  sheetActionRequired={
                    process.env.TRADE_AS_IPS
                      ? SheetActionIps.CanCrystallise
                      : SheetActionPrincipal.CanCrystallise
                  }
                />
              </React.Fragment>
            </PermissionCheck>
          )}
          {(!isReadOnly || isAdminUser) &&
            !sheet.isDeclined &&
            sheet.isArchived && (
              <UnarchiveSheet
                sheet={sheet}
                onSubmitted={reloadSheetsForSchedule}
              />
            )}
          {(!isReadOnly || isAdminUser) &&
            !sheet.isDeclined &&
            !sheet.isArchived && (
              <ArchiveSheet
                sheet={sheet}
                onSubmitted={reloadSheetsForSchedule}
              />
            )}
          {(!isReadOnly || isAdminUser) && !sheet.isDeclined && (
            <PermissionCheck
              target={Targets.Sheet}
              action={Actions.SetReadOnly}
            >
              <ToggleSheetReadOnly
                sheet={sheet}
                onSubmitted={reloadSheetsForSchedule}
              />
            </PermissionCheck>
          )}

          {!sheet.isDeclined && (
            <MakeSheetLive
              sheet={sheet}
              scheduleIsLive={schedule.isLive}
              sheetActionRequired={
                process.env.TRADE_AS_IPS
                  ? SheetActionIps.CanMakeLive
                  : SheetActionPrincipal.CanMakeLive
              }
              onSubmitted={() => {
                reloadSheetsForSchedule();
                reloadAgreement();
              }}
              scheduleReference={scheduleReference}
              agreementReference={agreementReference}
            />
          )}
        </TableSubMenu.Menu>
      </TableSubMenu>
    </Table.Menu>
  );
};

export default withPermissions(withCurrentUser(withRouter(SheetActions)));
