import { ModalBuilder, Table, notifySuccess } from "@redriver/cinnamon";
import React from "react";
import { Button, Icon } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import { TranslatedModal } from "features/../../../shared/components/modals";
import { submitSheet } from "./actions";
import { useDispatch } from "react-redux";

const SubmitSheet = ({
  sheet,
  onSubmitted,
  generateDocument,
  boilerPlateTandCsId,
}) => {
  const { t } = useTranslation("agreements");
  const { t: tSheet } = useTranslation("sheetTranslation");
  const dispatch = useDispatch();
  const { sheetId, sheetName } = sheet;

  return (
    <ModalBuilder
      submitAction={submitSheet}
      submitParams={{ sheetId: sheetId ?? sheet.id }}
      onSubmitted={() => {
        dispatch(notifySuccess("Sheet successfully submitted"));
        onSubmitted();
      }}
      renderTrigger={(onTrigger) =>
        generateDocument ? (
          <Button
            className="generate-btn"
            primary
            onClick={onTrigger}
            disabled={!boilerPlateTandCsId && process.env.TRADE_AS_IPS}
          >
            <Icon name="check" />
            {tSheet(
              `SubmitSheet.${
                process.env.TRADE_AS_IPS
                  ? "TriggerText_Ips"
                  : "TriggerText_Principal"
              }`,
            )}
          </Button>
        ) : (
          <Table.MenuItem onClick={onTrigger}>
            {t("SheetActions.SubmitSheet.TriggerText")}
          </Table.MenuItem>
        )
      }
      renderModal={(modalProps) => (
        <TranslatedModal.Confirmation
          {...modalProps}
          header={t("SheetActions.SubmitSheet.ModalHeader")}
          content={t("SheetActions.SubmitSheet.ModalContent", { sheetName })}
        />
      )}
    />
  );
};

export default SubmitSheet;
