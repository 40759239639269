import { apiPost, notifySuccess } from "@redriver/cinnamon";

const ActionTypes = {
  CustomerApprovalSubmit: "SHEETS/CUSTOMER_APPROVAL_SUBMIT",
};

export const sendForCustomerApproval =
  (formData = {}, params = {}) =>
  async (dispatch) => {
    let { sheetId, includeSecondClientSignatory } = params;

    if (!includeSecondClientSignatory) {
      formData.customerSignatory2 = null;
    }

    const cleanupSignatoryField = (fieldName) => {
      const fieldValue = formData[fieldName];
      if (fieldValue == null) return;

      formData[fieldName].isNew = formData[fieldName][`isNew_${fieldName}`];
      delete formData[fieldName][`isNew_${fieldName}`];
      delete formData[fieldName].authorisedSignatory;
    };

    cleanupSignatoryField("customerSignatory1");
    cleanupSignatoryField("customerSignatory2");

    const response = await dispatch(
      apiPost(
        ActionTypes.CustomerApprovalSubmit,
        `sheets/${sheetId}/submit/customer-approval`,
        formData,
      ),
    );
    response &&
      response.success &&
      dispatch(
        notifySuccess("Sheet successfully submitted for Customer approval"),
      );
    return response;
  };
