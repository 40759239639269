import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector, connect } from "react-redux";
import {
  ModalBuilder,
  Form,
  FormBuilder,
  useAuthClaims,
} from "@redriver/cinnamon";
import { withRouter } from "react-router-dom";
import KeyPressTriggerButton from "./KeyPressTriggerButton";
import { Button, Grid, GridColumn, GridRow, Dimmer } from "semantic-ui-react";
import {
  verifyUnlockView,
  setDefaultSheetViewType,
  defaultImpersonatedUser,
} from "features/Sheets/ManageSheet/actions";
import { withPermissions } from "features/../../../shared/components/auth";
import { Targets, Actions } from "constants/permissions";
import { compose } from "redux";

import { getSheetDataState } from "features/Sheets";
import { TranslatedModal } from "features/../../../shared/components/modals";
import { useTranslation } from "react-i18next";
import { InlineLoader } from "features/../../../shared/components/forms/InlineLoader";
import { getHasUserPin } from "./actions";
import { SystemRoutes } from "constants/routes";

const SheetViewConfirmationModal = ({
  disabled,
  customerToggle,
  customerView,
  triggerButtonClassName,
  triggerButtonText,
  hideIcon,
  hasPermission,
  impersonatedUser,
  defaultImpersonatedUser,
  setImpersonatedUserFlicker,
  history,
  getHasUserPin,
}) => {
  const { t } = useTranslation("customerView", {
    keyPrefix: "sheetViewConfirmationModal",
  });
  const dispatch = useDispatch();
  const { userType } = useAuthClaims();

  const canEditAdminFields =
    hasPermission(Targets.SheetAdmin, Actions.Edit) &&
    impersonatedUser.canEditAdminFields;

  //use ref to enable automatic focus on second pin entry once first is complete.
  const pin2InputRef = useRef(null);

  //state
  const [location1, setLocation1] = useState();
  const [location2, setLocation2] = useState();
  const [location1Desc, setLocation1Desc] = useState("");
  const [location2Desc, setLocation2Desc] = useState("");
  const [inputErrors, setInputErrors] = useState("");
  const [verifyErrors, setVerifyErrors] = useState("");
  const [hasPin, setHasPin] = useState(false);
  const [hasPinResponse, setHasPinResponse] = useState(false);
  const [loading, setLoading] = useState(false);

  //initial setup on page load
  useEffect(() => {
    //pin character locations descriptions for header text.
    const getLocationDesc = (location) => {
      let desc = "";
      switch (location) {
        case 1:
          desc = t("first");
          break;
        case 2:
          desc = t("second");
          break;
        case 3:
          desc = t("third");
          break;
        case 4:
          desc = t("fourth");
      }
      return desc;
    };
    //possible pin combinations, numerical order only.
    const pinCombinations = [
      { location1: 1, location2: 2 },
      { location1: 1, location2: 3 },
      { location1: 1, location2: 4 },
      { location1: 2, location2: 3 },
      { location1: 2, location2: 4 },
      { location1: 3, location2: 4 },
    ];
    const randNum = Math.floor(Math.random() * 6);
    const combination = pinCombinations[randNum];
    setLocation1(combination.location1);
    setLocation1Desc(getLocationDesc(combination.location1));
    setLocation2(combination.location2);
    setLocation2Desc(getLocationDesc(combination.location2));
    setInputErrors(t("enterPinError"));
  }, [t]);

  //ensure user can only enter 1 digit per textbox
  const onFormChange = (field, data, applyChanges, formProps) => {
    const { value, onChange } = formProps;
    if (data.length < 2) {
      if (!isNaN(data)) {
        let newFormData = applyChanges(value);
        onChange(field, newFormData, () => applyChanges(newFormData));
        //if user has inputted in the first textbox automatically tab to the second
        if (field == "secretPin1") {
          pin2InputRef.current.focus();
        }
      }
    }
  };

  return (
    <ModalBuilder
      withForm
      renderTrigger={(showModal) => {
        return (
          <KeyPressTriggerButton
            showModal={showModal}
            customerView={customerView}
            disabled={disabled}
            customerToggle={() => {
              customerToggle();
              setImpersonatedUserFlicker(false);
              defaultImpersonatedUser();
            }}
            className={triggerButtonClassName}
            buttonText={triggerButtonText}
            hideIcon={hideIcon}
          />
        );
      }}
      renderModal={(modalProps) => (
        <TranslatedModal.Edit
          {...modalProps}
          header={
            hasPinResponse
              ? hasPin
                ? t("modalHeader", {
                    location1Desc: location1Desc,
                    location2Desc: location2Desc,
                  })
                : t("noPin")
              : t("checkingPinStatus")
          }
          className="sheet-passcode-modal custom-actions"
        >
          <FormBuilder
            renderLoading={false}
            onLoad={async () => {
              setHasPinResponse(false);
              setLoading(true);
              const { success, response } = await getHasUserPin();
              if (!success) return;

              setHasPin(response.hasPin);
              setLoading(false);
              setHasPinResponse(true);
            }}
            onSubmit={async ({ secretPin1, secretPin2 }) => {
              async function verify() {
                let result = await dispatch(
                  verifyUnlockView({
                    pin1: secretPin1,
                    location1: location1,
                    pin2: secretPin2,
                    location2: location2,
                  }),
                );
                return (
                  result && result.response && result.response.permitUnlock
                );
              }
              setImpersonatedUserFlicker(false);
              defaultImpersonatedUser();

              if (await verify()) {
                dispatch(setDefaultSheetViewType(userType, canEditAdminFields));
                customerToggle();
                return;
              }

              setVerifyErrors(t("pinMatchError"));
              setLoading(false);
            }}
            renderForm={(formProps, state, formEvents) => (
              <Form
                {...formProps}
                className="switch-passcode"
                onKeyDown={(event) => {
                  switch ((event || {}).key) {
                    case "Enter":
                      formEvents.onSubmit();
                      break;
                    case "Escape":
                      modalProps.onCancel();
                      break;
                    default:
                      event.stopPropagation();
                      break;
                  }
                }}
                onChange={(field, data, applyChanges) => {
                  onFormChange(field, data, applyChanges, formProps);
                  setVerifyErrors("");
                }}
              >
                {loading || state.loading || !hasPinResponse ? (
                  <div className="pin-loading">
                    <Dimmer active inverted>
                      <InlineLoader isActive={true} size="medium" />
                    </Dimmer>
                  </div>
                ) : hasPin ? (
                  <React.Fragment>
                    <Grid>
                      <GridRow columns={2}>
                        <GridColumn className="right">
                          <Form.Password required autoFocus field="secretPin1">
                            <input autoComplete="secret-pin-1" />
                          </Form.Password>
                        </GridColumn>
                        <GridColumn>
                          <Form.Password
                            required
                            field="secretPin2"
                            ref={pin2InputRef}
                            customValidator={(_, __, ___, { value }) => {
                              if (
                                value.secretPin1?.length !== 1 ||
                                value.secretPin2?.length !== 1
                              ) {
                                setInputErrors(t("enterPinError"));
                              } else {
                                setInputErrors("");
                              }
                            }}
                          >
                            <input autoComplete="secret-pin-2" />
                          </Form.Password>
                        </GridColumn>
                      </GridRow>
                    </Grid>

                    {(inputErrors != "" || verifyErrors != "") && (
                      <div className="center-align">
                        <p className="red">
                          {verifyErrors}
                          {verifyErrors && inputErrors && <br />}
                          {inputErrors}
                        </p>
                      </div>
                    )}

                    <div className="custom-action-container">
                      <Button
                        negative
                        className="cancel"
                        onClick={modalProps.onCancel}
                      >
                        {t("cancel")}
                      </Button>
                      <Button
                        positive
                        onClick={() => {
                          formEvents.onSubmit();
                          setLoading(true);
                          formEvents.onReset();
                        }}
                        disabled={inputErrors != ""}
                      >
                        {t("enter")}
                      </Button>
                    </div>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <div className="no-pin-account-page-redirect">
                      <div className="no-pin-text-spacing">
                        {t("noPinMain")}
                      </div>
                      <div className="custom-action-container">
                        <Button
                          primary
                          positive
                          content={t("accountsPage")}
                          onClick={() => history.push(SystemRoutes.Account)}
                        />
                        <Button
                          primary
                          negative
                          content={t("close")}
                          onClick={modalProps.onCancel}
                        />
                      </div>
                    </div>
                  </React.Fragment>
                )}
              </Form>
            )}
          />
        </TranslatedModal.Edit>
      )}
    />
  );
};

const actions = {
  defaultImpersonatedUser,
  getHasUserPin,
};

const mapStateToProps = (state) => {
  const { impersonatedUser } = getSheetDataState(state);
  return { impersonatedUser };
};

export default compose(
  connect(mapStateToProps, actions),
  withPermissions,
  withRouter,
)(SheetViewConfirmationModal);
