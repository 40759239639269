import React from "react";
import { connect } from "react-redux";
import ConfirmationModal from "./ConfirmationModal";
import { useTranslation } from "react-i18next";
import { saveDetails } from "features/Sheets/ManageSheet/actions";

const SaveConfirmationModal = ({
  onSubmitted,
  disabled,
  sheetId,
  sheetCanBeUpdated,
  buttonContent,
  buttonIcon,
  buttonClassName,
  skipHeaderUpdate = false,
  redirectFunction = null,
  cancelReload = false,
}) => {
  const { t } = useTranslation("sheetTranslation");

  return (
    <ConfirmationModal
      disabled={disabled}
      sheetId={sheetId}
      sheetCanBeUpdated={sheetCanBeUpdated}
      buttonContent={buttonContent}
      buttonIcon={buttonIcon}
      buttonClassName={buttonClassName}
      onSubmitted={onSubmitted}
      headerText={t("saveConfirmationModal.header")}
      bodyText={t(
        sheetCanBeUpdated
          ? "saveConfirmationModal.bodyTextWithSaveWarning"
          : "saveConfirmationModal.bodyTextWithoutSaveWarning",
      )}
      errorText={t("saveConfirmationModal.unallocated_unit_error")}
      submitAction={
        sheetCanBeUpdated
          ? () =>
              saveDetails(
                sheetId,
                skipHeaderUpdate,
                redirectFunction,
                cancelReload,
              )
          : null
      }
    ></ConfirmationModal>
  );
};

export default connect()(SaveConfirmationModal);
