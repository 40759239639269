import React from "react";
import { Button, Icon } from "semantic-ui-react";
import { ModalBuilder } from "@redriver/cinnamon";
import { TranslatedModal } from "features/../../../shared/components/modals";
import {
  usePermission,
  PermissionCheck,
} from "features/../../../shared/components/auth";
import { useTranslation } from "react-i18next";
import InlinePdf from "features/../../../shared/components/display/InlinePdf";
import {
  downloadSheetContract,
  getSheetContract,
  downloadPrintPreview,
  canCalculate,
} from "./actions";

import { Actions, Targets } from "constants/permissions";

import { useDispatch, connect } from "react-redux";
import { SendForApproval } from "features/Agreements/AgreementsList/SheetActions/SendForApproval";
import {
  SheetActionIps,
  SheetActionPrincipal,
} from "features/../../../shared/constants/enums";
import { SaveConfirmationModal } from "features/Sheets/ManageSheet/Shared";
import { compose } from "redux";
import { getSheetDataState, getCalculationData } from "../selectors";
import { canSheetBeUpdated } from "features/Sheets/ManageSheet/helpers";
import { GenerateDocument } from "./SheetActions";

const PreviewGenerateDocument = ({
  sheetId,
  disableTrigger,
  disableSubmit,
  onSubmitted,
  renderTrigger,
  sheet,
  sheetCanBeUpdated,
  canCalc,
  downloadOnly,
  isDraft,
}) => {
  const { t } = useTranslation("sheetTranslation"),
    tScope = "previewGenDocModal";
  const dispatch = useDispatch();
  return (
    <ModalBuilder
      loadAction={() =>
        getSheetContract({ sheetId }, { watermark: true, isPreview: true })
      }
      submitAction={downloadSheetContract}
      submitParams={{ sheetId }}
      onSubmitted={onSubmitted}
      renderTrigger={
        renderTrigger ||
        ((onRender) => (
          <Button
            primary
            compact
            icon="print"
            disabled={disableTrigger}
            onClick={onRender}
            content={t("previewGenDocModal.trigger")}
          />
        ))
      }
      renderModal={(modalProps, data, state, events) => (
        <TranslatedModal.Edit
          {...modalProps}
          size="large"
          header={t(`${tScope}.header`)}
          submitLabel={t(`${tScope}.positiveLabel`)}
          cancelLabel={t(`${tScope}.negativeLabel`)}
          submitDisabled={state.loading || state.error || disableSubmit}
          className="preview-document-modal"
        >
          {!state.loading && !state.error && (
            <InlinePdf src={data} initialScale={1.5} />
          )}

          <div className="action-buttons">
            <Button
              primary
              onClick={() => dispatch(downloadPrintPreview({ sheetId }))}
            >
              <Icon name="print" />
              Print Preview
            </Button>
            <div className="button-on-right">
              <label className="back" onClick={modalProps.onCancel}>
                Back
              </label>
              {process.env.TRADE_AS_IPS ? (
                downloadOnly ? (
                  <Button
                    className="generate-btn"
                    primary
                    onClick={modalProps.onSubmit}
                    disabled={!isDraft}
                  >
                    <Icon name="check" />
                    {t("generateDocumentModal.triggerText")}
                  </Button>
                ) : (
                  <PermissionCheck
                    target={Targets.Sheet}
                    action={Actions.SubmitForCustomerApproval}
                  >
                    <SendForApproval
                      sheetData={sheet}
                      onSubmitted={onSubmitted}
                      sheetActionRequired={
                        process.env.TRADE_AS_IPS
                          ? SheetActionIps.CanSendForApproval
                          : SheetActionPrincipal.CanSendForApproval
                      }
                      generateDocument={true}
                      scheduleReference={sheet.scheduleReference}
                      agreementReference={sheet.agreementReference}
                      boilerPlateTandCsId={sheet.boilerPlateTandCsId}
                    />
                  </PermissionCheck>
                )
              ) : null}
              {!process.env.TRADE_AS_IPS && (
                <GenerateDocument
                  sheet={sheet}
                  onSubmitted={() => {
                    events.onCancel();
                    onSubmitted && onSubmitted();
                  }}
                  renderTrigger={(showModal) => (
                    <Button
                      onClick={showModal}
                      primary
                      className="generate-btn"
                      disabled={!sheet.isDraft}
                    >
                      {t("generateDocumentModal.triggerText")}
                    </Button>
                  )}
                />
              )}
            </div>
          </div>
        </TranslatedModal.Edit>
      )}
    />
  );
};

const mapStateToProps = (state) => {
  const sheetData = getSheetDataState(state);
  const calculationData = getCalculationData(state);
  return {
    sheet: sheetData,
    sheetCanBeUpdated: canSheetBeUpdated(sheetData),
    canCalc: canCalculate(sheetData, calculationData),
    isDraft: sheetData.isDraft,
  };
};

export default compose(connect(mapStateToProps))(PreviewGenerateDocument);
