import React from "react";
import { Form, registerLookup, apiGet } from "@redriver/cinnamon";

const FunderSignsLookups = {
  GetFunderSigns: "getFunderSignsLookup",
  GetFunders: "getFundersLookup",
};

const ActionTypes = {
  GetFunderSigns: "LOOKUPS/GET_FUNDER__SIGNATORY",
  GetFunders: "LOOKUPS/GET_FUNDERS",
};

registerLookup(
  FunderSignsLookups.GetFunderSigns,
  ({ funderId, search }) =>
    apiGet(ActionTypes.GetFunderSigns, "lookups/funder-signatories", {
      funderId,
      search,
    }),
  {
    cache: false,
    transform: (data) =>
      Array.isArray(data)
        ? data.map((x) => ({
            ...x,
            text: `${x.name} - ${x.position} - ${x.email}`,
          }))
        : [],
  },
);

export const FunderSignTypeAhead = ({ funderId, readOnly }) => (
  <Form.TypeAhead
    required
    searchSuggestions
    alwaysShowSuggestionsOnBlur
    readOnly={readOnly}
    field="funderSignatoryId"
    label="Funder Signatory"
    placeholder="Search Funder Signatories..."
    lookup={FunderSignsLookups.GetFunderSigns}
    lookupParams={{
      funderId,
    }}
    className="client--sign-dropdown"
    optionField="funderSignatory"
  />
);

registerLookup(
  FunderSignsLookups.GetFunders,
  ({ search }) =>
    apiGet(ActionTypes.GetFunders, "lookups/funders", {
      search,
    }),
  {
    cache: false,
  },
);

export const FunderTypeAhead = ({ readOnly, required }) => (
  <Form.TypeAhead
    required={required}
    searchSuggestions
    alwaysShowSuggestionsOnBlur
    readOnly={readOnly}
    field="funderId"
    label="Funder"
    placeholder="Search Funders…"
    lookup={FunderSignsLookups.GetFunders}
    className="client--sign-dropdown"
    optionField="funder"
  />
);
