import React from "react";
import { compose } from "redux";
import { Icon } from "semantic-ui-react";
import { useTranslation, Trans } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  Form,
  FormBuilder,
  ActionButton,
  TriggerBuilder,
} from "@redriver/cinnamon";
import BlueAddSvg from "features/../../../shared/assets/blue-add.svg";
import {
  UserTypeCheck,
  PermissionCheck,
  withCurrentUser,
  withPermissions,
} from "features/../../../shared/components/auth";
import { UserType } from "features/../../../shared/constants/enums";
import { AddButton } from "features/../../../shared/components/buttons";
import { Targets, Actions } from "constants/permissions";
import { DeleteSchedule } from ".";
import { RequestPanel } from "../ScheduleRequests";
import { SheetRoutes } from "constants/routes";
import { getAgreementsState } from "../../selectors";

import {
  generateSheet,
  addExpandedAgreementOrSchedule,
  removeExpandedAgreementOrSchedule,
  getAgreementScheduleSheets,
  getMasterAgreement,
  updateSchedule,
} from "../actions";

const ScheduleRowForm = ({
  agreement,
  schedule,
  isAdminUser,
  isInternalUser,
  getExpandIconName,
  hasPermission,
}) => {
  const history = useHistory();
  const { t } = useTranslation("agreements");

  const canUpdateCreditAvailable = hasPermission(
    Targets.Schedules,
    Actions.UpdateCreditAvailable,
  );
  const { expandedAgreementsAndSchedules, filters, companyFilters } =
    useSelector(getAgreementsState);
  const dispatch = useDispatch();

  const onToggleScheduleExpanded = (id) => {
    if (expandedAgreementsAndSchedules?.includes(id)) {
      dispatch(removeExpandedAgreementOrSchedule(id));
    } else {
      dispatch(addExpandedAgreementOrSchedule(id));
      dispatch(getAgreementScheduleSheets(id, filters));
    }
  };

  const reloadAgreement = () => {
    dispatch(getMasterAgreement(agreement.agreementId, filters));
  };

  return (
    <FormBuilder
      initialData={schedule}
      submitAction={updateSchedule}
      submitParams={{ id: schedule.scheduleId }}
      onSubmitted={reloadAgreement}
      renderForm={(formProps, state, formEvents) => (
        <Form {...formProps} className="manage-agreement-form">
          <div className="full-width">
            <div style={{ display: "flex" }} className="float-left name">
              <div
                className="spaced expand-collapse"
                onClick={() => onToggleScheduleExpanded(schedule.scheduleId)}
              >
                <Icon name={getExpandIconName(schedule.scheduleId)} />
                {t("ScheduleRow.ProjectName_Label")}
              </div>
              <div className="spaced no-padding-left">
                <Form.Input
                  field="projectName"
                  className="project-name"
                  placeholder={t("ScheduleRow.ProjectName_Placeholder")}
                  loading={state.submitting}
                  required
                  disabled={!isAdminUser}
                />
              </div>
            </div>
            <div className="float-left">
              <RequestPanel schedule={schedule} />
            </div>

            <div className="float-left grid">
              {!isInternalUser || process.env.TRADE_AS_IPS ? (
                <div className="float-left spaced not-bold">
                  <Trans
                    t={t}
                    values={{
                      ref:
                        agreement.agreementReference &&
                        schedule.scheduleReference
                          ? `${agreement.agreementReference}${schedule.scheduleReference}`
                          : t("NotLive"),
                    }}
                    components={{
                      span: <span />,
                    }}
                  >
                    ScheduleRow.TitleWithRef
                  </Trans>
                </div>
              ) : (
                <div className="float-left">
                  <div className="float-left spaced not-bold">
                    {t("ScheduleRow.Title")}
                  </div>
                  <div className="float-left spaced no-padding-left">
                    <Form.Input
                      field="scheduleReference"
                      inline
                      disabled={!isInternalUser}
                      fluid
                    />
                  </div>
                </div>
              )}
              {!process.env.TRADE_AS_IPS && (
                <div className="float-left flex grid-col">
                  <div className="spaced not-bold">
                    {t("ScheduleRow.RevisionNumber")}
                  </div>
                  <div className="float-left spaced no-padding-left">
                    <Form.Input
                      field="revisionNumber"
                      inline
                      disabled={!isInternalUser}
                      fluid
                    />
                  </div>
                </div>
              )}
            </div>

            <div className="float-left">
              <div>
                <div className="float-left spaced not-bold">
                  {t("ScheduleRow.Schedule_Agreement_Date")}
                </div>
                <div className="float-left no-padding-left spaced schedule-agreement-date-form-field">
                  <Form.Date
                    local
                    field="startDate"
                    placeholder=""
                    inline
                    disabled={!isAdminUser}
                  />
                </div>
              </div>
              <div className="float-right">
                <div className="float-left spaced not-bold red">
                  {t("ScheduleRow.Credit_Available")}
                </div>
                <div className="float-left no-padding-left spaced">
                  <Form.Numeric
                    field="creditAvailable"
                    disabled={!canUpdateCreditAvailable}
                    decimalPlaces={2}
                    formatValue
                    subLabel={process.env.MAJOR_CURRENCY}
                  />
                </div>
              </div>
            </div>
            <div className="float-left">
              <div className="flex spaced">
                <PermissionCheck
                  target={Targets.Schedules}
                  action={Actions.Edit}
                >
                  <ActionButton
                    type="save"
                    floated="right"
                    onClick={() => formEvents.onSubmit()}
                    loading={state.submitting}
                    className={
                      "action-button" + (state.dirty ? " " : " hidden")
                    }
                  />
                  <ActionButton
                    type="cancel"
                    floated="right"
                    onClick={() => formEvents.onReset()}
                    loading={state.submitting}
                    className={
                      "action-button" + (state.dirty ? " " : " hidden")
                    }
                  />
                </PermissionCheck>
              </div>
            </div>
            <DeleteSchedule
              scheduleId={schedule.scheduleId}
              scheduleReference={schedule.scheduleReference}
              onSubmitted={reloadAgreement}
            />

            <div className="float-left spaced">
              <PermissionCheck target={Targets.Sheet} action={Actions.Create}>
                <TriggerBuilder
                  submitAction={generateSheet}
                  submitParams={{
                    clientId: agreement.clientId,
                    dealerOfficeId: companyFilters.dealerOffice.id,
                    scheduleId: schedule.scheduleId,
                  }}
                  onSubmitted={(submitResponse) =>
                    history.push(SheetRoutes.Sheet(submitResponse.sheetId), {
                      isNewSheet: true,
                    })
                  }
                  renderTrigger={(fireTrigger, state) => (
                    <div>
                      <AddButton
                        className="clear-bg"
                        content={t("ScheduleRow.New_Sheet")}
                        icon={BlueAddSvg}
                        onClick={fireTrigger}
                        loading={state.submitting}
                      />
                      {state.success && state.data && (
                        <span>{state.data.message}</span>
                      )}
                    </div>
                  )}
                />
              </PermissionCheck>
            </div>
            <div className="clear"></div>
          </div>
        </Form>
      )}
    />
  );
};

export default compose(withCurrentUser, withPermissions)(ScheduleRowForm);
