import { Modal, ModalBuilder } from "@redriver/cinnamon";
import React from "react";
import { Icon } from "semantic-ui-react";
import { markAsRead } from "./actions";
import { useTranslation } from "react-i18next";
import { PermissionCheck } from "features/../../../shared/components/auth";
import { Targets, Actions } from "constants/permissions";

const NotificationModal = ({ props, onRefresh }) => {
  const { t } = useTranslation("shared");
  const { t: tReason } = useTranslation("enums", {
    keyPrefix: "notificationReasons",
  });

  return (
    <ModalBuilder
      submitAction={!props.isRead ? markAsRead : null}
      submitParams={{ id: props.id }}
      onSubmitted={onRefresh}
      renderTrigger={(showModal) => (
        <Icon
          onClick={() => {
            showModal();
          }}
          name="eye"
        />
      )}
      renderModal={(modalProps) => (
        <Modal.Confirmation
          {...modalProps}
          className="notification-modal"
          size="large"
          header={props.level}
          cancelDisabled={true}
          cancelLabel=""
          submitLabel="Ok"
        >
          <div>{props.source}</div>
          <div>{tReason(props.body)}</div>
          <div>{props.link}</div>

          <PermissionCheck
            target={Targets.Notifications}
            action={Actions.ViewRawNotificationText}
          >
            {props.rawMessageText && (
              <div className="raw-msg">{props.rawMessageText}</div>
            )}
          </PermissionCheck>
        </Modal.Confirmation>
      )}
    />
  );
};

export default NotificationModal;
