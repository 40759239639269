import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  PageLoader,
  ActionBar,
  ErrorMessage,
  FormBuilder,
  Form,
} from "@redriver/cinnamon";
import { useDispatch, useSelector } from "react-redux";

import { TablePagination } from "components/pagination";
import { getAgreementsState } from "../selectors";
import {
  getMasterAgreementsDebounced,
  getAgreementScheduleSheetsDebounced,
  setFilters,
  setPagination,
} from "./actions";
import AgreementListItems from "./AgreementComponents/AgreementListItems";
import ClientHeader from "./ClientHeader";
import {
  AgreementSheetFilters,
  AgreementFilterDisplay,
  SearchAgreements,
} from "../AgreementFilter";
import { PermissionCheck } from "features/../../../shared/components/auth";
import { Targets, Actions } from "constants/permissions";
import { Grid, GridColumn } from "semantic-ui-react";
import { AddMasterAgreement } from "./AgreementComponents";
import { AddSchedule } from "./ScheduleComponents";
import CRMUpdateToggleForm from "./CRMUpdateToggleForm";

const AgreementsList = ({ sheetId }) => {
  const {
    companyFilters,
    filters,
    pagination,
    agreementsLoading,
    agreementsLoaded,
    totalAgreements,
    scheduleSheets,
  } = useSelector(getAgreementsState);

  const { t } = useTranslation("agreements");
  const dispatch = useDispatch();

  // Load agreements on page load, and change to filters or pagination
  useEffect(() => {
    getMasterAgreementsDebounced(
      dispatch,
      { filters, pagination },
      companyFilters,
    );
    // Remove schedule sheets that are no longer in the list.
    // Reload expanded schedules' sheets for schedules still in the list.
  }, [filters, pagination, companyFilters]);

  // Reload sheets when filters change
  useEffect(() => {
    getAgreementScheduleSheetsDebounced(dispatch, scheduleSheets, filters);
  }, [filters]);

  const onFiltersChange = (formData) => {
    dispatch(setFilters(formData));
  };

  const onPaginationChange = (pagination) =>
    dispatch(setPagination(pagination));

  return (
    <div className="agreements-list">
      <div>
        <ClientHeader />
        <br />
        <Grid columns={3}>
          <GridColumn width={3}>
            <PermissionCheck
              target={Targets.Client}
              action={Actions.UpdateCRMRecord}
            >
              <CRMUpdateToggleForm />
            </PermissionCheck>
          </GridColumn>
          <GridColumn className="right-align" width={5}>
            <PermissionCheck
              target={Targets.Agreements}
              action={Actions.Create}
            >
              <AddMasterAgreement />
            </PermissionCheck>
          </GridColumn>
          <GridColumn width={4}>
            <PermissionCheck target={Targets.Schedules} action={Actions.Create}>
              <AddSchedule />
            </PermissionCheck>
          </GridColumn>
        </Grid>
        <div className="content-container agreements-list">
          <FormBuilder
            value={filters}
            onChange={onFiltersChange}
            renderForm={(formProps) => (
              <ActionBar>
                <ActionBar.Item fluid className="flex search-filter-display">
                  <SearchAgreements formProps={formProps} />
                  <AgreementFilterDisplay formProps={formProps} />
                </ActionBar.Item>
                <PermissionCheck
                  target={Targets.Agreements}
                  action={Actions.Create}
                >
                  <ActionBar.Item></ActionBar.Item>
                </PermissionCheck>
                <div className="agreement-filter-button">
                  <ActionBar.Item>
                    <AgreementSheetFilters formProps={formProps} />
                  </ActionBar.Item>
                </div>
              </ActionBar>
            )}
          />
          {(!agreementsLoaded || agreementsLoading) && <PageLoader />}
          <AgreementListItems history={history} selectedSheetId={sheetId} />
          <TablePagination
            pageNumber={pagination.pageNumber}
            onChange={onPaginationChange}
            pageSize={pagination.pageSize}
            totalItems={totalAgreements}
          />
          {agreementsLoaded && (
            <ErrorMessage
              header={t("No_data_returned")}
              error={
                !agreementsLoading && totalAgreements == 0
                  ? t("Update_selection")
                  : ""
              }
              omitCodes
            />
          )}
        </div>
      </div>
    </div>
  );
};
export default AgreementsList;
