import React from "react";
import { Button } from "semantic-ui-react";
import { useDispatch } from "react-redux";
import { ModalBuilder } from "@redriver/cinnamon";
import { TranslatedModal } from "features/../../../shared/components/modals";
import { useTranslation } from "react-i18next";
import {
  downloadSheetContractWithoutSubmitting,
  downloadProductScheduleAddendum,
  downloadDirectDebitAddendum,
  downloadPrincipalSoftwareSupportAddendum,
  downloadPrincipalLargeFormatAddendum,
  downloadPaperInclusiveAddendum,
} from "../actions";

const DownloadDocuments = ({
  sheetId,
  disableTrigger,
  disableSubmit,
  onSubmitted,
  renderTrigger,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation("sheetSummary", {
    keyPrefix: "DownloadDocumentsModal",
  });

  return (
    <ModalBuilder
      onSubmit={() => {
        dispatch(downloadSheetContractWithoutSubmitting(sheetId));
        if (!process.env.TRADE_AS_IPS) {
          dispatch(downloadProductScheduleAddendum(sheetId));
          dispatch(downloadDirectDebitAddendum(sheetId));
          dispatch(downloadPrincipalSoftwareSupportAddendum(sheetId));
          dispatch(downloadPrincipalLargeFormatAddendum(sheetId));
          dispatch(downloadPaperInclusiveAddendum(sheetId));
        }
      }}
      onSubmitted={onSubmitted}
      renderTrigger={
        renderTrigger ||
        ((onRender) => (
          <Button
            primary
            compact
            icon="download"
            disabled={disableTrigger}
            onClick={onRender}
            content={t("Trigger")}
          />
        ))
      }
      renderModal={(modalProps, data, state) => (
        <TranslatedModal.Confirmation
          {...modalProps}
          header={t("Header")}
          submitDisabled={state.loading || state.error || disableSubmit}
        >
          {t("ConfirmMessage")}
        </TranslatedModal.Confirmation>
      )}
    />
  );
};

export default DownloadDocuments;
