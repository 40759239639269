import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Format, ListBuilder, Table, useAuthClaims } from "@redriver/cinnamon";
import { getNotificationsList } from "./actions";
import { TablePagination } from "components/pagination";
import { useTranslation } from "react-i18next";
import NotificationModal from "./NotificationModal";
import NotificationFilters from "./NotificationFilters";
import { Icon } from "semantic-ui-react";

const NotificationList = () => {
  const { t } = useTranslation("notifications");
  const { t: tHeaders } = useTranslation("notifications", {
    keyPrefix: "headers",
  });
  const { t: tReason } = useTranslation("enums", {
    keyPrefix: "notificationReasons",
  });
  const { t: tLevel } = useTranslation("enums", {
    keyPrefix: "notificationLevels",
  });
  const { t: tSource } = useTranslation("enums", {
    keyPrefix: "notificationSources",
  });

  const { userId, userName } = useAuthClaims();
  const location = useLocation();

  const [sheetId, setSheetId] = useState(null);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    setSheetId(params.get("sheetId"));
  }, [location.search]);

  /* Default recipientId to the current user for opening the notifications page from the main menu.
     Do not default the recipientId if we are viewing a Sheet's notifications, as admins should see all records */
  const recipientId = sheetId ? null : userId;

  return (
    <React.Fragment>
      <h4>{t("header")}</h4>
      <ListBuilder
        withTable
        initialPagination={{ pageSize: 25, pageNumber: 1 }}
        initialFilters={{
          recipientId,
          sheetOwnerName: userName,
        }}
        dataTransform={(data) => data.results}
        totalTransform={(data) => data.totalResults}
        loadAction={getNotificationsList}
        loadParams={{
          sheetId,
        }}
        renderList={(tableProps, state, events) => (
          <div className="notifications">
            <NotificationFilters
              value={state.filters}
              onChange={events.onChangeFilters}
            />
            <Table
              {...tableProps}
              dataKey="id"
              emptyMessage={t("empty")}
              width="50%"
              columns={10}
            >
              {(notification) => (
                <React.Fragment>
                  <Table.Column
                    className={!notification?.isRead ? "unread" : ""}
                    field="level"
                    title=""
                    render={() => {
                      let level =
                        notification.level == "Info"
                          ? { name: "warning circle", color: "green" }
                          : notification.level == "Warning"
                          ? { name: "warning sign", color: "yellow" }
                          : notification.level == "Critical"
                          ? { name: "warning", color: "red" }
                          : { name: "", color: "" };

                      return <Icon name={level.name} color={level.color} />;
                    }}
                  />
                  <Table.Column
                    className={!notification?.isRead ? "unread" : null}
                    field="createdUtc"
                    title={tHeaders("time")}
                    render={(x) => (
                      <Format.DateTime
                        value={notification.createdUtc}
                        format="hh:mmA DD MMM YYYY"
                      />
                    )}
                  />

                  <Table.Column
                    className={!notification?.isRead ? "unread" : null}
                    field="clientName"
                    title={tHeaders("client")}
                  />
                  <Table.Column
                    className={!notification?.isRead ? "unread" : null}
                    field="masterProjectName"
                    title={tHeaders("master_project")}
                  />
                  <Table.Column
                    className={!notification?.isRead ? "unread" : null}
                    field="scheduleProjectName"
                    title={tHeaders("schedule_project")}
                  />
                  <Table.Column
                    className={!notification?.isRead ? "unread" : null}
                    field="sheetName"
                    title={tHeaders("sheet_name")}
                  />

                  <Table.Column
                    className={!notification?.isRead ? "unread" : null}
                    render={(fields) => tSource(fields.source)}
                    title={tHeaders("message_source")}
                  />
                  <Table.Column
                    className={!notification?.isRead ? "unread" : null}
                    render={(fields) => tLevel(fields.level)}
                    title={tHeaders("message_level")}
                  />
                  <Table.Column
                    className={!notification?.isRead ? "unread" : null}
                    render={(fields) => tReason(fields.body)}
                    title={tHeaders("message_title")}
                  />
                  <Table.Column
                    className={!notification?.isRead ? "unread" : null}
                    width="1%"
                    align="right"
                    render={(value) => (
                      <NotificationModal
                        props={value}
                        onRefresh={events.onRefresh}
                      />
                    )}
                  />
                </React.Fragment>
              )}
            </Table>
            <TablePagination
              pageNumber={state.pagination.pageNumber}
              onChange={events.onChangePagination}
              pageSize={state.pagination.pageSize}
              totalItems={state.total}
            />
          </div>
        )}
      />
    </React.Fragment>
  );
};

export default NotificationList;
