import { FunderType } from "features/../../../shared/constants/enums";
import {
  newFixedInput,
  newFixedString,
  newFixedBoolean,
  newFixedInputEmpty,
  newFixedPaperWeight,
} from "components/forms";
import { getWizardSplitTotals } from "../helpers";

export const initialSheetState = {
  actionsPanelOpen: false,
  comparisonPanelOpen: false,
  missingYieldBands: false,
  summary: {
    countedVolume: 0,
    notCountedVolume: 0,
    settlements: 0,
    volumeReductionMinimum: 0,
    nonVolumeReductionMinimum: 0,
    totalMinimum: 0,
    totalScheduleManagedCountedVolume: 0,
    terminationDiscount: null,
  },
  loadFailed: false,
  requiresRecalculation: false,
  calculationPaused: false,
  capitalContent: getWizardSplitTotals(),
  servicesContent: getWizardSplitTotals(),
  paperContent: getWizardSplitTotals(),
  scheduleContent: 0,
  utilityMeters: 0,
  largeFormatContent: 0,
  sheetDetailsLoading: false,
  sheetDetailsSaving: false,
  ipsRatesCompleted: true,
  ratesCompleted: false,
  capitalCompleted: false,
  servicesCompleted: false,
  scheduleCompleted: false,
  scheduleStabilityCompleted: false,
  paperCompleted: false,
  paperHasContent: null,
  largeFormatCompleted: false,
  sheetData: {
    isDraft: true,
    excessAllocation: {
      actualExcess: null,
      excessAllocatedToSchedule: null,
      totalSecondaryRentals: null,
      includeExcessAndVolumeCredit: false,
    },
    impersonatedUser: {
      fullName: "",
      canEditSheet: true,
      canDownloadSheet: true,
      canEditAdminFields: true,
      canViewPaperPrice: true,
      isInternal: true,
    },
    lastUpdated: Date.now(), // used to help detect changes to data, and cause re-renders
    isDirty: false, // used to determine whether sheet needs saving before opening Product Schedule
    largeFormatCreateModal: {
      printerId: null,
      inkInclusiveId: null,
      printerInkUsageId: null,
      compPrinterInkUsageId: null,
      printerName: null,
      includeScan: false,
      includeCleaning: false,
      costPerUnit: null,
      lfPrinterConfig: {
        text: "",
        value: "",
        targetMarket: "",
        paperWidth: "",
        paperWidthmm: 0,
        paperWidthInches: 0,
        colours: "",
        scanner: null,
        costPerMl: 0,
        costPerScan: 0,
        costPerMlCleaning: 0,
        priceBVisible: false,
      },
      lfInkInclusive: { inkUsedML: 0, imageUrl: null },
      compLfInkInclusive: { inkUsedML: 0, imageUrl: null },
      lfRollMedia: [
        {
          index: 0,
          cost: 0,
          width: 0,
          length: 0,
          weight: 0,
          paperWidthId: "",
          _widthIdMetric: "", //used internally
          _widthIdImperial: "", //used internally
          paperInclusiveId: "",
        },
      ],
    },
    id: "",
    ipsRates: {
      unlockAllowedTieredToner: "",
      unlockDisableMinVolsLogic: "",

      funderOverrides: overrideInitialState(FunderType.CoFunded),
      dealerFundedOverrides: overrideInitialState(FunderType.Funder),
      coFundedOverrides: overrideInitialState(FunderType.Dealer),
    },
    rates: {
      allowedTieredToner: 0,
      systemScheduleDisableMinVolsLogic: 0,
      additionalTerms: "",
      paymentMethod: "",
      sheetType: "",
      applicableFromUtc: "",
      displaySettlement: newFixedInput(),
      upgradeClause: "",
      funderFmv: {
        id: null,
        funderType: FunderType.Funder,
        financeType: null,
        fmvType: null,
        salesPercentage: newFixedInput(),
        incentivePercentage: newFixedInput(),
        adminPercentage: newFixedInput(),
        otherPercentage: newFixedInput(),
        introductionPaymentDisplaySelected: false,
      },
      dealerFmv: {
        id: null,
        funderType: FunderType.Dealer,
        financeType: null,
        fmvType: null,
        salesPercentage: newFixedInput(),
        IncentivePercentage: newFixedInput(),
        adminPercentage: newFixedInput(),
        otherPercentage: newFixedInput(),
        introductionPaymentDisplaySelected: false,
      },
      cofundedFmv: {
        id: null,
        funderType: FunderType.CoFunded,
        financeType: null,
        fmvType: null,
        salesPercentage: newFixedInput(),
        incentivePercentage: newFixedInput(),
        adminPercentage: newFixedInput(),
        otherPercentage: newFixedInput(),
        introductionPaymentDisplaySelected: false,
      },
    },
    capital: {
      cofundedTotal: 0,
      funderTotal: 0,
      dealerTotal: 0,
      cofundedTotalCosts: 0,
      funderTotalCosts: 0,
      dealerTotalCosts: 0,
      volumeSelection: newFixedInput(),
      totalDevicesOnSchedule: newFixedInput(),
      funderSelected: newFixedBoolean(),
      cofundedSelected: newFixedBoolean(),
      dealerFunderSelected: newFixedBoolean(),
      cofunded: capitalInitialState(FunderType.CoFunded),
      funder: capitalInitialState(FunderType.Funder),
      dealer: capitalInitialState(FunderType.Dealer),
    },
    services: {
      servicesDisableMinVolsLogic: null,
      mandatoryServices: [],
      services: [],
      adminServices: [],
      ipServices: [],
      servicesTotal: 0,
    },
    restrictions: {
      allowPaymentMethod: null,
      allowVolumeSelection: null,
      disableMinLogicAvailableToDealer: null,
      tieredTonerAvailableToAll: null,
      tieredTonerAvailableToDealer: null,
    },
    paperInclusive: {
      isPaperInclusive: newFixedBoolean(),
      paperInclusiveChargeType: { isFixed: false, value: "A4A3" },
      isCostEffectiveA3: newFixedBoolean(),
      a4CustomerCurrentSpend: emptyPaperInclusiveSpend(),
      a3CustomerCurrentSpend: emptyPaperInclusiveSpend(),
      a4ContractedPaperSpend: emptyContractedSpend(),
      a3ContractedPaperSpend: emptyContractedSpend(),
      specialPaperCost: newFixedInput(),
      specialPaperCostDescription: newFixedString(),
      specialPaperCostSku: newFixedString(),
      deliveryCost: newFixedInput(),
      deliveryCostDescription: newFixedString(),
      deliveryCostSku: newFixedString(),
      paperInclusiveAdditionalTerms: newFixedString(),
      paperInclusiveDisableMinLogic: 0,
      paperIncomeAdjustment: 0,
      quarterlyServiceAdjustment: 0,
      quarterlyServiceDifference: 0,
      additionalCosts: [],
      summary: {
        units: 0.0,
        costPerUnit: 0,
        totalIncMinDelivery: 0,
        quarterlyContractVol: 0,
        contractVolumeInBoxedReams: 0,
        amethystAmount: 0,
        minDiffToCreateInAmethyst: 0,
        likelyMinGmBelowActioned: 0,
        totalIncludedMinimumDeliveryCharge: 0,
        totalAdditionalCosts: 0,
      },
    },
    systemScheduleItems: [],
    largeFormatGroups: [],
    scheduleStability: {
      sheetOverrideVMax: null,
      agreementStabilityOption: "Option1",
      comparisonAdjustmentQtrFinance: 0,
      comparisonAdjustmentQtrService: 0,
    },
    comparisonData: {
      items: [],
      priceDifference: null,
    },
    defaults: {
      largeFormatDefaults: {
        paperMeter: {},
      },
    },
  },
  comparisonCopies: {
    paperInclusive: {
      isPaperInclusive: newFixedBoolean(),
      paperInclusiveChargeType: null,
      isCostEffectiveA3: null,
      a4CustomerCurrentSpend: emptyPaperInclusiveSpend(),
      a3CustomerCurrentSpend: emptyPaperInclusiveSpend(),
      a4ContractedPaperSpend: null,
      a3ContractedPaperSpend: null,
      totalSpecialPaperCost: 0.0,
      paperInclusiveAdditionalTerms: "",
    },
  },
  rawCalculation: null,
};

export function capitalInitialState() {
  return {
    financeSelection: newFixedInput(),
    productCapitalCosts: newFixedInput(),
    otherCosts: newFixedInput(),
    ipSyndicatePercentageYield: newFixedInput(),

    dealerShareOfYieldPrincipalCapital: newFixedInput(),
    dealerLiquidityChargeOfRental: newFixedInput(),
    dealerIncome: newFixedInput(),
    dealerIPSyndicateRentalIncome: newFixedInput(),
    coFundedDealerPercentage: newFixedInput(),
    coFundedDealerPayout: newFixedInput(),
    coFundedPercentageOfRentalDue: newFixedInput(),
    coFundedDealerRental: newFixedInput(),
    coFundedDealerReturn: newFixedInput(),
    coFundedIpSyndicatePayout: newFixedInput(),
    coFundedPercentageOfFunderRisk: newFixedInput(),
    coFundedFunderRental: newFixedInput(),

    rrp: {
      value: "",
      minimumValue: null,
      maximumValue: null,
      setValue: null,
    },
    settlements: [],
    settlementsTotal: 0,
    adminSettlements: [],
    adminSettlementsTotal: 0,
    ipSettlements: [],
    ipSettlementsTotal: 0,
    ipCoTerminusRentals: [],
    coTerminusRentals: [],
  };
}

function overrideInitialState() {
  return {
    yieldOverride: newFixedInputEmpty(),
    fvOverride: newFixedInputEmpty(),
    ipNetRate: newFixedInputEmpty(),
    grossSaleOverride: newFixedInputEmpty(),
    ipLiquidityChargeOverride: newFixedInputEmpty(),
    unlockRateType: 1,
    unlockFMV: 1,
  };
}

function emptyPaperInclusiveSpend() {
  return {
    pricePerReam: newFixedInput(),
    quantityPerReam: newFixedInput(),
    reamsPerBox: newFixedInput(),
    paperWeight: newFixedPaperWeight(),
    totalReamsBoughtPerQuarter: newFixedInput(),
    deliveryChargesPerQuarter: newFixedInput(),
    otherChargesPerQuarter: newFixedInput(),
    description: "",
  };
}

function emptyContractedSpend() {
  return {
    description: null,
    paperId: null,
    pricePerReam: newFixedInputEmpty(),
  };
}
