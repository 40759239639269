import { Form, FormBuilder } from "@redriver/cinnamon";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Icon } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import { getAgreementsState } from "features/Agreements/selectors";
import {
  getCustomerCrmRestriction,
  updateCustomerCrmRestriction,
} from "./actions";

const CRMUpdateToggleForm = () => {
  const { t } = useTranslation("agreements");
  const dispatch = useDispatch();

  const [formData, setFormData] = useState(undefined);
  const [submitTimeout, setSubmitTimeout] = useState(undefined);
  const [isCheckboxEnabled, setIsCheckboxEnabled] = useState(false);

  const {
    companyFilters: {
      client: { id: clientId },
    },
  } = useSelector(getAgreementsState);

  useEffect(() => {
    (async () => {
      const { success, response } = await dispatch(
        getCustomerCrmRestriction(clientId),
      );

      success && setFormData(response);
    })();
  }, [clientId, dispatch]);

  const handleIconClick = () => {
    setIsCheckboxEnabled(!isCheckboxEnabled);
  };

  return (
    <div>
      <FormBuilder
        submitAction={() => {
          return updateCustomerCrmRestriction(formData, {
            clientId,
          });
        }}
        renderForm={(formProps, state, events) => (
          <Form
            {...formProps}
            value={formData}
            readOnly={false}
            disabled={!formData || formProps.disabled}
            onChange={(field, data, applyChanges) => {
              setFormData(applyChanges(formProps.value));
              clearTimeout(submitTimeout);
              setSubmitTimeout(setTimeout(events.onSubmit, 500)); // suppress rapid toggling
            }}
          >
            <div className="checkbox-icon-container">
              <Form.Checkbox
                field="restrictCrmUrlUpdate"
                label={t("Stop_CRM_Updating")}
                disabled={!isCheckboxEnabled}
              />
              <Icon
                name={isCheckboxEnabled ? "unlock" : "lock"}
                size="large"
                onClick={handleIconClick}
                className="disable-icon"
              />
            </div>
          </Form>
        )}
      />
    </div>
  );
};

export default CRMUpdateToggleForm;
