import React from "react";
import { ModalBuilder, Table } from "@redriver/cinnamon";
import { useTranslation } from "react-i18next";
import { TranslatedModal } from "features/../../../shared/components/modals";
import { Divider } from "semantic-ui-react";
import { getDocumentRecipients } from "./actions";
import DocumentRecipients from "./DocumentRecipients";

const DocumentRecipientsView = ({ sheetId }) => {
  const { t } = useTranslation("agreements");

  return (
    <ModalBuilder
      withForm
      loadAction={getDocumentRecipients}
      loadParams={{
        sheetId,
      }}
      renderTrigger={(trigger) => (
        <Table.MenuItem onClick={trigger}>
          {t("SheetActions.ViewDocumentRecipients.TriggerText")}
        </Table.MenuItem>
      )}
      renderModal={(modalProps, data) => (
        <TranslatedModal.Information
          {...modalProps}
          header={t("SheetActions.ViewDocumentRecipients.HeaderText")}
          cancelDisabled={true}
          submitLabel="Close"
          cancelLabel=""
        >
          {data.value && (
            <React.Fragment>
              <DocumentRecipients
                header={t(
                  "SheetActions.ViewDocumentRecipients.AuthorisedSignatories",
                )}
                data={data.value.authorisedSignatories}
              />
              <Divider />
              <DocumentRecipients
                header={t(
                  "SheetActions.ViewDocumentRecipients.DirectDebitSignatories",
                )}
                data={data.value.directDebitSignatories}
              />
              <Divider />
              <DocumentRecipients
                header={t(
                  "SheetActions.ViewDocumentRecipients.DocumentViewers",
                )}
                data={data.value.documentViewers}
              />
              <Divider />
              <DocumentRecipients
                header={t(
                  "SheetActions.ViewDocumentRecipients.CopyDocumentUsers",
                )}
                data={data.value.copyDocumentUsers}
              />

              {!process.env.TRADE_AS_IPS && (
                <React.Fragment>
                  <Divider />
                  <DocumentRecipients
                    header={t("SheetActions.ViewDocumentRecipients.FunderUser")}
                    data={[data.value.funderSignatory]}
                  />
                </React.Fragment>
              )}
            </React.Fragment>
          )}
        </TranslatedModal.Information>
      )}
    />
  );
};

export default DocumentRecipientsView;
