import React from "react";
import { useTranslation } from "react-i18next";
import { deleteSheet } from "../actions";
import { ModalBuilder, Table } from "@redriver/cinnamon";
import { withRouter } from "react-router-dom";
import {
  withCurrentUser,
  withPermissions,
} from "features/../../../shared/components/auth";
import { compose } from "redux";
import { Targets, Actions } from "constants/permissions";
import { TranslatedModal } from "features/../../../shared/components/modals";

const DeleteSheet = ({ sheet, onSubmitted, hasPermission }) => {
  const { t } = useTranslation("agreements");
  const { sheetId, sheetName } = sheet;
  const canDeleteAll = hasPermission(Targets.Sheet, Actions.DeleteAll);
  const canDeleteVoided = hasPermission(Targets.Sheet, Actions.DeleteVoided);

  if (!canDeleteAll && !canDeleteVoided) return;

  if (!canDeleteAll && canDeleteVoided) {
    if (!(sheet.isDraft || sheet.isVoided)) {
      return;
    }
  }
  return (
    <ModalBuilder
      withForm
      submitAction={deleteSheet}
      submitParams={{ sheetId }}
      onSubmitted={onSubmitted}
      renderTrigger={(trigger) => (
        <Table.MenuItem onClick={trigger}>
          {t("SheetActions.DeleteSheet.TriggerText")}
        </Table.MenuItem>
      )}
      renderModal={(modalProps) => (
        <TranslatedModal
          {...modalProps}
          header={t("SheetActions.DeleteSheet.HeaderText")}
          size="small"
        >
          {t("SheetActions.DeleteSheet.ConfirmMessage", { sheetName })}
        </TranslatedModal>
      )}
    />
  );
};

export default compose(
  withCurrentUser,
  withPermissions,
)(withRouter(DeleteSheet));
