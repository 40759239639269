export default {
  View: "v",
  ViewOthers: "vo",
  ViewExpired: "ve",
  Create: "c",
  Edit: "e",
  Delete: "d",
  Archive: "a",
  Submit: "s",
  Renew: "r",
  ClearLock: "cl",
  Download: "dl",
  SubmitForCustomerApproval: "sc",
  OverrideCustomerSigned: "ocs",
  OverrideIpsSigned: "ois",
  DealerView: "dv",
  SetOwner: "so",
  Void: "vd",
  Resurrect: "ru",
  SetPhysicalDocumentSheetState: "sps",
  ReviewByDealer: "rd",
  ReviewByIps: "ri",
  RejectDeal: "rjd",
  MarkProductSchedule: "mps",
  MakeLive: "ml",
  SetMeterReading: "smr",
  SetMeterCode: "smc",
  IPSAdminEdit: "ip",
  DealerAdminEdit: "da",
  ImpersonateUser: "iu",
  Upload: "up",
  Approve: "ap",
  SubmitRequest: "sr",
  NonOwnedSubmitRequest: "nosr",
  CreateComparisonSnapshot: "ccs",
  ViewCrystallisedSheets: "vcs",
  CrystalliseAndUncrystalliseSheets: "cus",
  SetReadOnly: "ro",
  ArchiveAndUnarchiveSheets: "sarc",
  UpdateSheetScheduleReference: "ussn",
  Reset: "rt",
  PreApproveSupplier: "pasup",
  PreApproveIPS: "paips",
  ViewEnableCleaningMeter: "vecm",
  ViewEnableScanMeter: "vesm",
  ViewPaperSizeOption: "vpso",
  ViewTieredToner: "vtt",
  ViewLargeFormatPriceA: "vlfprca",
  ViewLargeFormatPriceB: "vlfprcb",
  ViewRateType: "vrt",
  ViewPaymentMethod: "vpm",
  ViewExcessAllocation: "vea",
  ViewRawNotificationText: "vrn",

  ViewIPSyndicateRates: "ipsrs",
  ViewScheduleSetup: "schsu",
  ViewCapitalCalc: "capc",
  ViewQuarterlyService: "quser",
  ViewCutSheetPaper: "csp",
  ViewStandardScheduleItems: "stschi",
  ViewLargeFormatSection: "lfsec",
  ViewScheduleStability: "schstb",
  UpdateIPSPermissions: "uip",
  UpdateIPSUsers: "uipusr",
  DuplicateToNewMaster: "dnm",
  DuplicateLive: "dlv",

  Lock: "l",

  UpdateCreditAvailable: "uca",
  UpdateCRMRecord: "ucr",

  ViewDocumentRecipients: "vdr",
  DeleteAll: "dal",
  DeleteVoided: "dvd",
};
