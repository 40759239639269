import React from "react";
import { Form } from "@redriver/cinnamon";
import FormArrayDeleteModal from "features/../../../shared/components/forms/FormArrayDeleteModal";
import { useTranslation } from "react-i18next";

const UserArray = ({ field, onUserRemoved }) => {
  const { t } = useTranslation("agreements");
  return (
    <Form.Array field={field} arrayKey="id" propagateUpdates="always">
      {() => {
        return (
          <div className="user-list">
            <Form.Input
              label={t("SheetActions.SendForApproval.ViewBeforeSigned_Name")}
              field="name"
              required
            />
            <Form.Email
              label={t("SheetActions.SendForApproval.ViewBeforeSigned_Email")}
              field="email"
              required
            />
            <Form.Input
              label={t(
                "SheetActions.SendForApproval.ViewBeforeSigned_PhoneNumber",
              )}
              field="phoneNumber"
            />
            <Form.Input
              label={t(
                "SheetActions.SendForApproval.ViewBeforeSigned_PhoneCountryCode",
              )}
              field="phoneCountryCode"
            />

            <FormArrayDeleteModal
              nameOfRemoval="User"
              onUserRemoved={onUserRemoved}
            />
          </div>
        );
      }}
    </Form.Array>
  );
};

export default UserArray;
