import React from "react";
import {
  Lookup,
  LookupTypeAhead,
  registerLookup,
  apiGet,
  Form,
  LookupDropdown,
} from "@redriver/cinnamon";
import { SheetServiceTypeAhead } from "./Components";

const NAMESPACE = "SHEETS/LOOKUPS";

export const ActionTypes = {
  HeaderDetails: `${NAMESPACE}/HEADER_DETAILS`,
  Services: `${NAMESPACE}/SERVICES`,
  SheetStatesIps: `${NAMESPACE}/STATES_IPS`,
  SheetStatesPrincipal: `${NAMESPACE}/STATES_PRINCIPAL`,
  PhysicalSheetStatePrincipal: `${NAMESPACE}/PHYSICAL_STATES_PRINCIPAL`,
  SheetTypes: `${NAMESPACE}/TYPES`,
  FmvTypes: `${NAMESPACE}/FMV_TYPES`,
  UnitTypes: `${NAMESPACE}/UNITS`,
  ImpersonateUsers: `${NAMESPACE}/IMPERSONATE_USER`,
  DocusignAdminRecipients: `${NAMESPACE}/DOCUSIGN_ADMIN_RECIPIENTS`,
};

export const LookupNames = {
  [ActionTypes.HeaderDetails]: "sheetHeaderDetailsLookup",
  [ActionTypes.Services]: "servicesLookup",
  [ActionTypes.SheetStatesIps]: "sheetStatesIpsLookup",
  [ActionTypes.SheetStatesPrincipal]: "sheetStatesPrincipalLookup",
  [ActionTypes.PhysicalSheetStatePrincipal]:
    "physicalSheetStatesPrincipalLookup",
  [ActionTypes.SheetTypes]: "sheetTypesLookup",
  [ActionTypes.FmvTypes]: "fmvTypesLookup",
  [ActionTypes.UnitTypes]: "unitTypesLookup",
  [ActionTypes.ImpersonateUsers]: "impersonateUserLookup",
  [ActionTypes.DocusignAdminRecipients]: "docusignAdminRecipients",
};

registerLookup(LookupNames[ActionTypes.HeaderDetails], ({ sheetId }) =>
  apiGet(ActionTypes.HeaderDetails, `sheets/${sheetId}/header-details`),
);

// Services
registerLookup(
  LookupNames[ActionTypes.Services],
  ({ search, sheetId, includeMandatory }) =>
    apiGet(ActionTypes.Services, `lookups/services`, {
      includeMandatory: includeMandatory,
      sheetId,
      search,
    }),
  {
    cacheExpiry: 15000,
    transform: (data) => data.map((d) => ({ ...d, text: d.name, value: d.id })),
  },
);

export const ServicesTypeAheadDropdown = ({ sheetId, ...props }) => (
  <Form.Dropdown
    {...props}
    lookup={LookupNames[ActionTypes.Services]}
    lookupParams={{ sheetId }}
  />
);

export const ServicesTypeAheadLookup = ({ sheetId, ...props }) => (
  <LookupTypeAhead
    {...props}
    lookup={LookupNames[ActionTypes.Services]}
    lookupParams={{ sheetId }}
  />
);

export const ServicesTypeAheadLookupField = ({ sheetId, ...props }) => (
  <SheetServiceTypeAhead
    {...props}
    searchSuggestions
    alwaysShowSuggestionsOnBlur
    lookup={LookupNames[ActionTypes.Services]}
    lookupParams={{ sheetId }}
  />
);

// Sheet Types
registerLookup(
  LookupNames[ActionTypes.SheetTypes],
  () => apiGet(ActionTypes.SheetTypes, `lookups/sheet-types`),
  {
    transform: (data) =>
      data.map((d) => ({ ...d, text: d.text, value: d.value })),
  },
);

export const SheetTypeDropdownLookup = ({ className, ...props }) => (
  <Lookup
    resetOnMount
    lookup={LookupNames[ActionTypes.SheetTypes]}
    render={({ response }) => {
      if (!response) {
        return <Form.Dropdown {...props} options={[]} />;
      }
      let options = response.map((m) => ({
        text: m.text,
        value: m.value,
        allowForRewrite: m.allowForRewrite,
      }));
      return (
        <div className={className}>
          <Form.Dropdown {...props} options={options} />
        </div>
      );
    }}
  />
);

// Sheet States
registerLookup(LookupNames[ActionTypes.SheetStatesIps], () =>
  apiGet(ActionTypes.SheetStatesIps, `lookups/sheet-states-ips`),
);
registerLookup(LookupNames[ActionTypes.SheetStatesPrincipal], () =>
  apiGet(ActionTypes.SheetStatesPrincipal, `lookups/sheet-states-principal`),
);
registerLookup(LookupNames[ActionTypes.PhysicalSheetStatePrincipal], (params) =>
  apiGet(
    ActionTypes.PhysicalSheetStatePrincipal,
    "lookups/physical-sheet-states-principal",
    params,
  ),
);

export const SheetStateDropdownLookup = ({ className, ...props }) => (
  <div className={className}>
    <Form.Dropdown
      {...props}
      lookup={
        LookupNames[
          process.env.TRADE_AS_IPS
            ? ActionTypes.SheetStatesIps
            : ActionTypes.SheetStatesPrincipal
        ]
      }
    />
  </div>
);

// FMV Types
registerLookup(
  LookupNames[ActionTypes.FmvTypes],
  () => apiGet(ActionTypes.FmvTypes, `lookups/fmv-types`),
  {
    transform: (data) => data.map((d) => ({ text: d.value, value: d.key })),
  },
);

// Sheet Schedule Item Units
registerLookup(
  LookupNames[ActionTypes.UnitTypes],
  (params) => apiGet(ActionTypes.Units, `lookups/metered-services`, params),
  {
    transform: (data) => data.map((d) => ({ ...d, text: d.code, value: d.id })),
  },
);

registerLookup(
  LookupNames[ActionTypes.ImpersonateUsers],
  ({ sheetId }) =>
    apiGet(ActionTypes.ImpersonateUsers, "lookups/impersonate-users", {
      sheetId,
    }),
  {
    transform: (data) =>
      data && Array.isArray(data)
        ? data.map((x) => {
            return { ...x, value: x.value, text: x.text };
          })
        : [],
    cache: false,
  },
);

export const ImpersonateUsersLookupDropdown = ({ sheetId, ...props }) => {
  return (
    <LookupDropdown
      {...props}
      lookup={LookupNames[ActionTypes.ImpersonateUsers]}
      lookupParams={{ sheetId }}
    />
  );
};

// Sheet Contract Recipients
registerLookup(
  LookupNames[ActionTypes.DocusignAdminRecipients],
  ({ dealerOfficeId }) =>
    apiGet(
      ActionTypes.DocusignAdminRecipients,
      `dealer-offices/${dealerOfficeId}/docusign-recipients`,
    ),
);

export const DocusignAdminRecipientsLookup = ({ dealerOfficeId, render }) => (
  <Lookup
    lookup={LookupNames[ActionTypes.DocusignAdminRecipients]}
    lookupParams={{ dealerOfficeId }}
    render={render}
  />
);
