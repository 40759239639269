import { ModalBuilder, Table, notifySuccess } from "@redriver/cinnamon";
import React from "react";
import { useTranslation } from "react-i18next";
import { TranslatedModal } from "features/../../../shared/components/modals";
import { useDispatch } from "react-redux";
import { sendToFunder } from "./actions";

const SendToFunder = ({ sheet, onSubmitted }) => {
  const { t } = useTranslation("agreements");
  const dispatch = useDispatch();
  const { sheetId, sheetName } = sheet;

  return (
    <ModalBuilder
      submitAction={sendToFunder}
      submitParams={{ sheetId }}
      onSubmitted={() => {
        dispatch(notifySuccess("Sheet successfully submitted"));
        onSubmitted();
      }}
      renderTrigger={(onTrigger) => (
        <Table.MenuItem onClick={onTrigger}>
          {t("SheetActions.SendToFunder.TriggerText")}
        </Table.MenuItem>
      )}
      renderModal={(modalProps) => (
        <TranslatedModal.Confirmation
          {...modalProps}
          header={t("SheetActions.SendToFunder.ModalHeader")}
        >
          {t("SheetActions.SendToFunder.ModalContent", { sheetName })}
        </TranslatedModal.Confirmation>
      )}
    />
  );
};

export default SendToFunder;
