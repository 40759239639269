import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Button, Icon } from "semantic-ui-react";
import { ModalBuilder, Form, notifySuccess } from "@redriver/cinnamon";
import {
  generateMasterAgreement,
  getMasterAgreements,
  addExpandedAgreementOrSchedule,
  getAgreementScheduleSheets,
} from "features/Agreements/AgreementsList/actions";
import { getAgreementsState } from "features/Agreements/selectors";
import { SheetRoutes } from "constants/routes";
import { TranslatedModal } from "features/../../../shared/components/modals";
import { BoilerPlateTandCsDropdownLookup } from "features/../../../shared/features/Settings";

const AddMasterAgreement = () => {
  const { t } = useTranslation("agreements");
  const dispatch = useDispatch();
  const { filters, companyFilters, pagination } =
    useSelector(getAgreementsState);

  const { dealer, dealerOffice, client } = companyFilters;

  return (
    <ModalBuilder
      submitAction={generateMasterAgreement}
      submitParams={{
        dealerOfficeId: dealerOffice ? dealerOffice.id : null,
        clientId: client ? client.id : null,
      }}
      onSubmitted={(param1, param2) => {
        const { masterAgreementId, scheduleId, sheetId } = param1;
        dispatch(addExpandedAgreementOrSchedule(masterAgreementId));
        dispatch(addExpandedAgreementOrSchedule(scheduleId));
        dispatch(getMasterAgreements({ filters, pagination }, companyFilters));
        dispatch(getAgreementScheduleSheets(scheduleId, filters));
        dispatch(
          notifySuccess(t("Master_Agreement_Modal.CreatedSuccessfully")),
        );
      }}
      withForm={true}
      renderTrigger={(showModal) => (
        <div>
          <Button primary onClick={showModal} className="add-new-agreement">
            <Icon name="calculator" size="large" />
            {t("New_Master_Agreement")}
          </Button>
        </div>
      )}
      renderModal={(modalProps, formProps) => (
        <TranslatedModal.Add
          {...modalProps}
          header={t("Master_Agreement_Modal.Title")}
          className="add-agreement-modal"
        >
          <Form {...formProps} className="new-agreement">
            <div>
              <Form.Input
                field="masterProjectName"
                label={t("Master_Agreement_Modal.Master_Project_Name")}
                required
                className="master-project-name"
              />
              <Form.Input
                field="scheduleProjectName"
                label={t("Master_Agreement_Modal.Schedule_Project_Name")}
                required
                className="schedule-project-name"
              />
              {process.env.TRADE_AS_IPS && (
                <BoilerPlateTandCsDropdownLookup
                  label={t("AgreementRow.TCs_Label")}
                  field="boilerPlateTandCsId"
                  dealerId={dealer.id}
                  clearable
                  fluid
                  textField="boilerPlateTandCs"
                  className="agreement-terms-and-conditions"
                />
              )}
            </div>
          </Form>
        </TranslatedModal.Add>
      )}
    />
  );
};

export default AddMasterAgreement;
