import React from "react";
import { connect } from "react-redux";
import { Button } from "semantic-ui-react";
import { ModalBuilder } from "@redriver/cinnamon";
import { updateHeader } from "features/Sheets/ManageSheet/actions";
import { TranslatedModal } from "features/../../../shared/components/modals";
import { Trans, useTranslation } from "react-i18next";

const ConfirmationModal = ({
  sheetDownloadFunction,
  sheetDownloadParams,
  onSubmitted,
  buttonContent,
  buttonIcon,
  buttonClassName,
  disabled,
  sheetId,
  isRewrite,
  isAdmin,
  sheetCanBeUpdated,
  onSaved,
  onSubmitFunction,
  headerText,
  bodyText,
  submitAction,
}) => {
  const { t } = useTranslation("sheetTranslation");
  return (
    <ModalBuilder
      onSubmit={onSubmitFunction}
      submitAction={submitAction}
      submitParams={{
        sheetDownloadFunction: sheetDownloadFunction,
        sheetDownloadParams,
        sheetId: sheetId,
        loadAdmin: isAdmin,
        loadRewrite: isRewrite,
        sheetCanBeUpdated,
        onSaved,
      }}
      onSubmitted={onSubmitted}
      preventErrorNotification={false}
      renderTrigger={(showModal) => (
        <Button
          primary
          className={buttonClassName}
          compact
          disabled={disabled}
          onClick={showModal}
          content={buttonContent}
          icon={buttonIcon}
        />
      )}
      renderModal={(modalProps) => (
        <React.Fragment>
          <TranslatedModal.Confirmation {...modalProps} header={headerText}>
            <div>
              <Trans t={t} components={{ br: <br /> }}>
                {bodyText}
              </Trans>
            </div>
          </TranslatedModal.Confirmation>
        </React.Fragment>
      )}
    />
  );
};

const actions = {
  updateHeader,
};

export default connect(null, actions)(ConfirmationModal);
